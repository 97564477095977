/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItemWithSub
        to='/client'
        title={intl.formatMessage({id: 'MENU.CLIENT'})}
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotune/art/art002.svg'
      >
        <AsideMenuItem
          to='/client/list'
          title={intl.formatMessage({id: 'MENU.LIST'})}
          hasBullet={true}
        />
        <AsideMenuItem
          to='/client/create'
          title={intl.formatMessage({id: 'MENU.CREATE'})}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      {/* <AsideMenuItemWithSub
        to='/packages'
        title={intl.formatMessage({id: 'MENU.PACKAGE'})}
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotune/art/art002.svg'
      >
        <AsideMenuItem
          to='/packages/list'
          title={intl.formatMessage({id: 'MENU.LIST'})}
          hasBullet={true}
        />
      </AsideMenuItemWithSub> */}
      <AsideMenuItemWithSub
        to='/location'
        title={intl.formatMessage({id: 'MENU.LOCATION'})}
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotune/art/art002.svg'
      >
        <AsideMenuItem
          to='/location/list'
          title={intl.formatMessage({id: 'MENU.LIST'})}
          hasBullet={true}
        />
        <AsideMenuItem
          to='/location/create'
          title={intl.formatMessage({id: 'MENU.CREATE'})}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/manager'
        title={intl.formatMessage({id: 'MENU.MANAGER'})}
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotune/art/art002.svg'
      >
        <AsideMenuItem
          to='/manager/list'
          title={intl.formatMessage({id: 'MENU.LIST'})}
          hasBullet={true}
        />
        <AsideMenuItem
          to='/manager/create'
          title={intl.formatMessage({id: 'MENU.CREATE'})}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/staff'
        title={intl.formatMessage({id: 'MENU.STAFF'})}
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotune/art/art002.svg'
      >
        <AsideMenuItem
          to='/staff/list'
          title={intl.formatMessage({id: 'MENU.LIST'})}
          hasBullet={true}
        />
        <AsideMenuItem
          to='/staff/create'
          title={intl.formatMessage({id: 'MENU.CREATE'})}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/inventory'
        title={intl.formatMessage({id: 'MENU.INVENTORY'})}
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotune/art/art002.svg'
      >
        <AsideMenuItem
          to='/inventory/list'
          title={intl.formatMessage({id: 'MENU.LIST'})}
          hasBullet={true}
        />
        <AsideMenuItem
          to='/inventory/import'
          title={intl.formatMessage({id: 'MENU.IMPORTINVENTORY'})}
          hasBullet={true}
        />
        {/* <AsideMenuItem
          to='/inventory/matrix'
          title={intl.formatMessage({id: 'MENU.MATRIXLIST'})}
          hasBullet={true}
        />
        <AsideMenuItem
          to='/inventory/twin'
          title={intl.formatMessage({id: 'MENU.TWINLIST'})}
          hasBullet={true}
        /> */}
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/courier'
        title={intl.formatMessage({id: 'MENU.COURIER'})}
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotune/art/art002.svg'
      >
        <AsideMenuItem
          to='/courier/list'
          title={intl.formatMessage({id: 'MENU.LIST'})}
          hasBullet={true}
        />
        <AsideMenuItem
          to='/courier/create'
          title={intl.formatMessage({id: 'MENU.CREATE'})}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/tally'
        title={intl.formatMessage({id: 'MENU.TALLY'})}
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotune/art/art002.svg'
      >
        <AsideMenuItem
          to='/tally/list'
          title={intl.formatMessage({id: 'MENU.LIST'})}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      {/*<AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      */}
    </>
  )
}
