/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useRef} from 'react'
import Select from 'react-select'
import _ from 'lodash'
import makeAnimated from 'react-select/animated'

type Props = {
  onChangeMethod: (value: any, actionMeta: any) => void
  placeholder?: string
  label: string
  input: {
    value?: Array<{
      label: string
      value: string
    }>
    name: string
    id: string
    options: Array<{
      label: string
      value: string
    }>
  }
  allOptionLabel?: string
  iconFontClass?: string
  type?: string
  disabled?: boolean
  className?: string
  error: any
  touched: any
  required?: boolean
  searchable?: boolean
  clearable?: boolean
  maxMenuHeight?: number
  autoFocus?: boolean
  styles?: any
}

const InputMultiSelect: React.FC<Props> = ({
  onChangeMethod,
  placeholder,
  label,
  input,
  iconFontClass,
  type = 'text',
  disabled = false,
  className,
  error,
  touched,
  required = true,
  searchable = true,
  clearable = true,
  maxMenuHeight,
  allOptionLabel = 'Select All',
  autoFocus = false,
  styles,
}) => {
  const valueRef = useRef(input.value)
  valueRef.current = input.value || []

  const selectAllOption = {
    value: 'SELECT_ALL',
    label: allOptionLabel,
  }

  const customClassName = ` ${touched && error ? 'has-danger' : ''}`

  const animatedComponents = makeAnimated()

  const handelOnChange = (newValue: any, actionMeta: any) => {
    const {action, option, removedValue} = actionMeta
    let newSelectedOptions = []
    let values = []

    if (action === 'select-option' && option.value === 'SELECT_ALL') {
      newSelectedOptions = input.options
    } else if (
      (action === 'deselect-option' && option.value === 'SELECT_ALL') ||
      (action === 'remove-value' && removedValue.value === 'SELECT_ALL')
    ) {
      newSelectedOptions = []
    } else if (actionMeta.action === 'deselect-option' && isSelectAllSelected()) {
      newSelectedOptions = input.options.filter(({value}) => value !== option.value)
    } else {
      newSelectedOptions = newValue || []
    }

    if (newSelectedOptions) {
      values = newSelectedOptions.map((option: any) =>
        Array.isArray(option) ? option[0].value : typeof option === 'string' ? option : option.value
      )
    }

    valueRef.current = values
    onChangeMethod(values, actionMeta)
  }

  const isSelectAllSelected = () => {
    return (
      input.options.length &&
      valueRef &&
      valueRef.current &&
      valueRef.current.length === input.options.length
    )
  }

  const isOptionSelected = (option: any) => {
    return (
      (valueRef &&
        valueRef.current &&
        valueRef.current.some(({value}) => value === option.value)) ||
      isSelectAllSelected()
    )
  }

  const getOptions = () => [selectAllOption, ...input.options]

  const getValue = () => {
    const selectedValues = isSelectAllSelected() ? [selectAllOption] : input.value
    if (!(selectedValues && selectedValues.length)) return []

    return (
      selectedValues &&
      selectedValues.map((option) =>
        typeof option === 'string'
          ? input.options.filter((element: any) => element.value === option)[0]
          : option
      )
    )
  }

  return (
    <>
      <label>
        {label} {required && <span className='text-danger'>*</span>}
      </label>

      <>
        <Select
          {...input}
          isMulti
          id={input.id}
          name={input.name}
          options={getOptions()}
          value={getValue()}
          placeholder={placeholder}
          onChange={handelOnChange}
          onBlur={(event: any) => {
            // console.log('onblur = ', option);
            // props.input.onBlur(props.input.value);
            event.preventDefault()
          }}
          // autoFocus
          isSimpleValue
          isSearchable={searchable !== undefined ? searchable : false}
          isClearable={clearable !== undefined ? clearable : false}
          onBlurResetsInput={false}
          onSelectResetsInput={false}
          maxMenuHeight={maxMenuHeight}
          isDisabled={disabled}
          isOptionSelected={isOptionSelected}
          blurInputOnSelect={false}
          autoFocus={autoFocus != undefined ? autoFocus : true}
          className={customClassName}
          closeMenuOnSelect={false}
          components={animatedComponents}
          styles={{
            control: (base: any) => ({
              ...base,
              maxHeight: '100px',
              overflow: 'auto',
            }),
            indicatorsContainer: (base: any) => ({
              ...base,
              alignItems: 'flex-start',
            }),
            ...(styles || {}),
          }}
        />
        {touched && error && error[input.name] && !disabled && (
          <div className='fv-plugins-message-container text-danger'>
            <div className='fv-help-block'>{error[input.name]}</div>
          </div>
        )}
      </>
    </>
  )
}

export {InputMultiSelect}

// <InputMultiSelect
//    input={{
//      value: values.bank_multi_select_name,
//      id: 'bank_multi_select_name',
//      name: 'bank_multi_select_name',
//      options: [
//        {
//          label: 'option1',
//          value: '1',
//        },
//        {
//          label: 'option2',
//          value: '2',
//        },
//        {
//          label: 'option3',
//          value: '3',
//        },
//      ],
//    }}
//    placeholder='Enter bank Select Multi name'
//    onChangeMethod={(value) => {
//      setFieldValue('bank_multi_select_name', value)
//    }}
//    label='Bank Select Multi Name'
//    error={errors}
//    touched={touched}
//  />
