import axios from 'axios'
import {AuthModel} from '../models/AuthModel'
import {UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}api/v1/auth/get-admin`
export const LOGIN_URL = `${API_URL}api/v1/admin/login/`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}api/v1/admin/forgot-password`
export const REQUEST_RESET_PASSWORD_URL = `${API_URL}api/v1/user/reset-password/`

// Server should return AuthModel
export async function login(email: string, password: string) {
  try {
    let loginData = await axios.post(LOGIN_URL, {email, password})
    return loginData.data
  } catch (e) {
    throw e
  }
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
  return axios.post(REGISTER_URL, {
    email,
    firstname,
    lastname,
    password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
}

// Server will reset password
export async function resetPassword(forgotCode: string, password: string) {
  try {
    let resetPasswordData = await axios.post(REQUEST_RESET_PASSWORD_URL, {forgotCode, password})
    return resetPasswordData.data
  } catch (e) {
    throw e
  }
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}
