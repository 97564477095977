/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {PackagePage} from '../../component/package'

const PackageWrapper: FC = () => {
  return (
    <div>
      <PackagePage />
    </div>
  )
}

export {PackageWrapper}
