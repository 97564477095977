import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setDashboardData: 'SET_DASHBOARD_DATA',
  setDashboardLoading: 'SET_DASHBOARD_LOADER'
}

const initialClientState: IClientState = {
  dashboardData: undefined,
  dashboardLoading: false
}

export interface IClientState {
  dashboardData?: any
  dashboardLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'dashboard', whitelist: ['dashboardData']},
    (state: IClientState = initialClientState, action: ActionWithPayload<IClientState>) => {
    switch (action.type) {
      case actionTypes.setDashboardData: {
        const dashboardData = action.payload?.dashboardData
        return {...state, dashboardData}
      }
            
      case actionTypes.setDashboardLoading: {
        const dashboardLoading = action.payload?.dashboardLoading
        return {...state, dashboardLoading}
      }

      default:
        return state
    }
  }
)

export const actions = {
  setDashboardData: (dashboardData: Array<any>) => ({type: actionTypes.setDashboardData, payload: {dashboardData : dashboardData}}),
  setDashboardLoading: (dashboardLoading: boolean) => ({type: actionTypes.setDashboardLoading, payload: {dashboardLoading}}),
}

export function* saga() {
  yield takeLatest(actionTypes.setDashboardData, function* setDashboardData() {
    yield put(actions.setDashboardLoading(false))
  })
}
