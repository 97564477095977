/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {config} from '../../../comman/constants'
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor

type Props = {
  onClick: () => void
  disabled?: boolean
}

const InputButtonComponent: React.FC<Props> = ({onClick, children}) => {
  return (
    <>
      <button
        type='button'
        style={{backgroundColor: PRIMARY_COLOR, color: TEXT_PRIMARY_COLOR}}
        className='btn btn-success btn-sm'
        onClick={onClick}
      >
        {children}
      </button>
    </>
  )
}

export {InputButtonComponent}

// <InputButtonComponent
//   input={{
//     value: values.bank_name,
//     id: 'bank_name',
//     name: 'bank_name',
//   }}
//   placeholder='Enter bank name'
//   onChange={(value: string) => {
//     handleChange(value)
//     setFieldValue('bank_name', value)
//   }}
//   onBlur={handleBlur}
//   label='Bank Name'
//   error={errors}
//   touched={touched}
// />
