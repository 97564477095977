import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputNumberText} from '../../../modules/comman/formComponents/InputNumberText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'
import {InputMultiSelect} from '../../../modules/comman/formComponents/InputMultiSelect'
import {getActiveLocationData} from '../../location/redux/LocationCRUD'
import {Spin} from 'antd'

interface IRenderFormikForm {
  values: IManagerFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IManagerFormValues {
  first_name: string
  last_name: string
  email: string
  phone: number
  password: string
  locations: Array<any>
  id: string
}

interface IAddClientProps {
  onSubmit: any
  data?: any
  handleCancel?: any
}

interface ActiveLocationType {
  value?: any
  label?: any
}

export class EditManagerForm extends React.Component<IAddClientProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      activeLocationDropdown: [],
      tempPropLocationValuesArray: [],
    }
  }

  public validateForm = (values: IManagerFormValues) => {
    const errors: any = {}
    console.log('Validating form -- ', values)

    if (!values.first_name) {
      errors.first_name = 'First name is required'
    }

    if (!values.last_name) {
      errors.last_name = 'Last name is required'
    }

    // if (!values.email) {
    //   errors.email = 'Email is required'
    // }

    if (!values.password) {
      errors.password = 'Password is required'
    }

    if (!values.phone) {
      errors.phone = 'Phone is required'
    }
    if (!values.locations) {
      errors.locations = 'Location is required'
    }
    if (values.locations && !values.locations.length) {
      errors.locations = 'Location is required'
    }
    console.log('Validating errors -- ', errors)
    return errors
  }

  public arrayFiltering = (array: any) => {
    let uniqueArray: any = []
    let i
    // Loop through array values
    for (i = 0; i < array.length; i++) {
      let obj: any = {}
      obj.location_id = array[i]
      uniqueArray.push(obj)
    }
    return uniqueArray
  }

  public handleSubmit = (values: IManagerFormValues, action: any) => {
    console.log('Basic Value - ', values)
    values.locations = this.arrayFiltering(values.locations)
    this.props.onSubmit(values, values.id)
    this.props.handleCancel()

    this.setState({
      visible: false,
    })
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  async componentDidMount(): Promise<void> {
    try {
      this.setState({
        isLoading: true,
      })
      let activeLocationData = await getActiveLocationData()
      if (activeLocationData && activeLocationData.data && activeLocationData.data.length) {
        let options = []
        for (var i = 0; i < activeLocationData.data.length; i++) {
          if (activeLocationData.data[i]._id && activeLocationData.data[i].name) {
            let obj: ActiveLocationType = {}
            obj['value'] = activeLocationData.data[i]._id
            obj['label'] = activeLocationData.data[i].name
            options.push(obj)
          }
        }
        this.setState({
          activeLocationDropdown: options,
        })
      }
      this.setState({
        isLoading: false,
      })
      let tempArray: any = []
      if (this.props.data && this.props.data.locations) {
        this.props.data.locations.map((id: any) => {
          console.log(id._id, 'ididid')
          if (id && id.location_id && id.location_id._id) {
            let tempid = id.location_id._id
            tempArray.push(tempid)
          }
        })
        this.setState({
          tempPropLocationValuesArray: tempArray,
        })
      }
    } catch (e) {
      throw e
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  public render() {
    return (
      <div className='container'>
        <div className='card card-custom'>
          <div className='card-header'>
            <h3 className='card-title'>Edit Manager Form</h3>
          </div>
          {this.state.tempPropLocationValuesArray &&
            !!this.state.tempPropLocationValuesArray.length && (
              <div className='card-body'>
                {console.log(this.state.tempPropLocationValuesArray)}
                <div>
                  <Formik
                    initialValues={{
                      first_name: this.props.data.first_name ? this.props.data.first_name : '',
                      last_name: this.props.data.last_name ? this.props.data.last_name : '',
                      email: this.props.data.email ? this.props.data.email : '',
                      password: this.props.data.password ? this.props.data.password : '',
                      phone: this.props.data.phone ? this.props.data.phone : NaN,
                      locations: this.state.tempPropLocationValuesArray
                        ? this.state.tempPropLocationValuesArray
                        : [],
                      id: this.props.data._id ? this.props.data._id : '',
                    }}
                    validate={this.validateForm}
                    onSubmit={this.handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                      setFieldTouched,
                      isSubmitting,
                    }: IRenderFormikForm) => {
                      return (
                        <div className='table-form'>
                          <div className='form-group row'>
                            <div className='col-md-6 col-lg-6 col-sm-12'>
                              <InputText
                                input={{
                                  value: values.first_name,
                                  id: 'first_name',
                                  name: 'first_name',
                                }}
                                placeholder='Enter first name'
                                onChange={(value: string) => {
                                  handleChange(value)
                                  setFieldValue('first_name', value)
                                }}
                                onBlur={handleBlur}
                                label='First Name'
                                error={errors}
                                touched={touched}
                              />
                            </div>
                            <div className='col-md-6 col-lg-6 col-sm-12'>
                              <InputText
                                input={{
                                  value: values.last_name,
                                  id: 'last_name',
                                  name: 'last_name',
                                }}
                                placeholder='Enter last name'
                                onChange={(value: string) => {
                                  handleChange(value)
                                  setFieldValue('last_name', value)
                                }}
                                onBlur={handleBlur}
                                label='Last Name'
                                error={errors}
                                touched={touched}
                              />
                            </div>
                          </div>

                          <div className='form-group row'>
                            <div className='col-md-6 col-lg-6 col-sm-12'>
                              <InputText
                                input={{
                                  value: values.email,
                                  id: 'email',
                                  name: 'email',
                                }}
                                placeholder='Enter email'
                                onChange={(value: string) => {
                                  handleChange(value)
                                  setFieldValue('email', value)
                                }}
                                onBlur={handleBlur}
                                label='Email'
                                error={errors}
                                touched={touched}
                              />
                            </div>
                            <div className='col-md-6 col-lg-6 col-sm-12'>
                              <InputNumberText
                                input={{
                                  value: values.phone,
                                  id: 'phone',
                                  name: 'phone',
                                }}
                                placeholder='Enter phone EX: (+91-6353689198)'
                                onChange={(value: any) => {
                                  handleChange(value)
                                  setFieldValue('phone', value)
                                }}
                                onBlur={handleBlur}
                                label='Phone'
                                error={errors}
                                touched={touched}
                              />
                            </div>
                          </div>

                          <div className='form-group row'>
                            {/* <div className='col-md-6 col-lg-6 col-sm-12'>
                              <InputText
                                input={{
                                  value: values.password,
                                  id: 'password',
                                  name: 'password',
                                }}
                                placeholder='Enter password'
                                onChange={(value: string) => {
                                  handleChange(value)
                                  setFieldValue('password', value)
                                }}
                                onBlur={handleBlur}
                                label='Password'
                                error={errors}
                                touched={touched}
                              />
                            </div> */}
                            <div className='col-md-6 col-lg-6 col-sm-12'>
                              <InputMultiSelect
                                input={{
                                  value: values.locations,
                                  id: 'locations',
                                  name: 'locations',
                                  options: this.state.activeLocationDropdown,
                                }}
                                placeholder='Select Location'
                                onChangeMethod={(value: Array<any>) => {
                                  setFieldValue('locations', value)
                                }}
                                label='Select Location'
                                error={errors}
                                touched={touched}
                              />
                            </div>
                          </div>

                          <InputButtonComponent onClick={handleSubmit}>Edit</InputButtonComponent>
                        </div>
                      )
                    }}
                  </Formik>
                </div>
              </div>
            )}
          {/* <div className='card-footer'></div> */}
        </div>
      </div>
    )
  }
}
