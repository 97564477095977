/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {StaffPage} from '../../component/staff'

const StaffWrapper: FC = () => {
  return (
    <div>
      <StaffPage />
    </div>
  )
}

export {StaffWrapper}
