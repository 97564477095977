import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {ClientModal} from '../models/ClientModal'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setClientData: 'SET_CLIENT_DATA',
  setClientDataTotal: 'SET_CLIENT_DATA_TOTAL',
  setClientLoading: 'SET_CLIENT_LOADER'
}

const initialClientState: IClientState = {
  clientData: undefined,
  clientDataTotal: undefined,
  clientLoading: false
}

export interface IClientState {
  clientData?: ClientModal
  clientDataTotal?: number
  clientLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'client', whitelist: ['clientData', 'clientDataTotal']},
    (state: IClientState = initialClientState, action: ActionWithPayload<IClientState>) => {
    switch (action.type) {
      case actionTypes.setClientData: {
        const clientData = action.payload?.clientData
        return {...state, clientData}
      }
            
      case actionTypes.setClientDataTotal: {
        const clientDataTotal = action.payload?.clientDataTotal
        return {...state, clientDataTotal}
      }
        
      case actionTypes.setClientLoading: {
        const clientLoading = action.payload?.clientLoading
        return {...state, clientLoading}
      }


      default:
        return state
    }
  }
)

export const actions = {
  setClientData: (clientData: Array<ClientModal>) => ({type: actionTypes.setClientData, payload: {clientData : clientData}}),
  setClientDataTotal: (clientDataTotal: number) => ({ type: actionTypes.setClientDataTotal, payload: { clientDataTotal } }),
  setClientLoading: (clientLoading: boolean) => ({type: actionTypes.setClientLoading, payload: {clientLoading}}),
}

export function* saga() {
  yield takeLatest(actionTypes.setClientData, function* loginSaga() {
    yield put(actions.setClientLoading(false))
  })

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser())
  // })

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const {data: user} = yield getUserByToken()
  //   yield put(actions.fulfillUser(user))
  // })
}
