import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputNumberText} from '../../../modules/comman/formComponents/InputNumberText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'
import {InputMultiSelect} from '../../../modules/comman/formComponents/InputMultiSelect'
import {Spin, Button, Select} from 'antd'
import {getViewPackageData} from '../redux/PackageCRUD'
import {
  returnPriceInThousandFormat,
  returnPriceInThousandFormatWithCurrencySign,
  returnStockId,
  returnStockString,
} from '../../../comman/helper'

interface IRenderFormikForm {
  values: IFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IFormValues {}

interface IAddProps {
  onSubmit: any
  data?: any
  handleCancel?: any
}

interface ActiveLocationType {
  value?: any
  label?: any
}

export class ViewPackageForm extends React.Component<IAddProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      activeLocationDropdown: [],
      tempPropLocationValuesArray: [],
      packageDataState: [],
    }
  }

  public validateForm = (values: IFormValues) => {
    const errors: any = {}
    console.log('Validating form -- ', values)

    console.log('Validating errors -- ', errors)
    return errors
  }

  public arrayFiltering = (array: any) => {
    let uniqueArray: any = []
    let i
    // Loop through array values
    for (i = 0; i < array.length; i++) {
      let obj: any = {}
      obj.location_id = array[i]
      uniqueArray.push(obj)
    }
    return uniqueArray
  }

  public handleSubmit = (values: IFormValues, action: any) => {
    console.log('Basic Value - ', values)

    // this.props.onSubmit(values, values.id)
    this.props.handleCancel()

    this.setState({
      visible: false,
    })
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  async componentDidMount(): Promise<void> {
    try {
      this.setState({
        isLoading: true,
      })
      if (this.props.data && this.props.data._id) {
        let viewData = await getViewPackageData(this.props.data._id)
        if (viewData && viewData.data) {
          this.setState({
            packageDataState: viewData.data,
          })
        }
      }

      //   let activeLocationData = await getActiveLocationData()
      //   if (activeLocationData && activeLocationData.data && activeLocationData.data.length) {
      //     let options = []
      //     for (var i = 0; i < activeLocationData.data.length; i++) {
      //       if (activeLocationData.data[i]._id && activeLocationData.data[i].name) {
      //         let obj: ActiveLocationType = {}
      //         obj['value'] = activeLocationData.data[i]._id
      //         obj['label'] = activeLocationData.data[i].name
      //         options.push(obj)
      //       }
      //     }
      //     this.setState({
      //       activeLocationDropdown: options,
      //     })
      //   }
      this.setState({
        isLoading: false,
      })
      let tempArray: any = []
      if (this.props.data && this.props.data.locations) {
        this.props.data.locations.map((id: any) => {
          console.log(id._id, 'ididid')
          if (id && id._id) {
            let tempid = id._id
            tempArray.push(tempid)
          }
        })
        this.setState({
          tempPropLocationValuesArray: tempArray,
        })
      }
    } catch (e) {
      throw e
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  public render() {
    return (
      //   <>
      //     {this.state.packageDataState && this.state.packageDataState.length && (
      //       <div>
      //         <div className='form-group row' style={{marginBottom: '20px'}}>
      //           <div className='col-md-12 col-lg-4 col-sm-12'>
      //             <h4 style={{marginBottom: '8px', fontWeight: 'bold'}}>Name</h4>
      //             <span style={{fontSize: '15px'}}>
      //               {this.props.data.name ? this.props.data.name : '-'}
      //             </span>
      //           </div>
      //           <div className='col-md-12 col-lg-4 col-sm-12'>
      //             <h4 style={{marginBottom: '8px', fontWeight: 'bold'}}>Total Stones</h4>
      //             <span style={{fontSize: '15px'}}>
      //               {this.props.data.total_stones == null || undefined
      //                 ? '-'
      //                 : this.props.data.total_stones}
      //             </span>
      //           </div>
      //           <div className='col-md-12 col-lg-4 col-sm-12'>
      //             <h4 style={{marginBottom: '8px', fontWeight: 'bold'}}>Total Weight</h4>
      //             <span style={{fontSize: '15px'}}>
      //               {this.props.data.total_weight == null || undefined
      //                 ? '-'
      //                 : this.props.data.total_weight}
      //             </span>
      //           </div>
      //         </div>

      //         <div className='form-group row' style={{marginBottom: '20px'}}>
      //           <div className='col-md-12 col-lg-6 col-sm-12'>
      //             <h4 style={{marginBottom: '8px', fontWeight: 'bold'}}>Total Certified Stones</h4>
      //             <span style={{fontSize: '15px'}}>
      //               {this.props.data.total_certified_stones
      //                 ? this.props.data.total_certified_stones
      //                 : '-'}
      //             </span>
      //           </div>
      //           <div className='col-md-12 col-lg-6 col-sm-12'>
      //             <h4 style={{marginBottom: '8px', fontWeight: 'bold'}}>Total Certified Weight</h4>
      //             <span style={{fontSize: '15px'}}>
      //               {this.props.data.total_stones == null || undefined
      //                 ? '-'
      //                 : this.props.data.total_stones}
      //             </span>
      //           </div>
      //         </div>

      //         <div className='form-group row' style={{marginBottom: '20px'}}>
      //           <h4 className='col-md-12 col-lg-6 col-sm-12'>
      //             <div style={{marginBottom: '8px', fontWeight: 'bold'}}>
      //               Total Non Certified Stones
      //             </div>
      //             <span style={{fontSize: '15px'}}>
      //               {this.props.data.total_non_certified_stones
      //                 ? this.props.data.total_non_certified_stones
      //                 : '-'}
      //             </span>
      //           </h4>
      //           <div className='col-md-12 col-lg-6 col-sm-12'>
      //             <h4 style={{marginBottom: '8px', fontWeight: 'bold'}}>
      //               Total Non Certified Weight
      //             </h4>
      //             <span style={{fontSize: '15px'}}>
      //               {this.props.data.total_non_certified_weight == null || undefined
      //                 ? '-'
      //                 : this.props.data.total_non_certified_weight}
      //             </span>
      //           </div>
      //         </div>

      //         <div className='form-group row' style={{marginBottom: '20px'}}>
      //           <div className='col-md-12 col-lg-6 col-sm-12'>
      //             <h4 style={{marginBottom: '8px', fontWeight: 'bold'}}>Total Star Melee Stones</h4>
      //             <span style={{fontSize: '15px'}}>
      //               {this.props.data.total_star_melee_stones
      //                 ? this.props.data.total_star_melee_stones
      //                 : '-'}
      //             </span>
      //           </div>
      //           <div className='col-md-12 col-lg-6 col-sm-12'>
      //             <h4 style={{marginBottom: '8px', fontWeight: 'bold'}}>Total Star Melee Weight</h4>
      //             <span style={{fontSize: '15px'}}>
      //               {this.props.data.total_star_melee_weight == null || undefined
      //                 ? '-'
      //                 : this.props.data.total_star_melee_weight}
      //             </span>
      //           </div>
      //         </div>

      //         <>
      //           <Button
      //             // type="primary"
      //             style={{marginTop: '19px', background: '#5C6AC4', color: '#fff'}}
      //             onClick={() => {}}
      //           >
      //             Done
      //           </Button>
      //         </>
      //       </div>
      //     )}
      //   </>
      <div className='card card-custom'>
        {this.props.data && this.state.packageDataState ? (
          <>
            {console.log(this.state.packageDataState)}
            <div className='card-body'>
              <div>
                <div className='form-group'>
                  <div className='row'>
                    <div className='col-md-2 col-lg-2 col-sm-12 font-weight-bold text-muted'>
                      <label style={{fontSize: '14px', textAlign: 'center'}}>{'Name:'}</label>
                    </div>
                    <div className='col-md-2 col-lg-2 col-sm-12'>
                      <label>{this.props.data.name ? this.props.data.name : '-'}</label>
                    </div>

                    <div className='col-md-2 col-lg-2 col-sm-12 font-weight-bold text-muted'>
                      <label style={{fontSize: '14px'}}>{'Total Stones:'}</label>
                    </div>
                    <div className='col-md-2 col-lg-2 col-sm-12'>
                      <label>
                        {this.props.data.total_stones ? this.props.data.total_stones : '-'}
                      </label>
                    </div>
                    <div className='col-md-2 col-lg-2 col-sm-12 font-weight-bold text-muted'>
                      <label style={{fontSize: '14px'}}>{'Total Weight:'}</label>
                    </div>
                    <div className='col-md-2 col-lg-2 col-sm-12'>
                      <label>
                        {this.props.data.total_weight ? this.props.data.total_weight : '-'}
                      </label>
                    </div>
                  </div>
                </div>
                <hr />
                <h3 className='mb-10' style={{marginTop: '25px'}}>
                  {'CERTIFIED STONE DETAILS'}
                </h3>
                {this.state.packageDataState &&
                this.state.packageDataState.certified_stones &&
                !!this.state.packageDataState.certified_stones.length ? (
                  <>
                    <div className='row border-bottom'>
                      <div className='col-lg-3 text-dark font-weight-bold text-muted'>
                        {'Stock Id'}
                      </div>
                      {/* <div className='col-lg-4 text-dark font-weight-bold text-muted'>{'Details'}</div> */}
                      <div className='col-lg-3 text-dark font-weight-bold text-muted'>
                        {'Price Per Caret'}
                      </div>
                      <div className='col-lg-3 text-dark font-weight-bold text-muted'>
                        <div className='text-center'>{'Total Weight'}</div>
                      </div>
                      <div className='col-lg-3 text-dark font-weight-bold text-muted'>
                        {'Total Prices'}
                      </div>
                    </div>
                    <div style={{maxHeight: '450px', overflowY: 'scroll', overflowX: 'hidden'}}>
                      {this.state.packageDataState &&
                        this.state.packageDataState.certified_stones &&
                        !!this.state.packageDataState.certified_stones.length &&
                        this.state.packageDataState.certified_stones.map(
                          (invItem: any, index: number) =>
                            invItem && (
                              <div className='row mt-10' key={index}>
                                <div className='col-lg-3 text-dark font-weight-bold'>
                                  <label>
                                    {/* <a
                                  href={`inventory/preview/${invItem.stock_data._id}`}
                                  target='_blank'
                                >
                                  {returnStockId(invItem.stock_data.stock_id)}
                                </a> */}
                                    {invItem.stock_id
                                      ? invItem.stock_id.stock_id
                                        ? invItem.stock_id.stock_id
                                        : '-'
                                      : '-'}
                                  </label>
                                </div>
                                <div className='col-lg-3 text-dark font-weight-bold'>
                                  <label>
                                    {invItem.stock_id?.price_per_caret
                                      ? invItem.stock_id?.price_per_caret
                                      : '-'}
                                  </label>
                                </div>
                                <div className='col-lg-3 text-dark font-weight-bold'>
                                  <label>
                                    {invItem.stock_id?.weight ? invItem.stock_id?.weight : '-'}
                                  </label>
                                </div>
                                <div className='col-lg-3 text-dark font-weight-bold'>
                                  <label>
                                    {invItem.stock_id?.total_price
                                      ? invItem.stock_id?.total_price
                                      : '-'}
                                  </label>
                                </div>
                              </div>
                            )
                        )}
                    </div>
                  </>
                ) : (
                  <div
                    style={{marginTop: '10px'}}
                    className='alert alert-danger text-center font-weight-bold'
                    role='alert'
                  >
                    NO DETAILS FOUND
                  </div>
                )}
                <hr />
                <h3 className='mb-10' style={{marginTop: '25px'}}>
                  {'NON CERTIFIED STONE DETAILS'}
                </h3>
                {this.state.packageDataState &&
                this.state.packageDataState.non_certified_stones &&
                !!this.state.packageDataState.non_certified_stones.length ? (
                  <>
                    <div className='row border-bottom'>
                      <div className='col-lg-3 text-dark font-weight-bold text-muted'>
                        {'Stock Id'}
                      </div>
                      {/* <div className='col-lg-4 text-dark font-weight-bold text-muted'>{'Details'}</div> */}
                      <div className='col-lg-3 text-dark font-weight-bold text-muted'>
                        {'Total Stones'}
                      </div>
                      <div className='col-lg-3 text-dark font-weight-bold text-muted'>
                        <div className='text-center'>{'Total Weight'}</div>
                      </div>
                      <div className='col-lg-3 text-dark font-weight-bold text-muted'>
                        {'Total Prices'}
                      </div>
                    </div>
                    <div style={{maxHeight: '450px', overflowY: 'scroll', overflowX: 'hidden'}}>
                      {this.state.packageDataState &&
                        this.state.packageDataState.non_certified_stones &&
                        !!this.state.packageDataState.non_certified_stones.length &&
                        this.state.packageDataState.non_certified_stones.map(
                          (invItem: any, index: number) =>
                            invItem && (
                              <div className='row mt-10' key={index}>
                                <div className='col-lg-3 text-dark font-weight-bold'>
                                  <label>
                                    {/* <a
                                  href={`inventory/preview/${invItem.stock_data._id}`}
                                  target='_blank'
                                >
                                  {returnStockId(invItem.stock_data.stock_id)}
                                </a> */}
                                    {invItem.stock_id
                                      ? invItem.stock_id.stock_id
                                        ? invItem.stock_id.stock_id
                                        : '-'
                                      : '-'}
                                  </label>
                                </div>
                                <div className='col-lg-3 text-dark font-weight-bold'>
                                  <label>
                                    {invItem.stock_id?.packages[0]?.used_no_of_stones
                                      ? invItem.stock_id?.packages[0]?.used_no_of_stones
                                      : '-'}
                                  </label>
                                </div>
                                <div className='col-lg-3 text-dark font-weight-bold'>
                                  <label>
                                    {invItem.stock_id?.packages[0]?.used_weight
                                      ? invItem.stock_id?.packages[0]?.used_weight
                                      : '-'}
                                  </label>
                                </div>
                                <div className='col-lg-3 text-dark font-weight-bold'>
                                  <label>
                                    {invItem.stock_id?.packages[0]?.total_price
                                      ? invItem.stock_id?.packages[0]?.total_price
                                      : '-'}
                                  </label>
                                </div>
                              </div>
                            )
                        )}
                    </div>
                  </>
                ) : (
                  <div
                    style={{marginTop: '10px'}}
                    className='alert alert-danger text-center font-weight-bold'
                    role='alert'
                  >
                    NO DETAILS FOUND
                  </div>
                )}
                <hr />
                <h3 className='mb-10' style={{marginTop: '25px'}}>
                  {'STAR MELEE STONE DETAILS'}
                </h3>
                {this.state.packageDataState &&
                this.state.packageDataState.melee_stones &&
                !!this.state.packageDataState.melee_stones.length ? (
                  <>
                    <div className='row border-bottom'>
                      <div className='col-lg-3 text-dark font-weight-bold text-muted'>
                        {'Stock Id'}
                      </div>
                      {/* <div className='col-lg-4 text-dark font-weight-bold text-muted'>{'Details'}</div> */}
                      <div className='col-lg-3 text-dark font-weight-bold text-muted'>
                        {'Total Stones'}
                      </div>
                      <div className='col-lg-3 text-dark font-weight-bold text-muted'>
                        <div className='text-center'>{'Total Weight'}</div>
                      </div>
                      <div className='col-lg-3 text-dark font-weight-bold text-muted'>
                        {'Total Prices'}
                      </div>
                    </div>
                    <div style={{maxHeight: '450px', overflowY: 'scroll', overflowX: 'hidden'}}>
                      {this.state.packageDataState &&
                        this.state.packageDataState.melee_stones &&
                        !!this.state.packageDataState.melee_stones.length &&
                        this.state.packageDataState.melee_stones.map(
                          (invItem: any, index: number) =>
                            invItem && (
                              <div className='row mt-10' key={index}>
                                <div className='col-lg-3 text-dark font-weight-bold'>
                                  <label>
                                    {/* <a
                                  href={`inventory/preview/${invItem.stock_data._id}`}
                                  target='_blank'
                                >
                                  {returnStockId(invItem.stock_data.stock_id)}
                                </a> */}
                                    {invItem.stock_id
                                      ? invItem.stock_id.stock_id
                                        ? invItem.stock_id.stock_id
                                        : '-'
                                      : '-'}
                                  </label>
                                </div>
                                <div className='col-lg-3 text-dark font-weight-bold'>
                                  <label>
                                    {invItem.stock_id?.packages[0]?.used_no_of_stones
                                      ? invItem.stock_id?.packages[0]?.used_no_of_stones
                                      : '-'}
                                  </label>
                                </div>
                                <div className='col-lg-3 text-dark font-weight-bold'>
                                  <label>
                                    {invItem.stock_id?.packages[0]?.used_weight
                                      ? invItem.stock_id?.packages[0]?.used_weight
                                      : '-'}
                                  </label>
                                </div>
                                <div className='col-lg-3 text-dark font-weight-bold'>
                                  <label>
                                    {invItem.stock_id?.packages[0]?.total_price
                                      ? invItem.stock_id?.packages[0]?.total_price
                                      : '-'}
                                  </label>
                                </div>
                              </div>
                            )
                        )}
                    </div>
                  </>
                ) : (
                  <div
                    style={{marginTop: '10px'}}
                    className='alert alert-danger text-center font-weight-bold'
                    role='alert'
                  >
                    NO DETAILS FOUND
                  </div>
                )}
                {/* <div className='row border-bottom'>
              <div className='col-lg-6 font-weight-bold text-muted'>{'Stock Id'}</div>
              <div className='col-lg-6 font-weight-bold text-muted'>
                <div className='w-25 text-right'>{'Price'}</div>
              </div>
            </div> */}
                {/* {data.buy_request_id && data.buy_request_id.stock_diamonds ? (
              <div className='overflow-auto' style={{maxHeight: '300px'}}>
                {data.buy_request_id.stock_diamonds.map((stockItem: any, index: number) => (
                  <div className='row mt-10' key={index}>
                    <div className='col-lg-6 text-dark font-weight-bold'>
                      <label>
                        <a
                          href={`/inventory/preview/${stockItem.stock_id._id}`}
                          target='_blank'
                        >
                          {returnStockId(stockItem.stock_id.stock_id)}
                        </a>
                      </label>
                    </div>
                    <div className='col-lg-6 text-dark font-weight-bold'>
                      <div className='w-25 text-right'>
                        <label>
                          {(Math.round(stockItem.stock_id.final_price * 100) / 100).toFixed(2)}
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              '-'
            )}{' '} */}
              </div>
            </div>
          </>
        ) : (
          'Please wait'
        )}
      </div>
    )
  }
}
