import {reducer} from './../../app/modules/auth/redux/AuthRedux'
import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from '../../app/modules/auth'
import * as client from '../../app/component/client'
import * as dashboard from '../../app/component/dashboard'
import * as location from '../../app/component/location'
import * as manager from '../../app/component/manager'
import * as staff from '../../app/component/staff'
import * as inventory from '../../app/component/inventory'
import * as packages from '../../app/component/package'
import * as courier from '../../app/component/courier'
import * as tally from '../../app/component/Tally'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  client: client.reducer,
  dashboard: dashboard.reducer,
  location: location.reducer,
  manager: manager.reducer,
  staff: staff.reducer,
  inventory: inventory.reducer,
  packages: packages.reducer,
  courier: courier.reducer,
  tally: tally.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([
    auth.saga(),
    client.saga(),
    dashboard.saga(),
    location.saga(),
    staff.saga(),
    manager.saga(),
    inventory.saga(),
    packages.saga(),
    courier.saga(),
    tally.saga(),
  ])
}
