import React from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_COURIER_URL = `${API_URL}api/v1/auth/admin/couriers`
export const SEARCH_COURIER_URL = `${API_URL}api/v1/auth/admin/couriers`
export const CREATE_COURIER_URL = `${API_URL}api/v1/auth/admin/courier/`
export const EDIT_COURIER_URL = `${API_URL}api/v1/auth/admin/courier/`
export const DELETE_COURIER_URL = `${API_URL}api/v1/auth/admin/courier`
export const CHANGE_STATUS_COURIER_URL = `${API_URL}api/v1/auth/admin/courier/`
export const GET_ACTIVE_COURIER_URL = `${API_URL}api/v1/auth/admin/active-courier/`
export const GET_VIEW_COURIER_URL = `${API_URL}api/v1/auth/admin/courier/`

// Get courier Data
export async function getCourierData(startIndex: number, limitNumber: number) {
  try {
    let courierData = await axios.get(`${GET_COURIER_URL}?skip=${startIndex}&limit=${limitNumber}`)
    return courierData
  } catch (e) {
    throw e
  }
}

// Get courier Data
export async function getViewCourierData(courierId: string) {
  try {
    let courierData = await axios.get(`${GET_VIEW_COURIER_URL}${courierId}`)
    return courierData
  } catch (e) {
    throw e
  }
}

export async function getActiveCourierData() {
  try {
    let courierData = await axios.get(`${GET_ACTIVE_COURIER_URL}`)
    return courierData
  } catch (e) {
    throw e
  }
}

// Search courier Data
export async function getSearchCourierData(
  startIndex: number,
  limitNumber: number,
  searchText: string
) {
  try {
    let courierData = await axios.get(
      `${SEARCH_COURIER_URL}?skip=${startIndex}&limit=${limitNumber}&searchText=${searchText}`
    )
    return courierData
  } catch (e) {
    throw e
  }
}

// Create Courier Data
export async function createCourierData(courierObject: any) {
  try {
    let createCourierData = await axios.post(`${CREATE_COURIER_URL}`, {
      ...courierObject,
    })
    return createCourierData
  } catch (e) {
    throw e
  }
}

// Delete Courier Data
export async function deleteCourierData(courierId: string) {
  try {
    let deleteCourierData = await axios.delete(`${DELETE_COURIER_URL}?courier_id=${courierId}`)
    return deleteCourierData
  } catch (e) {
    throw e
  }
}

// Edit Courier Data
export async function editCourierData(courierObject: any, id: any) {
  try {
    let editCourierData = await axios.put(`${EDIT_COURIER_URL}`, {
      ...courierObject,
    })
    return editCourierData
  } catch (e) {
    throw e
  }
}
