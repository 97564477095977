/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as inventory from '../redux/InventoryRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {
  UserOutlined,
  FieldTimeOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  InfoOutlined,
} from '@ant-design/icons'
import {
  changeStatusInventoryData,
  deleteInventoryData,
  editInventoryData,
  exportAllStockList,
  exportSelectedStockList,
  getDownloadLabelUrl,
  getInventoryData,
  getSearchInventoryData,
  deleteSingleStockData,
  editSingleStockData,
  createStoneDataApi,
} from '../redux/InventoryCRUD'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/Toaster'
import {CommanModal} from '../../../modules/comman/components/Modal'
import {IInventoryFormValues} from './CreateInventoryForm'
import {Tag} from 'antd'
import {
  returnPriceInThousandFormat,
  returnPriceInThousandFormatWithCurrencySign,
  returnStockId,
} from '../../../comman/helper'
import {ViewTrailForm} from './ViewTrailForm'
import {type} from 'os'
import {EditStockCertDataForm} from './EditStockCertDataForm'
import ViewMemoData from './ViewMemoData'
import {CreateCertStoneForm} from './CreateCertStoneForm'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
const accessArray = ['client']
interface IRecordsTableProps {
  inventoryData: any
  searchParamsData: any
  searchTextData: any
  locationData: any
  inventoryDataTotal: number
  inventoryLoading: boolean
  inventoryCartData: any
  setListInventoryData: (inventoryData: any) => void
  setListInventoryDataTotal: (inventoryDataTotal: number) => void
  setInventoryLoading: (inventoryLoading: boolean) => void
  setInventoryCartData: (inventoryCartData: any) => void
  history?: History<LocationState>
  loginUser: any
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  inventorySelectedData: any
  dataTableSelectedRowKeys: any
  inventorySelectedStockData: any
  inventorySelectedMemoData: any
  createNewStoneData: any
}

type PathParamsType = {
  param1: string
}
class ListInventoryData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      inventorySelectedData: null,
      dataTableSelectedRowKeys: [],
      inventorySelectedStockData: null,
      inventorySelectedMemoData: null,
      createNewStoneData: false,
    }
  }

  tempBuyRequests: any = []

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListInventoryData(pagination)
    )
  }

  public componentDidMount = () => {
    this.setInitialDataTableValue()
  }

  public setInitialDataTableValue = () => {
    const {inventoryCartData} = this.props
    if (inventoryCartData && inventoryCartData.length >= 1) {
      this.setState({
        dataTableSelectedRowKeys: inventoryCartData.map((item: any) => item._id),
      })
    }
  }

  public getListInventoryData = async (pagination: any) => {
    const {searchText} = this.state

    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listInventorySearchData(start, end)
        } else {
          this.listInventoryData(start, end)
        }
        this.setInitialDataTableValue()
      }
    )
  }

  public listInventoryData = async (start: number, end: any) => {
    const {setInventoryLoading, searchParamsData, searchTextData,locationData} = this.props
    try {
      const {setListInventoryData, setListInventoryDataTotal} = this.props
      await setInventoryLoading(true)
      let inventoryData = await getSearchInventoryData(
        start,
        end,
        searchParamsData,
        'CERT',
        searchTextData,
        locationData
      )
      if (inventoryData.data) {
        await setListInventoryData(inventoryData.data.docs)
        await setListInventoryDataTotal(inventoryData.data.totalDocs)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setInventoryLoading(false)
    }
  }

  public listInventorySearchData = async (start: number, end: any) => {
    const {setInventoryLoading} = this.props
    try {
      const {setListInventoryData, setListInventoryDataTotal, searchParamsData, searchTextData,locationData} =
        this.props
      const {searchText} = this.state

      await setInventoryLoading(true)
      let inventoryData = await getSearchInventoryData(
        start,
        end,
        searchParamsData,
        'CERT',
        searchTextData,
        locationData
      )
      if (inventoryData.data) {
        await setListInventoryData(inventoryData.data.docs)
        await setListInventoryDataTotal(inventoryData.data.totalDocs)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setInventoryLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listInventorySearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public onCreateInventory = () => {
    const {history} = this.props
    history.push('/inventory/import')
  }

  public deleteInventory = async (item: any) => {
    const {setInventoryLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setInventoryLoading(true)
      await deleteInventoryData(item._id)

      Toaster({
        type: 'success',
        title: 'INVENTORY',
        description: 'Deleted SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listInventorySearchData(startIndex, limitNumber)
        return
      }
      this.listInventoryData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setInventoryLoading(false)
    }
  }

  public onChangeStatus = async (item: any, checked: boolean) => {
    const {setInventoryLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setInventoryLoading(true)
      await changeStatusInventoryData(item._id, checked)

      Toaster({
        type: 'success',
        title: 'INVENTORY',
        description: 'Status changed successFully',
      })

      if (searchText != null && searchText !== '') {
        this.listInventorySearchData(startIndex, limitNumber)
        return
      }
      this.listInventoryData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setInventoryLoading(false)
    }
  }

  public onEditSubmit = async (values: IInventoryFormValues, inventoryId: string) => {
    const {setInventoryLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setInventoryLoading(true)
      await editInventoryData(values, inventoryId)

      Toaster({
        type: 'success',
        title: 'INVENTORY',
        description: 'Edited SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listInventorySearchData(startIndex, limitNumber)
        return
      }
      this.listInventoryData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setInventoryLoading(false)
    }
  }

  public onEditStockSubmit = async (values: IInventoryFormValues, inventoryId: string) => {
    const {setInventoryLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setInventoryLoading(true)
      await editSingleStockData(values, inventoryId)

      Toaster({
        type: 'success',
        title: 'STOCK',
        description: 'Edited SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listInventorySearchData(startIndex, limitNumber)
        return
      }
      this.listInventoryData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setInventoryLoading(false)
    }
  }

  public onCreateStockSubmit = async (values: any) => {
    const {setInventoryLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setInventoryLoading(true)
      await createStoneDataApi(values)

      Toaster({
        type: 'success',
        title: 'STOCK',
        description: 'Created SuccessFully',
      })

      // if (searchText != null && searchText !== '') {
      //   this.listInventorySearchData(startIndex, limitNumber)
      //   return
      // }
      // this.listInventoryData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setInventoryLoading(false)
    }
  }

  public deleteSingleStockDataItem = async (item: any) => {
    const {setInventoryLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setInventoryLoading(true)
      await deleteSingleStockData(item._id)

      Toaster({
        type: 'success',
        title: 'STOCK',
        description: 'Deleted SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listInventorySearchData(startIndex, limitNumber)
        return
      }
      this.listInventoryData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setInventoryLoading(false)
    }
  }

  public onCreateStone = async () => {
    this.setState({
      createNewStoneData: true,
    })
  }

  public closeCreateStone = async () => {
    this.setState({
      createNewStoneData: false,
    })
    const {startIndex, limitNumber, searchText} = this.state
    if (searchText != null && searchText !== '') {
      this.listInventorySearchData(startIndex, limitNumber)
      return
    }
    this.listInventoryData(startIndex, limitNumber)
  }

  public showEditModal = async (item: any) => {
    this.setState({
      inventorySelectedData: item,
    })
  }

  public showEditStockModal = async (item: any) => {
    this.setState({
      inventorySelectedStockData: item,
    })
  }

  public showViewStockMemoModal = async (item: any) => {
    this.setState({
      inventorySelectedMemoData: item,
    })
  }

  public closeViewStockMemoModal = async () => {
    this.setState({
      inventorySelectedMemoData: null,
    })
    const {startIndex, limitNumber, searchText} = this.state
    if (searchText != null && searchText !== '') {
      this.listInventorySearchData(startIndex, limitNumber)
      return
    }
    this.listInventoryData(startIndex, limitNumber)
  }

  public closeEditStockModal = async () => {
    this.setState({
      inventorySelectedStockData: null,
    })
    const {startIndex, limitNumber, searchText} = this.state
    if (searchText != null && searchText !== '') {
      this.listInventorySearchData(startIndex, limitNumber)
      return
    }
    this.listInventoryData(startIndex, limitNumber)
  }

  public previewInventoryForm = async (item: any) => {
    const {history} = this.props
    history.push('/inventory/preview/' + item._id)
  }

  public closeEditModal = async () => {
    this.setState({
      inventorySelectedData: null,
    })
  }

  counter = 0
  tempCounter = 0
  public columns = [
    // {
    //   title: 'Sr. No.',
    //   key: 'serial_number',
    //   fixed: window.innerWidth < 768 ? false : true,
    //   width: 60,
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         <span>{++index + this.tempCounter}</span>
    //       </div>
    //     )
    //   },
    // },
    {
      // title: 'Stock Id',
      title: 'STOCK ID',
      key: 'stock_id',
      width: 80,
      fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <a href={`/inventory/preview/${item._id}`} target='_blank'>
              <strong>{item.stock_id ? returnStockId(item.stock_id) : '-'}</strong>
            </a>
          </div>
        )
      },
    },
    {
      // title: 'Status',
      title: 'STATUS',
      key: 'status',
      width: 80,
      fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {' '}
            {item.status ? (
              <Tag
                color={
                  config.statusTagColor[item.status]
                    ? config.statusTagColor[item.status]
                    : 'default'
                }
              >
                {item.status}
              </Tag>
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      // title: 'Shape',
      title: 'Client',
      key: 'client_name',
      width: 70,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
       { item.status == 'MEMO' || item.status == 'SOLD' ?   
        <>
              {item.memos ? item.memos?.map((obj: any) => 
                <>
                {console.log(obj,"obj!!")}
                {obj.memo_id ? obj.memo_id.client_id ? obj.memo_id.client_id.first_name && obj.memo_id.client_id.last_name
                  ? `| ${obj.memo_id.client_id.first_name} ${obj.memo_id.client_id.last_name} |`
                  : `| ${obj.memo_id.client_id.first_name} |`
                  ? `| ${obj.memo_id.client_id.first_name} |`
                  : '-'
                : '-'
                : '-'
                }
                </>
              ) : <>
              -
              </>}
              </>
              : <>
              -
              </>}
          </div>
        )
      },
    },
    {
      // title: 'Stock Id',
      title: 'OLD STOCK ID',
      key: 'old_stock_id',
      width: 80,
      render: (text: any, item: any, index: number) => {
        return <div>{item.old_stock_id ? returnStockId(item.old_stock_id) : '-'}</div>
      },
    },
    {
      // title: 'Image',
      title: 'MEDIA',
      key: 'diamond_image',
      width: 90,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.diamond_image ? (
              <a href={item.diamond_image} target='_blank'>
                {item.diamond_image}
              </a>
            ) : (
              <span className='text-muted'>{'No Image Available'}</span>
            )}
            {' | '}
            {item.video_link ? (
              <a href={item.video_link} target='_blank'>
                {item.video_link}
              </a>
            ) : item.video_file_name ? (
              <a href={`${API_URL}stock/video/${item.video_file_name}`} target='_blank'>
                {`${API_URL}stock/video/${item.video_file_name}`}
              </a>
            ) : (
              <span className='text-muted'>{'No Video Available'}</span>
            )}
          </div>
        )
      },
    },
    {
      // title: 'Shape',
      title: 'SHAPE',
      key: 'shape',
      width: 70,
      render: (text: any, item: any, index: number) => {
        return <div>{item.shape ? returnStockId(item.shape) : '-'}</div>
      },
    },
    {
      // title: 'Weight',
      title: 'WEIGHT',
      key: 'weight',
      width: 75,
      render: (text: any, item: any, index: number) => {
        return <div>{item.weight ? item.weight : '-'}</div>
      },
    },
    {
      // title: 'Color',
      title: 'COLOR',
      key: 'color',
      // ellipsis: true,
      // width: 40,
      width: 65,
      render: (text: any, item: any, index: number) => {
        return <div>{item.color ? returnStockId(item.color) : '-'}</div>
      },
    },
    {
      // title: 'Clarity',
      title: 'CLARITY',
      key: 'clarity',
      width: 75,
      render: (text: any, item: any, index: number) => {
        return <div>{item.clarity ? returnStockId(item.clarity) : '-'}</div>
      },
    },
    {
      // title: 'Cut',
      title: 'CUT',
      key: 'cut',
      // width: 40,
      width: 45,
      render: (text: any, item: any, index: number) => {
        return <div>{item.cut ? returnStockId(item.cut) : '-'}</div>
      },
    },
    {
      // title: 'Polish',
      title: 'POLISH',
      // ellipsis: true,
      key: 'polish',
      width: 70,
      render: (text: any, item: any, index: number) => {
        return <div>{item.polish ? returnStockId(item.polish) : '-'}</div>
      },
    },
    {
      // title: 'Symmetry',
      title: 'SYM',
      // ellipsis: true,
      key: 'symmetry',
      width: 50,
      render: (text: any, item: any, index: number) => {
        return <div>{item.symmetry ? returnStockId(item.symmetry) : '-'}</div>
      },
    },

    {
      // title: 'Fluorescence Intensity',
      title: 'FL',
      // ellipsis: true,
      key: 'fluorescence',
      width: 50,
      render: (text: any, item: any, index: number) => {
        return <div>{item.fluorescence ? returnStockId(item.fluorescence) : '-'}</div>
      },
    },
    {
      // title: 'Lab Grown Type',
      title: 'LAB',
      // ellipsis: true,
      key: 'lab_grown_type',
      width: 50,
      render: (text: any, item: any, index: number) => {
        return <div>{item.lab_grown_type ? item.lab_grown_type : '-'}</div>
      },
    },
    {
      // title: 'Certificate No.',
      title: 'CERTIFICATE',
      key: 'cert_number',
      width: 100,
      // ellipsis: true,
      render: (text: any, item: any, index: number) => {
        return <div>{item.cert_number ? item.cert_number : '-'}</div>
      },
    },
    {
      // title: 'Discounts',
      title: 'DISCOUNTS',
      // ellipsis: true,
      key: 'discount',
      width: 100,
      render: (text: any, item: any, index: number) => {
        return <div>{item.discount ? item.discount : '-'}</div>
      },
    },
    {
      // title: 'Price Per Caret',
      title: '$/CT',
      // ellipsis: true,
      key: 'price_per_caret',
      width: 60,
      render: (text: any, item: any, index: number) => {
        return <div>{item.price_per_caret ? item.price_per_caret : '-'}</div>
      },
    },
    {
      // title: 'Final Price',
      title: 'FINAL PRICE',
      // ellipsis: true,
      key: 'final_price',
      width: 100,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.total_price ? returnPriceInThousandFormatWithCurrencySign(item.total_price) : '-'}
          </div>
        )
      },
    },
    // {
    //   // title: 'Final Price Ori',
    //   title: 'FINAL PRICE ORI',
    //   // ellipsis: true,
    //   key: 'final_price_ori',
    //   width: 130,
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         {item.final_price_ori
    //           ? returnPriceInThousandFormatWithCurrencySign(item.final_price_ori)
    //           : '-'}
    //       </div>
    //     )
    //   },
    // },
    // {
    //   // title: 'Availability',
    //   title: 'AVAILABILITY',
    //   // ellipsis: true,
    //   key: 'availability',
    //   width: 110,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.availability ? returnStockId(item.availability) : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Brand',
    //   title: 'BRAND',
    //   key: 'brand',
    //   width: 105,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.brand ? returnStockId(item.brand) : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Crown Angle',
    //   title: 'CROWN ANGLE',
    //   // ellipsis: true,
    //   key: 'crown_angle',
    //   width: 70,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.crown_angle ? item.crown_angle : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Crown Height',
    //   title: 'CROWN HEIGHT',
    //   // ellipsis: true,
    //   key: 'crown_height',
    //   width: 70,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.crown_height ? item.crown_height : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Culet Condition',
    //   title: 'CULET CONDITION',
    //   // ellipsis: true,
    //   key: 'culet_condition',
    //   width: 100,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.culet_condition ? returnStockId(item.culet_condition) : '-'}</div>
    //   },
    // },
    {
      // title: 'Depth',
      title: 'DEPTH',
      key: 'depth',
      width: 70,
      render: (text: any, item: any, index: number) => {
        return <div>{item.depth ? item.depth : '-'}</div>
      },
    },
    {
      // title: 'Table',
      title: 'TABLE',
      key: 'table',
      width: 70,
      render: (text: any, item: any, index: number) => {
        return <div>{item.table ? item.table : '-'}</div>
      },
    },
    // {
    //   // title: 'Type',
    //   title: 'TYPE',
    //   key: 'diamond_type',
    //   width: 60,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.diamond_type ? returnStockId(item.diamond_type) : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Girdle Condition',
    //   title: 'GIRDLE CONDITION',
    //   // ellipsis: true,
    //   key: 'girdle_condition',
    //   width: 100,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.girdle_condition ? returnStockId(item.girdle_condition) : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Lw Ratio',
    //   title: 'LW RATIO',
    //   // ellipsis: true,
    //   key: 'lw_ratio',
    //   width: 80,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.lw_ratio ? item.lw_ratio : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Meas Depth',
    //   title: 'MEAS DEPTH',
    //   // ellipsis: true,
    //   key: 'meas_depth',
    //   width: 80,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.meas_depth ? item.meas_depth : '-'}</div>
    //   },
    // },
    {
      // title: 'Measurements',
      title: 'MEASUREMENTS',
      // ellipsis: true,
      key: 'measurement',
      width: 140,
      render: (text: any, item: any, index: number) => {
        return <div>{item.measurement ? item.measurement : '-'}</div>
      },
    },
    // {
    //   // title: 'Pavilion Angle',
    //   title: 'PAVILION ANGLE',
    //   // ellipsis: true,
    //   key: 'pavilion_angle',
    //   width: 80,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.pavilion_angle ? item.pavilion_angle : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Pavilion Depth',
    //   title: 'PAVILION DEPTH',
    //   // ellipsis: true,
    //   key: 'pavilion_depth',
    //   width: 80,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.pavilion_depth ? item.pavilion_depth : '-'}</div>
    //   },
    // },
    // {
    //   // title: 'Rap Per Caret',
    //   title: 'RAP PER CARET',
    //   // ellipsis: true,
    //   key: 'rap_per_caret',
    //   width: 80,
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>{item.rap_per_caret ? returnPriceInThousandFormat(item.rap_per_caret) : '-'}</div>
    //     )
    //   },
    // },
    {
      // title: 'Rap Price',
      title: 'RAP PRICE',
      // ellipsis: true,
      key: 'rap',
      width: 80,
      render: (text: any, item: any, index: number) => {
        return <div>{item.rap ? item.rap : '-'}</div>
      },
    },
    // {
    //   // title: 'Treatment',
    //   title: 'TREATMENT',
    //   // ellipsis: true,
    //   key: 'treatment',
    //   width: 100,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.treatment ? returnStockId(item.treatment) : '-'}</div>
    //   },
    // },
    // {
    //   title: 'updatedAt',
    //   key: 'updatedAt',
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.updatedAt ? item.updatedAt : '-'}</div>
    //   },
    // },
    // {
    //   title: 'createdAt',
    //   key: 'createdAt',
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.createdAt ? item.createdAt : '-'}</div>
    //   },
    // },
    // {
    //   title: 'isActive',
    //   key: 'isActive',
    //   width: 60,
    // ellipsis: true,
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         {item.is_active != null ? (
    //           <Tooltip placement='bottom' title='Inventory Activation'>
    //             {
    //               <Switch
    //                 checked={item.is_active}
    //                 style={{
    //                   backgroundColor: item.is_active ? PRIMARY_COLOR : '#bababa',
    //                 }}
    //                 onChange={(checked: boolean) => {
    //                   //call onChange Method with item
    //                   this.onChangeStatus(item, checked)
    //                 }}
    //               />
    //             }
    //           </Tooltip>
    //         ) : (
    //           '-'
    //         )}
    //       </div>
    //     )
    //   },
    // },
    {
      title: 'Actions',
      width: 70,
      key: 'action',
      fixed: 'right',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='View'>
                <Button
                  shape='circle'
                  icon={<EyeOutlined />}
                  onClick={() => {
                    this.previewInventoryForm(item)
                  }}
                />
              </Tooltip>
            </div>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Trail'>
                <Button
                  shape='circle'
                  icon={<FieldTimeOutlined />}
                  onClick={() => {
                    this.showEditModal(item)
                  }}
                />
              </Tooltip>
            </div>
            {item && item.status == 'AVAILABLE' && (
              <>
                <div style={{float: 'left', marginRight: '5px', marginTop: '5px'}}>
                  <Tooltip placement='bottom' title='Edit'>
                    <Button
                      shape='circle'
                      icon={<EditOutlined />}
                      onClick={() => {
                        this.showEditStockModal(item)
                      }}
                    />
                  </Tooltip>
                </div>
                <div style={{float: 'left', marginRight: '5px', marginTop: '5px'}}>
                  <Tooltip placement='bottom' title='Delete'>
                    <Popconfirm
                      onConfirm={async () => {
                        //call delete staff api
                        await this.deleteSingleStockDataItem(item)
                      }}
                      title='Are you sure you want to delete stock?'
                    >
                      <Button shape='circle' icon={<DeleteOutlined />} />
                    </Popconfirm>
                  </Tooltip>
                </div>
              </>
            )}
            {item && item.status == 'MEMO' && (
              <>
                <div style={{float: 'left', marginRight: '5px', marginTop: '5px'}}>
                  <Tooltip placement='bottom' title='View Memo Details'>
                    <Button
                      shape='circle'
                      icon={<InfoOutlined />}
                      onClick={() => {
                        this.showViewStockMemoModal(item)
                      }}
                    />
                  </Tooltip>
                </div>
              </>
            )}
            {/* <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Delete'>
                <Popconfirm
                  onConfirm={() => {
                    //call delete inventory api
                    this.deleteInventory(item)
                  }}
                  title='Are you sure you want to delete inventory?'
                >
                  <Button shape='circle' icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </div> */}
          </div>
        )
      },
    },
  ]

  rowSelection = {
    // onChange: async (selectedRowKeys: any, selectedRows: any, selected: any) => {
    //   const {inventoryCartData} = this.props
    //   if (selectedRows && selectedRows.length >= 1) {
    //     this.tempBuyRequests = []
    //     selectedRows.map((selectedItem: any, index: number) => {
    //       let inventoryFilterIndex = -1
    //       if (selectedItem) {
    //         if (inventoryCartData && inventoryCartData.length >= 1) {
    //           inventoryFilterIndex = inventoryCartData.findIndex(
    //             (item: any) => item.stock_id === selectedItem.stock_id
    //           )
    //         }
    //         if (inventoryFilterIndex === -1) {
    //           this.tempBuyRequests.push({
    //             _id: selectedItem._id,
    //             stock_id: selectedItem.stock_id,
    //             price: selectedItem.final_price,
    //             price_ori: selectedItem.final_price_ori,
    //             stock_data: selectedItem,
    //           })
    //         }
    //       }
    //     })
    //     console.log('selected', selected)
    //     console.log('this.tempBuyRequests', this.tempBuyRequests)
    //     // await this.props.setInventoryCartData(this.tempBuyRequests)
    //   } else {
    //     this.tempBuyRequests = []
    //   }
    // },
    onChange: async (selectedRowKeys: any, selectedRows: any, selected: any) => {
      this.setState({dataTableSelectedRowKeys: selectedRowKeys})
    },
    onSelect: (record: any, selected: any, selectedRows: any, nativeEvent: any) => {
      if (selected) {
        this.addSelectedItemsToCartOnTableChange(selectedRows, selected)
      } else {
        this.addSelectedItemsToCartOnTableChange([record], selected)
      }
    },
    onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
      if (selected) {
        this.addSelectedItemsToCartOnTableChange(selectedRows, selected)
      } else {
        this.addSelectedItemsToCartOnTableChange(changeRows, selected)
      }
    },
    getCheckboxProps: (record: any) => ({
      //  disabled: record.status !== 'AVAILABLE', // Column configuration not to be checked
      status: record.status, // se the particular column disable and update the table
    }),
  }

  public addSelectedItemsToCartOnTableChange = async (rows: any, selected: any) => {
    const {inventoryCartData, setInventoryCartData} = this.props

    // if (rows && rows.length >= 1) {
    //   this.tempBuyRequests = []
    //   rows.map((selectedItem: any, index: number) => {
    //     let inventoryFilterIndex = -1
    //     if (selectedItem) {
    //       if (inventoryCartData && inventoryCartData.length >= 1) {
    //         inventoryFilterIndex = inventoryCartData.findIndex(
    //           (item: any) => item.stock_id === selectedItem.stock_id
    //         )
    //       }
    //       console.log("selected", selected)
    //       console.log("inventoryFilterIndex", inventoryFilterIndex)
    //       if (selected && inventoryFilterIndex === -1) {
    //         this.tempBuyRequests.push({
    //           _id: selectedItem._id,
    //           stock_id: selectedItem.stock_id,
    //           price: selectedItem.final_price,
    //           price_ori: selectedItem.final_price_ori,
    //           stock_data: selectedItem,
    //         })
    //       } else {
    //         if (inventoryFilterIndex !== -1) {
    //           inventoryCartData.splice(inventoryFilterIndex, 1)
    //           // await setInventoryCartData(inventoryCartData)
    //         }
    //       }
    //     }
    //   })
    //   console.log(this.tempBuyRequests)
    //   console.log("inventoryCartData",inventoryCartData)
    //   await setInventoryCartData([].concat(inventoryCartData || [], this.tempBuyRequests))
    //   Toaster({
    //     type: 'success',
    //     title: 'INVENTORY',
    //     description: 'Cart Updated',
    //   })
    // }

    if (rows && rows.length >= 1) {
      if (selected) {
        this.tempBuyRequests = []
        rows.map((selectedItem: any, index: number) => {
          let inventoryFilterIndex = -1
          if (selectedItem) {
            if (inventoryCartData && inventoryCartData.length >= 1) {
              inventoryFilterIndex = inventoryCartData.findIndex(
                (item: any) => item.stock_id === selectedItem.stock_id
              )
            }
            if (inventoryFilterIndex === -1) {
              this.tempBuyRequests.push({
                _id: selectedItem._id,
                stock_id: selectedItem.stock_id,
                stock_data: selectedItem,
              })
            }
          }
        })
        await setInventoryCartData([].concat(inventoryCartData || [], this.tempBuyRequests))
      } else {
        rows.map(async (selectedItem: any, index: number) => {
          let inventoryFilterIndex = -1
          if (selectedItem) {
            if (inventoryCartData && inventoryCartData.length >= 1) {
              inventoryFilterIndex = inventoryCartData.findIndex(
                (item: any) => item.stock_id === selectedItem.stock_id
              )
              if (inventoryFilterIndex !== -1) {
                inventoryCartData.splice(inventoryFilterIndex, 1)
                await setInventoryCartData(inventoryCartData)
              }
            }
          }
        })
      }
      // Toaster({
      //   type: 'success',
      //   title: 'INVENTORY',
      //   description: 'Cart Updated',
      // })
    }
  }

  public generateCsv = async () => {
    try {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          exportAllStockList('CERT',this.props.searchParamsData)
            .then((res: any) => {
              let csvArray = []

              csvArray.push([
                'STATUS',
                'OLD NO',
                'NEW NO',
                'CLIENT NAME',
                'RAPNET',
                'SHAPE',
                'WEIGHT',
                'COL',
                'CL',
                'RAP',
                'CUT',
                'PO',
                'SYM',
                'FL',
                'MEASUREMENT',
                'TABLE',
                'DEPTH',
                'LAB',
                'CERT NO',
                'CERT Link',
                'DISC',
                'NO BGM',
                'LOCATION',
                'P/Ct $',
                'VIDEO Link',
                'IMAGE Link',
              ])

              res.data.docs.map((data: any) => {

                let client_name = ''
                let tempClientNameArray = []
                if(data && data.memos && data.memos.length) {
                  for(let item of data.memos) {
                   let tempName = item.memo_id ? item.memo_id.client_id ? item.memo_id.client_id.first_name && item.memo_id.client_id.last_name
                    ? `${item.memo_id.client_id.first_name} ${item.memo_id.client_id.last_name}`
                    : `${item.memo_id.client_id.first_name}`
                    ? `${item.memo_id.client_id.first_name}`
                    : '-'
                  : '-'
                  : '-'
                  tempClientNameArray.push(tempName)
                  } 
                 client_name = tempClientNameArray && tempClientNameArray.join(',')

                }
          
                csvArray.push([
                  data && data.status ? data.status : '',
                  data && data.stock_id ? returnStockId(data.stock_id) : '',
                  data && data.old_stock_id ? returnStockId(data.old_stock_id) : '',
                  client_name ? `"${client_name}"` : '-',
                  data && data.rapnet ? data.rapnet : '',
                  data && data.shape ? returnStockId(data.shape) : '',
                  data && data.weight ? data.weight : '',
                  data && data.color ? returnStockId(data.color) : '',
                  data && data.clarity ? returnStockId(data.clarity) : '',
                  data && data.rap ? data.rap : '',
                  data && data.cut ? returnStockId(data.cut) : '',
                  data && data.polish ? returnStockId(data.polish) : '',
                  data && data.symmetry ? returnStockId(data.symmetry) : '',
                  // data && data.fluorescence_intensity
                  //   ? returnStockId(data.fluorescence_intensity)
                  //   : '',
                  data && data.fluorescence
                    ? returnStockId(data.fluorescence)
                    : '',
                  data && data.measurement ? data.measurement : '',
                  data && data.table ? data.table : '',
                  data && data.depth ? data.depth : '',
                  data && data.lab ? data.lab : '',
                  data && data.cert_number ? data.cert_number : '',
                  data && data.cert_link ? data.cert_link : '',
                  data && data.discount ? data.discount : '',
                  data && data.no_bgm ? data.no_bgm : '',
                  data && data.location ? data.location : '',
                  data && data.price_per_caret ? data.price_per_caret : '',
                  data && data.video_link ? data.video_link : '',
                  data && data.image_link ? data.image_link : '',
                ])
              })

              const rows = csvArray
              let csvName = 'Stock.csv'
              let csvContent = 'data:text/csv;charset=utf-8,'

              rows.forEach(function (rowArray) {
                let row = rowArray.join(',')
                csvContent += row + '\r\n'
              })

              var encodedUri = encodeURI(csvContent)
              // // // // window.open(encodedUri);
              var link = document.createElement('a')
              link.setAttribute('href', encodedUri)
              link.setAttribute('download', csvName)
              document.body.appendChild(link) // Required for FF

              link.click()

              this.setState({
                isLoading: false,
              })
            })
            .catch((err) => {
              console.log('ERROR', err)
              throw err
            })
        }
      )
    } catch (e) {
      console.log('error in generate csv', e)
    }
  }

  public OnDownloadLabel = async () => {
    try {
      const {inventoryCartData} = this.props

      if (inventoryCartData && inventoryCartData.length) {
        let response_link: any = {}
        response_link = await getDownloadLabelUrl(inventoryCartData)
        if (response_link && response_link.data) {
          window.open(response_link.data, '_blank')
        }
        Toaster({
          type: 'success',
          title: 'INVENTORY',
          description: 'Download Label Link Generated Successfully',
        })
        await this.props.setInventoryCartData([])
        this.setState({dataTableSelectedRowKeys: []})
      } else {
        Toaster({
          type: 'error',
          title: 'INVENTORY',
          description: 'Please Select Item',
        })
      }
    } catch (e) {
      console.log(e, '!!error!!!')
    }
  }

  public generateCsvOfSelectedItems = async () => {
    try {
      const {inventoryCartData} = this.props

      let stockIds: any = []

      if (inventoryCartData && inventoryCartData.length >= 1) {
        inventoryCartData.map((items: any, index: number) => {
          stockIds.push(items._id)
        })
      }

      this.setState(
        {
          isLoading: true,
        },
        () => {
          exportSelectedStockList(stockIds)
            .then((res: any) => {
              let csvArray = []

              csvArray.push([
                'STOCK ID',
                'CLIENT NAME',
                'STATUS',
                'IMAGE',
                'VIDEO',
                'SHAPE',
                'WEIGHT',
                'COLOR',
                'CLARITY',
                'CUT',
                'POLISH',
                'SYM',
                'FL',
                'LAB',
                'CERTIFICATE',
                'DISCOUNTS',
                '$/CT',
                'FINAL PRICE',
                'FINAL PRICE ORI',
                'AVAILABILITY',
                'BRAND',
                'CROWN ANGLE',
                'CROWN HEIGHT',
                'CULET CONDITION',
                'DEPTH',
                'TYPE',
                'GIRDLE CONDITION',
                'LW RATIO',
                'MEAS DEPTH',
                'MEASUREMENTS',
                'PAVILION ANGLE',
                'PAVILION DEPTH',
                'RAP PER CARET',
                'RAP PRICE',
                'TABLE',
                'TREATMENT',
              ])

              res.data.docs.map((data: any) => {
                
                
                let client_name = ''
                let tempClientNameArray = []
                if(data && data.memos && data.memos.length) {
                  for(let item of data.memos) {
                   let tempName = item.memo_id ? item.memo_id.client_id ? item.memo_id.client_id.first_name && item.memo_id.client_id.last_name
                    ? `${item.memo_id.client_id.first_name} ${item.memo_id.client_id.last_name}`
                    : `${item.memo_id.client_id.first_name}`
                    ? `${item.memo_id.client_id.first_name}`
                    : '-'
                  : '-'
                  : '-'
                  tempClientNameArray.push(tempName)
                  } 
                 client_name = tempClientNameArray && tempClientNameArray.join(',')

                }

                csvArray.push([
                  data && data.stock_id ? returnStockId(data.stock_id) : 'NA',
                  client_name ? `"${client_name}"` : '-',
                  data && data.status ? data.status : 'NA',
                  data && data.diamond_image ? data.diamond_image : 'NA',
                  data && data.diamond_video ? data.diamond_video : 'NA',
                  data && data.shape ? returnStockId(data.shape) : 'NA',
                  data && data.weight ? data.weight : 'NA',
                  data && data.color ? returnStockId(data.color) : 'NA',
                  data && data.clarity ? returnStockId(data.clarity) : 'NA',
                  data && data.cut ? returnStockId(data.cut) : 'NA',
                  data && data.polish ? returnStockId(data.polish) : 'NA',
                  data && data.symmetry ? returnStockId(data.symmetry) : 'NA',
                  data && data.fluorescence_intensity
                    ? returnStockId(data.fluorescence_intensity)
                    : 'NA',
                  data && data.lab_grown_type ? data.lab_grown_type : 'NA',
                  data && data.certificate_number ? data.certificate_number : 'NA',
                  data && data.discounts ? data.discounts : 'NA',
                  data && data.price_per_caret ? data.price_per_caret : 'NA',
                  data && data.final_price ? data.final_price : 'NA',
                  data && data.final_price_ori ? data.final_price_ori : 'NA',
                  data && data.availability ? returnStockId(data.availability) : 'NA',
                  data && data.brand ? returnStockId(data.brand) : 'NA',
                  data && data.crown_angle ? data.crown_angle : 'NA',
                  data && data.crown_height ? data.crown_height : 'NA',
                  data && data.culet_condition ? returnStockId(data.culet_condition) : 'NA',
                  data && data.depth ? data.depth : 'NA',
                  data && data.diamond_type ? returnStockId(data.diamond_type) : 'NA',
                  data && data.girdle_condition ? returnStockId(data.girdle_condition) : 'NA',
                  data && data.lw_ratio ? data.lw_ratio : 'NA',
                  data && data.meas_depth ? data.meas_depth : 'NA',
                  data && data.measurements ? data.measurements : 'NA',
                  data && data.pavilion_angle ? data.pavilion_angle : 'NA',
                  data && data.pavilion_depth ? data.pavilion_depth : 'NA',
                  data && data.rap_per_caret ? data.rap_per_caret : 'NA',
                  data && data.rap_price ? data.rap_price : 'NA',
                  data && data.table ? data.table : 'NA',
                  data && data.treatment ? returnStockId(data.treatment) : 'NA',
                ])
              })

              const rows = csvArray
              let csvName = 'Stock.csv'
              let csvContent = 'data:text/csv;charset=utf-8,'

              rows.forEach(function (rowArray) {
                let row = rowArray.join(',')
                csvContent += row + '\r\n'
              })

              var encodedUri = encodeURI(csvContent)
              // // // // window.open(encodedUri);
              var link = document.createElement('a')
              link.setAttribute('href', encodedUri)
              link.setAttribute('download', csvName)
              document.body.appendChild(link) // Required for FF

              link.click()

              this.setState({
                isLoading: false,
              })
            })
            .catch((err) => {
              console.log('ERROR', err)
              throw err
            })
        }
      )
    } catch (e) {
      console.log('error in generate csv', e)
    }
  }

  // public addSelectedItemsToCart = async () => {
  //   const {inventoryCartData, setInventoryLoading, setInventoryCartData} = this.props
  //   try {
  //     await setInventoryLoading(true)
  //     if (this.tempBuyRequests && this.tempBuyRequests.length >= 1) {
  //       await setInventoryCartData([].concat(inventoryCartData || [], this.tempBuyRequests))
  //       this.tempBuyRequests = []
  //       Toaster({
  //         type: 'success',
  //         title: 'INVENTORY',
  //         description: 'Items Added to cart',
  //       })
  //     } else {
  //       Toaster({
  //         type: 'error',
  //         title: 'INVENTORY',
  //         description: 'Please select item or item is already added to the cart',
  //       })
  //     }
  //   } catch (e) {
  //     console.log('!!!!Error', e)
  //   } finally {
  //     await setInventoryLoading(false)
  //   }
  // }

  public render() {
    const {inventoryData, inventoryDataTotal, inventoryLoading, loginUser, inventoryCartData} =
      this.props
    const {
      searchText,
      inventorySelectedData,
      inventorySelectedStockData,
      dataTableSelectedRowKeys,
      inventorySelectedMemoData,
      createNewStoneData,
    } = this.state

    return (
      <div>
        {console.log(this.props.searchParamsData, 'searchParamsData')}
        <div>
          <div className='card card-custom inventory-list'>
            {loginUser?.role === 'admin' && (
              <div className='card-header justify-content-end'>
                {/* <h3 className='card-title table-card-title' style={{width: '25%'}}>
                <InputSearchText
                  onChange={(value: string) => {
                    this.onSearchChange(value)
                  }}
                  placeholder='Search Inventory'
                  input={{
                    value: searchText,
                  }}
                />
              </h3> */}
                <div className='card-toolbar'>
                  <div className='ms-3'>
                    <InputButtonComponent
                      onClick={() => {
                        this.onCreateStone()
                      }}
                    >
                      Create CERT Stone
                    </InputButtonComponent>
                  </div>
                  <div className='ms-3'>
                    <InputButtonComponent
                      onClick={() => {
                        this.OnDownloadLabel()
                      }}
                    >
                      Download Label
                    </InputButtonComponent>
                  </div>
                  <div className='ms-3'>
                    <InputButtonComponent
                      onClick={() => {
                        this.onCreateInventory()
                      }}
                    >
                      Import Stock
                    </InputButtonComponent>
                  </div>
                  <div className='ms-3'>
                    <InputButtonComponent
                      onClick={() => {
                        this.generateCsv()
                      }}
                    >
                      Export Stock
                    </InputButtonComponent>
                  </div>

                  {/* {loginUser?.role === 'client' && (
                  <div>
                    <InputButtonComponent
                      onClick={() => {
                        this.addSelectedItemsToCart()
                      }}
                    >
                      Add to cart
                    </InputButtonComponent>
                  </div>
                )} */}
                </div>
              </div>
            )}
            {loginUser?.role === 'client' && (
              <div className='card-header justify-content-end'>
                <div className='card-toolbar'>
                  <div>
                    <InputButtonComponent
                      onClick={() => {
                        this.generateCsvOfSelectedItems()
                      }}
                    >
                      Export Stock
                    </InputButtonComponent>
                  </div>

                  {/* {loginUser?.role === 'client' && (
                  <div>
                    <InputButtonComponent
                      onClick={() => {
                        this.addSelectedItemsToCart()
                      }}
                    >
                      Add to cart
                    </InputButtonComponent>
                  </div>
                )} */}
                </div>
              </div>
            )}
            <div className='card-body'>
              <div>
                <ListTable
                  rowKey={(record: {_id: any}) => record._id}
                  onChange={this.handleTableChange}
                  onPaginationChange={(e: any) => {
                    this.setInitialDataTableValue()
                    if (e - 1) {
                      this.counter = this.tempCounter = (e - 1) * this.state.pagination.pageSize
                      return
                    }

                    this.counter = 0
                    this.tempCounter = 0
                  }}
                  column={this.columns}
                  paginationConfig={{
                    total: inventoryDataTotal,
                    showSizeChanger: true,
                    pageSize: this.state.pagination.pageSize,
                    current: this.state.pagination.current,
                  }}
                  isLoading={inventoryLoading}
                  dataSource={inventoryData}
                  counter={this.counter}
                  tempCounter={this.tempCounter}
                  isScroll={true}
                  scrollWidth={3000}
                  rowSelection={
                    loginUser?.role == 'admin'
                      ? {
                          type: 'checkbox',
                          ...this.rowSelection,
                          selectedRowKeys: dataTableSelectedRowKeys ? dataTableSelectedRowKeys : [],
                        }
                      : undefined
                  }
                />
              </div>
            </div>
            <CommanModal
              show={inventorySelectedData ? true : false}
              handleClose={() => {
                this.closeEditModal()
              }}
              title={'Inventory Trail'}
              submitText={'Save Changes'}
            >
              <div>
                {inventorySelectedData && (
                  <ViewTrailForm
                    data={inventorySelectedData}
                    onSubmit={(values: any, id: string) => {
                      this.onEditSubmit(values, id)
                    }}
                    handleCancel={() => {
                      this.closeEditModal()
                    }}
                  />
                )}
              </div>
            </CommanModal>
            <CommanModal
              show={inventorySelectedStockData ? true : false}
              handleClose={() => {
                this.closeEditStockModal()
              }}
              title={'Edit Stock'}
              submitText={'Save Changes'}
            >
              <div>
                {inventorySelectedStockData && (
                  <EditStockCertDataForm
                    data={inventorySelectedStockData}
                    onSubmit={(values: any, id: string) => {
                      this.onEditStockSubmit(values, id)
                    }}
                    handleCancel={() => {
                      this.closeEditStockModal()
                    }}
                  />
                )}
              </div>
            </CommanModal>
            <CommanModal
              show={inventorySelectedMemoData ? true : false}
              handleClose={() => {
                this.closeViewStockMemoModal()
              }}
              title={'View Stock'}
              submitText={'Save Changes'}
            >
              <div>
                {inventorySelectedMemoData && (
                  <ViewMemoData
                    data={inventorySelectedMemoData}
                    onSubmit={(values: any, id: string) => {}}
                    handleCancel={() => {
                      this.closeViewStockMemoModal()
                    }}
                  />
                )}
              </div>
            </CommanModal>
            <CommanModal
              show={createNewStoneData ? true : false}
              handleClose={() => {
                this.closeCreateStone()
              }}
              title={'Create Stone'}
              submitText={'Save Changes'}
            >
              <div>
                {createNewStoneData && (
                  <CreateCertStoneForm
                    data={createNewStoneData}
                    onSubmit={(values: any) => {
                      this.onCreateStockSubmit(values)
                    }}
                    handleCancel={() => {
                      this.closeCreateStone()
                    }}
                  />
                )}
              </div>
            </CommanModal>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  loginUser: state.auth.user,
  inventoryData: state.inventory.inventoryData,
  searchParamsData: state.inventory.searchParamsData,
  searchTextData: state.inventory.searchTextData,
  locationData: state.inventory.locationData,
  inventoryDataTotal: state.inventory.inventoryDataTotal,
  inventoryLoading: state.inventory.inventoryLoading,
  inventoryCartData: state.inventory.inventoryCartData,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListInventoryData: (inventoryData: any) => {
      dispatch(inventory.actions.setInventoryData(inventoryData))
    },
    setListInventoryDataTotal: (inventoryDataTotal: number) => {
      dispatch(inventory.actions.setInventoryDataTotal(inventoryDataTotal))
    },
    setInventoryLoading: (inventoryLoading: boolean) => {
      dispatch(inventory.actions.setInventoryLoading(inventoryLoading))
    },
    setInventoryCartData: (inventoryCartData: any) => {
      dispatch(inventory.actions.setInventoryCartData(inventoryCartData))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListInventoryData))
