/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as inventory from '../redux/InventoryRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {UserOutlined, EditOutlined, DeleteOutlined, EyeOutlined} from '@ant-design/icons'
import {
  changeStatusInventoryData,
  deleteInventoryData,
  editInventoryData,
  getInventoryData,
  getSearchInventoryData,
} from '../redux/InventoryCRUD'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/Toaster'
import {CommanModal} from '../../../modules/comman/components/Modal'
import {IInventoryFormValues} from './CreateInventoryForm'
import {
  returnPriceInThousandFormat,
  returnPriceInThousandFormatWithCurrencySign,
  returnStockId,
} from '../../../comman/helper'
import InfiniteScroll from 'react-infinite-scroll-component'
import ListTwinInventoryDataItem from './ListTwinInventoryDataItem'

const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor

interface IRecordsTableProps {
  loadMoreData: () => void
  data: any
  twinInventoryData: any
  twinInventoryDataTotal: number
  twinInventoryLoading: boolean
  inventoryCartData: any
  setTwinInventoryData: (twinInventoryData: any) => void
  setTwinInventoryDataTotal: (twinInventoryDataTotal: number) => void
  setInventoryLoading: (twinInventoryLoading: boolean) => void
  setInventoryCartData: (inventoryCartData: any) => void
  history?: History<LocationState>
  loginUser: any
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  inventorySelectedData: any
  dataTableSelectedRowKeys: any
  itemList: any
}

type PathParamsType = {
  param1: string
}
class ListTwinInventoryData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      inventorySelectedData: null,
      dataTableSelectedRowKeys: [],
      itemList: [],
    }
  }

  tempBuyRequests: any = []

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListInventoryData(pagination)
    )
  }

  public componentDidMount = () => {
    this.setInitialDataTableValue()
  }
  // itemList: any = []

  public renderScrollListValue = () => {
    const {data} = this.props
    if (data) {
      return data.map((model: any, index: number) => {
        const randomNumber = Math.floor(Math.random() * 6)
        return (
          <ListTwinInventoryDataItem
            data={model}
            key={`entity_list_item_${model.docs[0]._id}${index}`}
            randomNumber={randomNumber}
            addInventoryItemToCart={this.addInventoryItemToCart}
          />
        )
      })
    }
  }

  public setInitialDataTableValue = () => {
    const {inventoryCartData} = this.props
    if (inventoryCartData && inventoryCartData.length >= 1) {
      this.setState({
        dataTableSelectedRowKeys: inventoryCartData.map((item: any) => item._id),
      })
    }
  }

  public getListInventoryData = async (pagination: any) => {
    const {searchText} = this.state

    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listInventorySearchData(start, end)
        } else {
          this.listInventoryData(start, end)
        }
      }
    )
  }

  public listInventoryData = async (start: number, end: any) => {
    const {setInventoryLoading} = this.props
    try {
      const {setTwinInventoryData, setTwinInventoryDataTotal} = this.props
      await setInventoryLoading(true)
      let twinInventoryData = await getInventoryData(start, end, 'CERT')
      if (twinInventoryData.data) {
        await setTwinInventoryData(twinInventoryData.data.docs)
        await setTwinInventoryDataTotal(twinInventoryData.data.total)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setInventoryLoading(false)
    }
  }

  public listInventorySearchData = async (start: number, end: any) => {
    const {setInventoryLoading} = this.props
    try {
      const {setTwinInventoryData, setTwinInventoryDataTotal} = this.props
      const {searchText} = this.state

      await setInventoryLoading(true)
      let twinInventoryData = await getSearchInventoryData(start, end, searchText, 'CERT',searchText)
      if (twinInventoryData.data) {
        await setTwinInventoryData(twinInventoryData.data.docs)
        await setTwinInventoryDataTotal(twinInventoryData.data.total)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setInventoryLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listInventorySearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public onCreateInventory = () => {
    const {history} = this.props
    history.push('/inventory/import')
  }

  public deleteInventory = async (item: any) => {
    const {setInventoryLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setInventoryLoading(true)
      await deleteInventoryData(item._id)

      Toaster({
        type: 'success',
        title: 'INVENTORY',
        description: 'Deleted SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listInventorySearchData(startIndex, limitNumber)
        return
      }
      this.listInventoryData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setInventoryLoading(false)
    }
  }

  public onChangeStatus = async (item: any, checked: boolean) => {
    const {setInventoryLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setInventoryLoading(true)
      await changeStatusInventoryData(item._id, checked)

      Toaster({
        type: 'success',
        title: 'INVENTORY',
        description: 'Status changed successFully',
      })

      if (searchText != null && searchText !== '') {
        this.listInventorySearchData(startIndex, limitNumber)
        return
      }
      this.listInventoryData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setInventoryLoading(false)
    }
  }

  public onEditSubmit = async (values: IInventoryFormValues, inventoryId: string) => {
    const {setInventoryLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setInventoryLoading(true)
      await editInventoryData(values, inventoryId)

      Toaster({
        type: 'success',
        title: 'INVENTORY',
        description: 'Edited SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listInventorySearchData(startIndex, limitNumber)
        return
      }
      this.listInventoryData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setInventoryLoading(false)
    }
  }

  public showEditModal = async (item: any) => {
    this.setState({
      inventorySelectedData: item,
    })
  }

  public previewInventoryForm = async (item: any) => {
    const {history} = this.props
    history.push('/inventory/preview/' + item._id)
  }

  public closeEditModal = async () => {
    this.setState({
      inventorySelectedData: null,
    })
  }

  rowSelection = {
    // onChange: async (selectedRowKeys: any, selectedRows: any, selected: any) => {
    //   const {inventoryCartData} = this.props
    //   if (selectedRows && selectedRows.length >= 1) {
    //     this.tempBuyRequests = []
    //     selectedRows.map((selectedItem: any, index: number) => {
    //       let inventoryFilterIndex = -1
    //       if (selectedItem) {
    //         if (inventoryCartData && inventoryCartData.length >= 1) {
    //           inventoryFilterIndex = inventoryCartData.findIndex(
    //             (item: any) => item.stock_id === selectedItem.stock_id
    //           )
    //         }
    //         if (inventoryFilterIndex === -1) {
    //           this.tempBuyRequests.push({
    //             _id: selectedItem._id,
    //             stock_id: selectedItem.stock_id,
    //             price: selectedItem.final_price,
    //             price_ori: selectedItem.final_price_ori,
    //             stock_data: selectedItem,
    //           })
    //         }
    //       }
    //     })
    //     console.log('selected', selected)
    //     console.log('this.tempBuyRequests', this.tempBuyRequests)
    //     // await this.props.setInventoryCartData(this.tempBuyRequests)
    //   } else {
    //     this.tempBuyRequests = []
    //   }
    // },
    onChange: async (selectedRowKeys: any, selectedRows: any, selected: any) => {
      this.setState({dataTableSelectedRowKeys: selectedRowKeys})
    },
    onSelect: (record: any, selected: any, selectedRows: any, nativeEvent: any) => {
      if (selected) {
        this.addSelectedItemsToCartOnTableChange(selectedRows, selected)
      } else {
        this.addSelectedItemsToCartOnTableChange([record], selected)
      }
    },
    onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
      if (selected) {
        this.addSelectedItemsToCartOnTableChange(selectedRows, selected)
      } else {
        this.addSelectedItemsToCartOnTableChange(changeRows, selected)
      }
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.status !== 'AVAILABLE', // Column configuration not to be checked
      status: record.status, // se the particular column disable and update the table
    }),
  }

  public addSelectedItemsToCartOnTableChange = async (rows: any, selected: any) => {
    const {inventoryCartData, setInventoryCartData} = this.props

    if (rows && rows.length >= 1) {
      if (selected) {
        this.tempBuyRequests = []
        rows.map((selectedItem: any, index: number) => {
          let inventoryFilterIndex = -1
          if (selectedItem) {
            if (inventoryCartData && inventoryCartData.length >= 1) {
              inventoryFilterIndex = inventoryCartData.findIndex(
                (item: any) => item.stock_id === selectedItem.stock_id
              )
            }
            if (inventoryFilterIndex === -1) {
              this.tempBuyRequests.push({
                _id: selectedItem._id,
                stock_id: selectedItem.stock_id,
                stock_data: selectedItem.stock_data,
              })
            }
          }
        })
        await setInventoryCartData([].concat(inventoryCartData || [], this.tempBuyRequests))
      } else {
        rows.map(async (selectedItem: any, index: number) => {
          let inventoryFilterIndex = -1
          if (selectedItem) {
            if (inventoryCartData && inventoryCartData.length >= 1) {
              inventoryFilterIndex = inventoryCartData.findIndex(
                (item: any) => item.stock_id === selectedItem.stock_id
              )
              if (inventoryFilterIndex !== -1) {
                inventoryCartData.splice(inventoryFilterIndex, 1)
                await setInventoryCartData(inventoryCartData)
              }
            }
          }
        })
      }
      Toaster({
        type: 'success',
        title: 'INVENTORY',
        description: 'Cart Updated',
      })
    }
  }

  public addInventoryItemToCart = (event: any, invData: any) => {
    this.addSelectedItemsToCartOnTableChange(invData, event.target.checked)
  }

  public render() {
    const {loadMoreData, twinInventoryLoading, twinInventoryData, loginUser, data} = this.props

    return (
      <div>
        <div>
          {twinInventoryLoading || !twinInventoryData ? (
            <div className='d-flex justify-content-center'>
              <div className='spinner-border'></div>
            </div>
          ) : (
            <div className='card card-custom'>
              <div className='card-body'>
                <InfiniteScroll
                  style={{
                    overflow: 'scroll',
                    width: '100%',
                    minWidth: '100%',
                  }}
                  height={600}
                  dataLength={data.length} // This is important field to render the next data
                  next={loadMoreData}
                  hasMore={twinInventoryData.hasMore}
                  loader={
                    <div className='d-flex justify-content-center mt-2'>
                      <div className='spinner-border'></div>
                    </div>
                  }
                >
                  <div className='ant-table-wrapper'>
                    <div className='ant-spin-nested-loading'>
                      <div className='ant-spin-container'>
                        <div className='ant-table ant-table-small ant-table-ping-right ant-table-fixed-column ant-table-scroll-horizontal ant-table-has-fix-left ant-table-has-fix-right'>
                          <div className='ant-table-container'>
                            <div className='ant-table-content custom-table-wrapper'>
                              <table style={{width: '100%', minWidth: '100%'}}>
                                <thead className='ant-table-thead'>
                                  <tr>
                                    {loginUser?.role !== 'admin' && (
                                      <th className='ant-table-cell custom-table-sticky-col'>
                                        <div style={{width: '30px'}}></div>
                                      </th>
                                    )}
                                    <th className='ant-table-cell custom-table-sticky-col'>
                                      <div style={{width: '70px'}}>{'STOCK ID'}</div>
                                    </th>
                                    <th className='ant-table-cell custom-table-sticky-col'>
                                      {'STATUS'}
                                    </th>
                                    <th className='ant-table-cell'>
                                      <div style={{width: '80px'}}>{'MEDIA'}</div>
                                    </th>
                                    <th className='ant-table-cell'>{'SHAPE'}</th>
                                    <th className='ant-table-cell'>{'WEIGHT'}</th>
                                    <th className='ant-table-cell'>{'COLOR'}</th>
                                    <th className='ant-table-cell'>{'CLARITY'}</th>
                                    <th className='ant-table-cell'>{'CUT'}</th>
                                    <th className='ant-table-cell'>{'POLISH'}</th>
                                    <th className='ant-table-cell'>{'SYM'}</th>
                                    <th className='ant-table-cell'>{'FL'}</th>
                                    <th className='ant-table-cell'>{'LAB'}</th>
                                    <th className='ant-table-cell'>{'CERTIFICATE'}</th>
                                    <th className='ant-table-cell'>{'DISCOUNTS'}</th>
                                    <th className='ant-table-cell'>{'$/CT'}</th>
                                    <th className='ant-table-cell'>{'FINAL PRICE'}</th>
                                    <th className='ant-table-cell'>{'FINAL PRICE ORI'}</th>
                                    <th className='ant-table-cell'>{'AVAILABILITY'}</th>
                                    <th className='ant-table-cell'>{'BRAND'}</th>
                                    <th className='ant-table-cell'>{'CROWN ANGLE'}</th>
                                    <th className='ant-table-cell'>{'CROWN HEIGHT'}</th>
                                    <th className='ant-table-cell'>{'CULET CONDITION'}</th>
                                    <th className='ant-table-cell'>{'DEPTH'}</th>
                                    <th className='ant-table-cell'>{'TYPE'}</th>
                                    <th className='ant-table-cell'>{'GIRDLE CONDITION'}</th>
                                    <th className='ant-table-cell'>{'LW RATIO'}</th>
                                    <th className='ant-table-cell'>{'MEAS DEPTH'}</th>
                                    <th className='ant-table-cell'>{'MEASUREMENTS'}</th>
                                    <th className='ant-table-cell'>{'PAVILION ANGLE'}</th>
                                    <th className='ant-table-cell'>{'PAVILION DEPTH'}</th>
                                    <th className='ant-table-cell'>{'RAP PER CARET'}</th>
                                    <th className='ant-table-cell'>{'RAP PRICE'}</th>
                                    <th className='ant-table-cell'>{'TABLE'}</th>
                                    <th className='ant-table-cell'>{'TREATMENT'}</th>
                                    <th className='ant-table-cell'>{'Actions'}</th>
                                  </tr>
                                </thead>
                                <tbody className='ant-table-tbody'>
                                  {this.renderScrollListValue()}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </InfiniteScroll>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  loginUser: state.auth.user,
  twinInventoryData: state.inventory.twinInventoryData,
  twinInventoryDataTotal: state.inventory.twinInventoryDataTotal,
  twinInventoryLoading: state.inventory.twinInventoryLoading,
  inventoryCartData: state.inventory.inventoryCartData,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setTwinInventoryData: (twinInventoryData: any) => {
      dispatch(inventory.actions.setInventoryData(twinInventoryData))
    },
    setTwinInventoryDataTotal: (twinInventoryDataTotal: number) => {
      dispatch(inventory.actions.setInventoryDataTotal(twinInventoryDataTotal))
    },
    setInventoryLoading: (twinInventoryLoading: boolean) => {
      dispatch(inventory.actions.setInventoryLoading(twinInventoryLoading))
    },
    setInventoryCartData: (inventoryCartData: any) => {
      dispatch(inventory.actions.setInventoryCartData(inventoryCartData))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListTwinInventoryData))
