/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {createStaffData, getStaffData} from '../redux/StaffCRUD'
import * as staff from '../redux/StaffRedux'
import {CreateStaffForm} from './CreateStaffForm'
import {IStaffFormValues} from './CreateStaffForm'

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  // call initial data for Form
  const callInitialData = async () => {
    try {
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callInitialData()
  }, [])

  const callStaffData = async () => {
    try {
      let locationData = await getStaffData(0, 10)
      if (locationData.data) {
        await dispatch(staff.actions.setStaffLoading(true))
        // once staff data set then loading will automatically off using saga middleware
        await dispatch(staff.actions.setStaffData(locationData.data.docs))
        await dispatch(staff.actions.setStaffDataTotal(locationData.data.totalDocs))
        history.push('/staff/list')
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  const addSubmit = async (values: IStaffFormValues) => {
    try {
      await createStaffData(values)
      await callStaffData()
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  return (
    <div>
      <CreateStaffForm onSubmit={(values: IStaffFormValues) => addSubmit(values)} />
    </div>
  )
}

const CreateWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'create',
            path: '/staff/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'create',
            path: '/staff/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.STAFF'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {CreateWrapper}
