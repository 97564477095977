/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getSingleInventoryDataForStockDetail} from '../../../component/inventory/redux/InventoryCRUD'
import {useDispatch} from 'react-redux'
import * as inventory from '../../../component/inventory/redux/InventoryRedux'
import {RouteComponentProps} from 'react-router'
import ViewInventoryForm from '../../../component/inventory/containers/ViewInventoryForm'

interface MyParams {
  id: string
}

const StockDetail: React.FC<any> = (props) => {
  const dispatch = useDispatch()

  const {inventoryId} = props

  console.log(inventoryId)

  const callInventoryData = async () => {
    try {
      // console.log(props)
      let inventoryData = await getSingleInventoryDataForStockDetail(inventoryId)
      if (inventoryData.data) {
        // once inventory data set then loading will automatically off using saga middleware
        await dispatch(inventory.actions.setSingleInventoryData(inventoryData.data))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await dispatch(inventory.actions.setInventoryLoading(false))
    }
  }

  useEffect(() => {
    callInventoryData()
  }, [])

  return (
    <div style={{padding: '30px'}}>
      <ViewInventoryForm isPublic={true} inventoryId={inventoryId} />
    </div>
  )
}

const StockDetailWrapper: React.FC<RouteComponentProps<MyParams>> = (props) => {
  const intl = useIntl()

  const inventoryId = props.match.params.id

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'View',
            path: '/inventory/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'View',
            path: '/inventory/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.INVENTORY'})}
      </PageTitle>
      <StockDetail inventoryId={inventoryId} />
    </div>
  )
}

export {StockDetailWrapper}
