/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ClientPage} from '../../component/client/ClientPage'

const ClientWrapper: FC = () => {
  return (
    <div>
      <ClientPage />
    </div>
  )
}

export {ClientWrapper}
