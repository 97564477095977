/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getAllKpisData} from '../redux/DashboardCRUD'
import {useDispatch, useSelector} from 'react-redux'
import * as dashboard from '../redux/DashboardRedux'
import {KpiCardComponent} from '../../../modules/comman/components/KpiCardComponent'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {Button} from 'antd'
import VideoUploadModal from './VideoUploadModal'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
import ImageUploadModal from './ImageUploadModal'
import {Popconfirm} from 'antd'
import {deleteAllStockData, updateRapData} from '../../inventory/redux/InventoryCRUD'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {Spin} from 'antd'
import BulkUploadDiscountModal from './BulkUploadDiscountModal'

const DashboardPage: FC = () => {
  const [uploadModal, setUploadModal] = useState(false)
  const [uploadImageModal, setUploadImageModal] = useState(false)
  const [bulkUploadModal, setBulkUploadModal] = useState(false)
  const dispatch = useDispatch()
  const dashboardDataStore = useSelector((state: any) => state.dashboard.dashboardData)
  const dashboardLoadingStore = useSelector((state: any) => state.dashboard.dashboardLoading)

  const callInitDashboardData = async () => {
    try {
      let dashboardData = await getAllKpisData()
      if (dashboardData.data) {
        await dispatch(dashboard.actions.setDashboardLoading(true))
        // once dashboard data set then loading will automatically off using saga middleware
        await dispatch(dashboard.actions.setDashboardData(dashboardData.data))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  const updateRapDataFunction = async () => {
    try {
      await dispatch(dashboard.actions.setDashboardLoading(true))
      await updateRapData()
      Toaster({
        type: 'success',
        title: 'Update Rap',
        description: 'Updated Successfully',
      })
    } catch (e) {
      Toaster({
        type: 'error',
        title: 'Update Rap',
        description: 'Failed To Update Rap',
      })
    } finally {
      await dispatch(dashboard.actions.setDashboardLoading(false))
    }
  }

  const deleteAllStocksDataFunction = async () => {
    try {
      await deleteAllStockData()
      Toaster({
        type: 'success',
        title: 'Stock',
        description: 'Deleted Successfully',
      })
    } catch (e) {
      Toaster({
        type: 'error',
        title: 'Stock',
        description: 'Failed To Delete Stock',
      })
    }
  }

  useEffect(() => {
    callInitDashboardData()
  }, [])

  return (
    <div>
      <div style={{width: '100%', display: 'inline-block', marginBottom: '10px'}}>
        <Popconfirm
          onConfirm={() => {
            updateRapDataFunction()
          }}
          title='Are you sure you want to update rap?'
        >
          <Button
            type='primary'
            style={{
              float: 'right',
              // margin: '20px',
              // marginLeft: '80%',
              borderRadius: '10px',
              width: '150px',
              height: '35px',
            }}
          >
            Update Rap Price
          </Button>
        </Popconfirm>
        <Popconfirm
          onConfirm={() => {
            deleteAllStocksDataFunction()
          }}
          title='Are you sure you want to delete all stocks?'
        >
          <Button
            type='primary'
            style={{
              float: 'right',
              // margin: '20px',
              // marginLeft: '80%',
              borderRadius: '10px',
              width: '150px',
              height: '35px',
              marginRight: '15px',
            }}
          >
            Delete All Stocks
          </Button>
        </Popconfirm>
        <Button
          onClick={() => {
            setUploadModal(true)
          }}
          type='primary'
          style={{
            float: 'right',
            // margin: '20px',
            // marginLeft: '80%',
            borderRadius: '10px',
            width: '150px',
            height: '35px',
            marginRight: '15px',
          }}
        >
          Upload New Video
        </Button>
        <Button
          onClick={() => {
            setUploadImageModal(true)
          }}
          type='primary'
          style={{
            float: 'right',
            // margin: '20px',
            // marginLeft: '80%',
            borderRadius: '10px',
            width: '150px',
            height: '35px',
            marginRight: '15px',
          }}
        >
          Upload New Image
        </Button>
        <Button
          onClick={() => {
            setBulkUploadModal(true)
          }}
          type='primary'
          style={{
            float: 'right',
            // margin: '20px',
            // marginLeft: '80%',
            borderRadius: '10px',
            width: '170px',
            height: '35px',
            marginRight: '15px',
          }}
        >
          Bulk Discount Stock
        </Button>
      </div>
      {/* {uploadModal && <VideoUploadModal />} */}
      <CommanModal
        show={uploadModal ? true : false}
        handleClose={() => {
          setUploadModal(false)
        }}
        title={'Upload New Video'}
        submitText={'Save Changes'}
      >
        <div>
          {uploadModal && (
            <VideoUploadModal
              handleCancel={() => {
                setUploadModal(false)
              }}
            />
          )}
        </div>
      </CommanModal>

      <CommanModal
        show={bulkUploadModal ? true : false}
        handleClose={() => {
          setBulkUploadModal(false)
        }}
        title={'Upload Bulk Discount File'}
        submitText={'Save Changes'}
      >
        <div>
          {bulkUploadModal && (
            <BulkUploadDiscountModal
              handleCancel={() => {
                setBulkUploadModal(false)
              }}
            />
          )}
        </div>
      </CommanModal>
      <CommanModal
        show={uploadImageModal ? true : false}
        handleClose={() => {
          setUploadImageModal(false)
        }}
        title={'Upload New Image'}
        submitText={'Save Changes'}
      >
        <div>
          {uploadImageModal && (
            <ImageUploadModal
              handleCancel={() => {
                setUploadImageModal(false)
              }}
            />
          )}
        </div>
      </CommanModal>
      {dashboardLoadingStore ? (
        <div>
          <Spin style={{marginLeft: '46%'}} />
        </div>
      ) : (
        <div className='row g-5 g-xl-8'>
          <KpiCardComponent
            icon='/media/icons/duotune/art/art002.svg'
            title='Total Clients'
            value={dashboardDataStore && String(dashboardDataStore.clientCount)}
            path='/client/list'
            background='white'
          />
          <KpiCardComponent
            icon='/media/icons/duotune/art/art002.svg'
            title='Total Locations'
            value={dashboardDataStore && String(dashboardDataStore.locationCount)}
            path='/location/list'
            background='black'
          />
          <KpiCardComponent
            icon='/media/icons/duotune/art/art002.svg'
            title='Total Managers'
            value={dashboardDataStore && String(dashboardDataStore.managerCount)}
            path='/manager/list'
            background='yellow'
          />
          <KpiCardComponent
            icon='/media/icons/duotune/art/art002.svg'
            title='Total Staffs '
            value={dashboardDataStore && String(dashboardDataStore.staffCount)}
            path='/staff/list'
            background='blue'
          />
          <KpiCardComponent
            icon='/media/icons/duotune/art/art002.svg'
            title='Total Available Stock'
            value={dashboardDataStore && String(dashboardDataStore.availableStockCount)}
            path='/inventory/list'
            background='white'
            cardType = 'available'
          />
          <KpiCardComponent
            icon='/media/icons/duotune/art/art002.svg'
            title='Total Hold Stock'
            value={dashboardDataStore && String(dashboardDataStore.holdStockCount)}
            path='/inventory/list'
            background='yellow'
            cardType = 'hold'
          />
          <KpiCardComponent
            icon='/media/icons/duotune/art/art002.svg'
            title='Total Sold Stock'
            value={dashboardDataStore && String(dashboardDataStore.soldStockCount)}
            path='/inventory/list'
            background='blue'
            cardType = 'sold'
          />
          <KpiCardComponent
            icon='/media/icons/duotune/art/art002.svg'
            title='Total Memo Stock'
            value={dashboardDataStore && String(dashboardDataStore.memoStockCount)}
            path='/inventory/list'
            background='yellow'
            cardType = 'memo'
          />
        </div>
      )}
    </div>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'dashboard',
            path: '/dashboard',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'dashboard',
            path: '/dashboard',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.DASHBOARD'})}
      </PageTitle>
      <DashboardPage />
    </div>
  )
}

export {DashboardWrapper}
