import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {LocationModal} from '../models/LocationModal'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setLocationData: 'SET_LOCATION_DATA',
  setLocationDataTotal: 'SET_LOCATION_DATA_TOTAL',
  setLocationLoading: 'SET_LOCATION_LOADER',
}

const initialClientState: IClientState = {
  locationData: undefined,
  locationDataTotal: undefined,
  locationLoading: false,
}

export interface IClientState {
  locationData?: LocationModal
  locationDataTotal?: number
  locationLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'location', whitelist: ['locationData', 'locationDataTotal']},
  (state: IClientState = initialClientState, action: ActionWithPayload<IClientState>) => {
    switch (action.type) {
      case actionTypes.setLocationData: {
        const locationData = action.payload?.locationData
        return {...state, locationData}
      }

      case actionTypes.setLocationDataTotal: {
        const locationDataTotal = action.payload?.locationDataTotal
        return {...state, locationDataTotal}
      }

      case actionTypes.setLocationLoading: {
        const locationLoading = action.payload?.locationLoading
        return {...state, locationLoading}
      }

      default:
        return state
    }
  }
)

export const actions = {
  setLocationData: (locationData: Array<LocationModal>) => ({
    type: actionTypes.setLocationData,
    payload: {locationData: locationData},
  }),
  setLocationDataTotal: (locationDataTotal: number) => ({
    type: actionTypes.setLocationDataTotal,
    payload: {locationDataTotal},
  }),
  setLocationLoading: (locationLoading: boolean) => ({
    type: actionTypes.setLocationLoading,
    payload: {locationLoading},
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.setLocationData, function* loginSaga() {
    yield put(actions.setLocationLoading(false))
  })

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser())
  // })

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const {data: user} = yield getUserByToken()
  //   yield put(actions.fulfillUser(user))
  // })
}
