/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as packages from '../redux/PackageRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {EyeOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
import {
  getPackageData,
  editPackageData,
  getSearchPackageData,
  deletePackageData,
} from '../redux/PackageCRUD'
import {ViewPackageForm} from './ViewPackageForm'
// import {EditPackageForm} from './EditPackageForm'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  packageData: any
  packageDataTotal: number
  packageLoading: boolean
  setListPackageData: (packageData: any) => void
  setListPackageDataTotal: (packageDataTotal: number) => void
  setPackageLoading: (packageLoading: boolean) => void
  history?: History<LocationState>
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  packageSelectedData: any
}

type PathParamsType = {
  param1: string
}
class ListPackageData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      packageSelectedData: null,
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListPackageData(pagination)
    )
  }

  public getListPackageData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listPackageSearchData(start, end)
        } else {
          this.listPackageData(start, end)
        }
      }
    )
  }

  public listPackageData = async (start: number, end: any) => {
    const {setPackageLoading} = this.props
    try {
      const {setListPackageData, setListPackageDataTotal} = this.props
      await setPackageLoading(true)
      let packageData = await getPackageData(start, end)
      if (packageData.data) {
        await setListPackageData(packageData.data.docs)
        await setListPackageDataTotal(packageData.data.totalDocs)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setPackageLoading(false)
    }
  }

  public listPackageSearchData = async (start: number, end: any) => {
    const {setPackageLoading} = this.props
    try {
      const {setListPackageData, setListPackageDataTotal} = this.props
      const {searchText} = this.state

      await setPackageLoading(true)
      let packageData = await getSearchPackageData(start, end, searchText)
      if (packageData.data) {
        await setListPackageData(packageData.data.docs)
        await setListPackageDataTotal(packageData.data.totalDocs)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setPackageLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listPackageSearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public onCreateLocation = () => {
    const {history} = this.props
    history.push('/packages/create')
  }

  public deletePackage = async (item: any) => {
    const {setPackageLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setPackageLoading(true)
      await deletePackageData(item._id)

      Toaster({
        type: 'success',
        title: 'PACKAGE',
        description: 'Deleted SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listPackageSearchData(startIndex, limitNumber)
        return
      }
      this.listPackageData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setPackageLoading(false)
    }
  }

  public onChangeStatus = async (item: any, checked: boolean) => {
    const {setPackageLoading} = this.props
    // try {
    //   const {startIndex, limitNumber, searchText} = this.state

    //   await setPackageLoading(true)
    //   await changeStatusLocationData(item.id, checked)

    //   Toaster({
    //     type: 'success',
    //     title: 'PACKAGE',
    //     description: 'Status changed successFully',
    //   })

    //   if (searchText != null && searchText !== '') {
    //     this.listPackageSearchData(startIndex, limitNumber)
    //     return
    //   }
    //   this.listPackageData(startIndex, limitNumber)
    // } catch (e) {
    //   console.log('!!!!Error', e)
    // } finally {
    //   await setPackageLoading(false)
    // }
  }

  public onEditSubmit = async (values: any, locationId: string) => {
    const {setPackageLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setPackageLoading(true)
      await editPackageData(values, locationId)

      Toaster({
        type: 'success',
        title: 'PACKAGE',
        description: 'Edited SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listPackageSearchData(startIndex, limitNumber)
        return
      }
      this.listPackageData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setPackageLoading(false)
    }
  }

  public showEditModal = async (item: any) => {
    this.setState({
      packageSelectedData: item,
    })
  }

  public closeEditModal = async () => {
    this.setState({
      packageSelectedData: null,
    })
  }

  counter = 0
  tempCounter = 0
  public columns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Name',
      key: 'name',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.name ? item.name : '-'}</strong>
          </div>
        )
      },
    },
    {
      title: 'Total Stones',
      key: 'total_stones',
      render: (text: any, item: any, index: number) => {
        return <div>{item.total_stones ? item.total_stones : '-'}</div>
      },
    },
    {
      title: 'Total Weight',
      key: 'total_weight',
      render: (text: any, item: any, index: number) => {
        return <div>{item.total_weight ? item.total_weight : '-'}</div>
      },
    },
    {
      title: 'Total Certified Stones',
      key: 'total_certified_stones',
      render: (text: any, item: any, index: number) => {
        return <div>{item.total_certified_stones ? item.total_certified_stones : '-'}</div>
      },
    },
    {
      title: 'Total Certified Weight',
      key: 'total_certified_weight',
      render: (text: any, item: any, index: number) => {
        return <div>{item.total_certified_weight ? item.total_certified_weight : '-'}</div>
      },
    },
    {
      title: 'Total Non Certified Stones',
      key: 'total_non_certified_stones',
      render: (text: any, item: any, index: number) => {
        return <div>{item.total_non_certified_stones ? item.total_non_certified_stones : '-'}</div>
      },
    },
    {
      title: 'Total Non Certified Weight',
      key: 'total_non_certified_weight',
      render: (text: any, item: any, index: number) => {
        return <div>{item.total_non_certified_weight ? item.total_non_certified_weight : '-'}</div>
      },
    },
    {
      title: 'Total Star Melee Stones',
      key: 'total_star_melee_stones',
      render: (text: any, item: any, index: number) => {
        return <div>{item.total_star_melee_stones ? item.total_star_melee_stones : '-'}</div>
      },
    },
    {
      title: 'Total Star Melee Weight',
      key: 'total_star_melee_weight',
      render: (text: any, item: any, index: number) => {
        return <div>{item.total_star_melee_weight ? item.total_star_melee_weight : '-'}</div>
      },
    },
    // {
    //   title: 'isActive',
    //   key: 'isActive',
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         {item.is_active != null ? (
    //           <Tooltip placement='bottom' title='Category Activation'>
    //             {
    //               <Switch
    //                 checked={item.is_active}
    //                 style={{
    //                   backgroundColor: item.is_active ? PRIMARY_COLOR : '#bababa',
    //                 }}
    //                 onChange={(checked: boolean) => {
    //                   //call onChange Method with item
    //                   this.onChangeStatus(item, checked)
    //                 }}
    //               />
    //             }
    //           </Tooltip>
    //         ) : (
    //           '-'
    //         )}
    //       </div>
    //     )
    //   },
    // },
    {
      title: 'Actions',
      width: 130,
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Edit'>
                <Button
                  shape='circle'
                  icon={<EyeOutlined />}
                  onClick={() => {
                    this.showEditModal(item)
                  }}
                />
              </Tooltip>
            </div>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Delete'>
                <Popconfirm
                  onConfirm={() => {
                    //call delete packages api
                    this.deletePackage(item)
                  }}
                  title='Are you sure you want to delete package?'
                >
                  <Button shape='circle' icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        )
      },
    },
  ]

  public render() {
    const {packageData, packageDataTotal, packageLoading} = this.props
    const {searchText, packageSelectedData} = this.state

    return (
      <div>
        <div className='container'>
          <div className='card card-custom'>
            <div className='card-header'>
              <h3 className='card-title table-card-title' style={{width: '25%'}}>
                <InputSearchText
                  onChange={(value: string) => {
                    this.onSearchChange(value)
                  }}
                  placeholder='Search Package'
                  input={{
                    value: searchText,
                  }}
                />
              </h3>
              <div className='card-toolbar'>
                {/* <InputButtonComponent
                  onClick={() => {
                    this.onCreateLocation()
                  }}
                >
                  Add Package
                </InputButtonComponent> */}
              </div>
            </div>
            <div className='card-body'>
              <div style={{overflowX: 'auto'}}>
                <ListTable
                  rowKey={(record: {id: any}) => record.id}
                  onChange={this.handleTableChange}
                  column={this.columns}
                  paginationConfig={{
                    total: packageDataTotal,
                    showSizeChanger: true,
                    pageSize: this.state.pagination.pageSize,
                    current: this.state.pagination.current,
                  }}
                  isLoading={packageLoading}
                  dataSource={packageData}
                  counter={this.counter}
                  tempCounter={this.tempCounter}
                />
                <div>
                  <CommanModal
                    show={packageSelectedData ? true : false}
                    handleClose={() => {
                      this.closeEditModal()
                    }}
                    title={'View Package'}
                    submitText={'Save Changes'}
                  >
                    <div>
                      {packageSelectedData && (
                        <ViewPackageForm
                          data={packageSelectedData}
                          onSubmit={(values: any, locationId: string) => {
                            this.onEditSubmit(values, locationId)
                          }}
                          handleCancel={() => {
                            this.closeEditModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  packageData: state.packages.packageData,
  packageDataTotal: state.packages.packageDataTotal,
  packageLoading: state.packages.packageLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListPackageData: (packageData: any) => {
      dispatch(packages.actions.setPackageData(packageData))
    },
    setListPackageDataTotal: (packageDataTotal: number) => {
      dispatch(packages.actions.setPackageDataTotal(packageDataTotal))
    },
    setPackageLoading: (packageLoading: boolean) => {
      dispatch(packages.actions.setPackageLoading(packageLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListPackageData))
