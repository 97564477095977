/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {createCourierData, getCourierData} from '../redux/CourierCRUD'
import * as courier from '../redux/CourierRedux'
import {CreateCourierForm} from './CreateCourierForm'
import {ICourierFormValues} from './CreateCourierForm'

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  // call initial data for Form
  const callInitialData = async () => {
    try {
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callInitialData()
  }, [])

  const callPackageData = async () => {
    try {
      let locationData = await getCourierData(0, 10)
      if (locationData.data) {
        await dispatch(courier.actions.setCourierLoading(true))
        // once courier data set then loading will automatically off using saga middleware
        await dispatch(courier.actions.setCourierData(locationData.data.docs))
        await dispatch(courier.actions.setCourierDataTotal(locationData.data.totalDocs))
        history.push('/courier/list')
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  const addSubmit = async (values: ICourierFormValues) => {
    try {
      await createCourierData(values)
      await callPackageData()
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  return (
    <div>
      <CreateCourierForm onSubmit={(values: ICourierFormValues) => addSubmit(values)} />
    </div>
  )
}

const CreateWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'create',
            path: '/courier/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'create',
            path: '/courier/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.COURIER'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {CreateWrapper}
