/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {importInventoryData, getInventoryData} from '../redux/InventoryCRUD'
import * as inventory from '../redux/InventoryRedux'
import {IImportInventoryFormValues, ImportInventoryForm} from './ImportInventoryForm'
import {Tabs} from 'antd'
import {ImportInventoryNonCertForm} from './ImportInventoryNonCertForm'
import {ImportInventoryStarMeleeForm} from './ImportInventoryStarMeleeForm'

const {TabPane} = Tabs

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [TabKey, setTabKey] = useState('1')

  const callback = (key: any) => {
    setTabKey(key)
  }

  const callInventoryData = async () => {
    try {
      let inventoryData = await getInventoryData(0, 10, 'CERT')
      if (inventoryData.data) {
        await dispatch(inventory.actions.setInventoryLoading(true))
        // once inventory data set then loading will automatically off using saga middleware
        await dispatch(inventory.actions.setInventoryData(inventoryData.data.docs))
        await dispatch(inventory.actions.setInventoryDataTotal(inventoryData.data.totalDocs))
        history.push('/inventory/list')
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  const addSubmit = async (values: IImportInventoryFormValues) => {
    try {
      let type = TabKey == '1' ? 'CERT' : TabKey == '2' ? 'NON_CERT' : 'STAR_MELEE'
      if (type) {
        await importInventoryData(values, type)
        await callInventoryData()
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  return (
    <div>
      {/* <ImportInventoryForm onSubmit={(values: IImportInventoryFormValues) => addSubmit(values)} /> */}
      <Tabs style={{fontWeight: '500'}} activeKey={TabKey} onChange={callback}>
        <TabPane tab='CERT' key='1'>
          {TabKey === '1' && (
            <ImportInventoryForm
              onSubmit={(values: IImportInventoryFormValues) => addSubmit(values)}
            />
          )}
        </TabPane>
        <TabPane tab='NON CERT' key='2'>
          {TabKey === '2' && (
            <ImportInventoryNonCertForm
              onSubmit={(values: IImportInventoryFormValues) => addSubmit(values)}
            />
          )}
        </TabPane>
        <TabPane tab='STAR MELEE' key='3'>
          {TabKey === '3' && (
            <ImportInventoryStarMeleeForm
              onSubmit={(values: IImportInventoryFormValues) => addSubmit(values)}
            />
          )}
        </TabPane>
      </Tabs>
    </div>
  )
}

const ImportWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'import',
            path: '/inventory/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'import',
            path: '/inventory/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.INVENTORY'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ImportWrapper}
