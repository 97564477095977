import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputToggle} from '../../../modules/comman/formComponents/InputToggle'
import {InputNumberText} from '../../../modules/comman/formComponents/InputNumberText'
import {InputSingleFileUpload} from '../../../modules/comman/formComponents/InputSingleFileUpload'

interface IRenderFormikForm {
  values: ICourierFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface ICourierFormValues {
  name: string
  surat_phone: number
  delhi_phone: number
  mumbai_phone: number
  email: string
  address: string
  file_name: string
}

interface IAddClientProps {
  onSubmit: any
  data?: any
  handleCancel?: any
}

const appAPIURL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'

export class CreateCourierForm extends React.Component<IAddClientProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      viewFileUploadModal: false,
      uploadMedia: [],
      isImageLinked: false,
      image_url: '',
      file_name: '',
    }
  }

  viewFileUploadModalRef: {show: () => void} | undefined

  public validateForm = (values: ICourierFormValues) => {
    const errors: any = {}
    console.log('Validating form -- ', values)

    if (!values.name) {
      errors.name = 'Name is required'
    }

    if (!values.email) {
      errors.email = 'Email is required'
    }

    if (!values.surat_phone) {
      errors.surat_phone = 'Surat Phone is required'
    }
    if (!values.delhi_phone) {
      errors.delhi_phone = 'Delhi Phone is required'
    }
    if (!values.mumbai_phone) {
      errors.mumbai_phone = 'Mumbai Phone is required'
    }
    if (!values.address) {
      errors.address = 'Address is required'
    }

    console.log('Validating errors -- ', errors)
    return errors
  }

  public handleSubmit = (values: ICourierFormValues, action: any) => {
    if (this.state.file_name) {
      values.file_name = this.state.file_name
    }

    console.log('Basic Value - ', values)
    this.props.onSubmit(values)

    this.setState({
      visible: false,
    })
  }

  public uploadMedia = (fileName: string, FileData: Array<any>, customFileData: Object) => {
    if (fileName && FileData.length >= 1) {
      this.setState({
        uploadMedia: FileData,
      })
      console.log('!!!!!!!!!!!uploadMedia')
    }
    //close modal popup
    this.setState({
      viewFileUploadModal: false,
      isImageLinked: false,
      image_url: `${appAPIURL}courier/image/${fileName}`,
      file_name: fileName,
    })
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public render() {
    return (
      <div className='container'>
        <div className='card card-custom'>
          <div className='card-header'>
            <h3 className='card-title'>Create Courier Form</h3>
          </div>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  name: '',
                  surat_phone: NaN,
                  delhi_phone: NaN,
                  mumbai_phone: NaN,
                  email: '',
                  address: '',
                  file_name: '',
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.name,
                              id: 'name',
                              name: 'name',
                            }}
                            placeholder='Enter Name'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('name', value)
                            }}
                            onBlur={handleBlur}
                            label='Name'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.email,
                              id: 'email',
                              name: 'email',
                            }}
                            placeholder='Enter email'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('email', value)
                            }}
                            onBlur={handleBlur}
                            label='Email'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>

                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.surat_phone,
                              id: 'surat_phone',
                              name: 'surat_phone',
                            }}
                            placeholder='Enter Surat Phone EX: (+91-6353689198)'
                            onChange={(value: number) => {
                              handleChange(value)
                              setFieldValue('surat_phone', value)
                            }}
                            onBlur={handleBlur}
                            label='Surat Phone'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.delhi_phone,
                              id: 'delhi_phone',
                              name: 'delhi_phone',
                            }}
                            placeholder='Enter Delhi Phone EX: (+91-6353689198)'
                            onChange={(value: number) => {
                              handleChange(value)
                              setFieldValue('delhi_phone', value)
                            }}
                            onBlur={handleBlur}
                            label='Delhi Phone'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.mumbai_phone,
                              id: 'mumbai_phone',
                              name: 'mumbai_phone',
                            }}
                            placeholder='Enter Mumbai Phone EX: (+91-6353689198)'
                            onChange={(value: number) => {
                              handleChange(value)
                              setFieldValue('mumbai_phone', value)
                            }}
                            onBlur={handleBlur}
                            label='Mumbai Phone'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.address,
                              id: 'address',
                              name: 'address',
                            }}
                            placeholder='Enter address'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('address', value)
                            }}
                            onBlur={handleBlur}
                            label='Address'
                            error={errors}
                            touched={touched}
                            required={false}
                          />
                        </div>
                      </div>
                      <div>
                        <InputSingleFileUpload
                          ref={(e: any) => {
                            this.viewFileUploadModalRef = e
                          }}
                          //   buttonLabel={'Choose Provider Image'}
                          onBlur={() => {}}
                          error={() => {}}
                          touched={() => {}}
                          //   required={false}
                          //   label='Item Image'
                          name='image_url'
                          input={{
                            id: 'image',
                            name: 'image',
                            action: `${appAPIURL}/file/upload/courier/image`,
                            headers: {
                              authorization: 'authorized-text',
                            },
                            accept: 'png|jpeg|jpg|gif|webp',
                            acceptMimeTypes: ['image/png', 'image/jpeg', 'image/jpg'],
                            mediaType: 'png|jpeg|jpg|gif|webp',
                            mediaModuleType: 'png|jpeg|jpg|gif|webp',
                          }}
                          onUpload={(
                            fileName: string,
                            FileData: Array<any>,
                            customFileData: Object
                          ) => {
                            this.uploadMedia(fileName, FileData, customFileData)
                          }}
                        />
                      </div>
                      {console.log(this.state.image_url)}
                      {this.state.image_url ? (
                        <img
                          src={`${this.state.image_url}`}
                          alt='Banner'
                          style={{width: '350px', height: '175px', margin: '10px auto'}}
                        />
                      ) : null}
                      <div style={{marginBottom: '15px'}}></div>
                      <InputButtonComponent onClick={handleSubmit}>Create</InputButtonComponent>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
          {/* <div className='card-footer'></div> */}
        </div>
      </div>
    )
  }
}
