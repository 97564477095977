/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {createPackageData, getPackageData} from '../redux/PackageCRUD'
import * as packages from '../redux/PackageRedux'
import {CreatePackageForm} from './CreatePackageForm'
import {IPackageFormValues} from './CreatePackageForm'

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  // call initial data for Form
  const callInitialData = async () => {
    try {
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callInitialData()
  }, [])

  const callPackageData = async () => {
    try {
      let locationData = await getPackageData(0, 10)
      if (locationData.data) {
        await dispatch(packages.actions.setPackageLoading(true))
        // once packages data set then loading will automatically off using saga middleware
        await dispatch(packages.actions.setPackageData(locationData.data.docs))
        await dispatch(packages.actions.setPackageDataTotal(locationData.data.totalDocs))
        history.push('/packages/list')
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  const addSubmit = async (values: IPackageFormValues) => {
    try {
      await createPackageData(values)
      await callPackageData()
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  return (
    <div>
      <CreatePackageForm onSubmit={(values: IPackageFormValues) => addSubmit(values)} />
    </div>
  )
}

const CreateWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'create',
            path: '/packages/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'create',
            path: '/packages/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.PACKAGE'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {CreateWrapper}
