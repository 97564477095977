/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {createManagerData, getManagerData} from '../redux/ManagerCRUD'
import * as manager from '../redux/ManagerRedux'
import {CreateManagerForm} from './CreateManagerForm'
import {IManagerFormValues} from './CreateManagerForm'

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  // call initial data for Form
  const callInitialData = async () => {
    try {
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callInitialData()
  }, [])

  const callManagerData = async () => {
    try {
      let locationData = await getManagerData(0, 10)
      if (locationData.data) {
        await dispatch(manager.actions.setManagerLoading(true))
        // once manager data set then loading will automatically off using saga middleware
        await dispatch(manager.actions.setManagerData(locationData.data.docs))
        await dispatch(manager.actions.setManagerDataTotal(locationData.data.totalDocs))
        history.push('/manager/list')
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  const addSubmit = async (values: IManagerFormValues) => {
    try {
      await createManagerData(values)
      await callManagerData()
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  return (
    <div>
      <CreateManagerForm onSubmit={(values: IManagerFormValues) => addSubmit(values)} />
    </div>
  )
}

const CreateWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'create',
            path: '/manager/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'create',
            path: '/manager/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.MANAGER'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {CreateWrapper}
