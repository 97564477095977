import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {getActiveLocationData} from '../../location/redux/LocationCRUD'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'
import {InputNumberText} from '../../../modules/comman/formComponents/InputNumberText'
interface IRenderFormikForm {
  values: IClientFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IClientFormValues {
  first_name: string
  last_name: string
  email: string
  company_name: string
  phone: number
  address: string
  location_id: string
  state: string
  pincode: number
  pan: string
  gstin: string
}

interface IAddClientProps {
  onSubmit: any
  data?: any
  handleCancel?: any
}

interface ActiveLocationType {
  value?: any
  label?: any
}
export class CreateClientForm extends React.Component<IAddClientProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      activeLocationDropdown: [],
    }
  }

  async componentDidMount(): Promise<void> {
    try {
      this.setState({
        isLoading: true,
      })
      let activeLocationData = await getActiveLocationData()
      if (activeLocationData && activeLocationData.data && activeLocationData.data.length) {
        let options = []
        for (var i = 0; i < activeLocationData.data.length; i++) {
          if (activeLocationData.data[i]._id && activeLocationData.data[i].name) {
            let obj: ActiveLocationType = {}
            obj['value'] = activeLocationData.data[i]._id
            obj['label'] = activeLocationData.data[i].name
            options.push(obj)
          }
        }
        this.setState({
          activeLocationDropdown: options,
        })
      }
      this.setState({
        isLoading: false,
      })
    } catch (e) {
      throw e
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  public validateForm = (values: IClientFormValues) => {
    const errors: any = {}
    console.log('Validating form -- ', values)

    if (!values.first_name) {
      errors.first_name = 'First name is required'
    }

    if (!values.last_name) {
      errors.last_name = 'Last name is required'
    }

    if (!values.email) {
      errors.email = 'Email is required'
    }

    if (!values.company_name) {
      errors.company_name = 'Company name is required'
    }

    if (!values.phone) {
      errors.phone = 'Phone is required'
    }
    if (!values.location_id) {
      errors.location_id = 'Location is required'
    }
    if (!values.address) {
      errors.address = 'Address is required'
    }
    console.log('Validating errors -- ', errors)
    return errors
  }

  public handleSubmit = (values: IClientFormValues, action: any) => {
    console.log('Basic Value - ', values)

    this.props.onSubmit(values)
    this.props.handleCancel()

    this.setState({
      visible: false,
    })
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public render() {
    return (
      <div className='container'>
        <div className='card card-custom'>
          <div className='card-header'>
            <h3 className='card-title'>Create Client Form</h3>
          </div>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  first_name: '',
                  last_name: '',
                  email: '',
                  company_name: '',
                  phone: NaN,
                  address: '',
                  location_id: '',
                  state: '',
                  pincode: NaN,
                  pan: '',
                  gstin: '',
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.first_name,
                              id: 'first_name',
                              name: 'first_name',
                            }}
                            placeholder='Enter first name'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('first_name', value)
                            }}
                            onBlur={handleBlur}
                            label='First Name'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.last_name,
                              id: 'last_name',
                              name: 'last_name',
                            }}
                            placeholder='Enter last name'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('last_name', value)
                            }}
                            onBlur={handleBlur}
                            label='Last Name'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.company_name,
                              id: 'company_name',
                              name: 'company_name',
                            }}
                            placeholder='Enter company name'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('company_name', value)
                            }}
                            onBlur={handleBlur}
                            label='Company Name'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.phone,
                              id: 'phone',
                              name: 'phone',
                            }}
                            placeholder='Enter phone EX: (+91-6353689198)'
                            onChange={(value: number) => {
                              handleChange(value)
                              setFieldValue('phone', value)
                            }}
                            onBlur={handleBlur}
                            label='Phone'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.email,
                              id: 'email',
                              name: 'email',
                            }}
                            placeholder='Enter email'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('email', value)
                            }}
                            onBlur={handleBlur}
                            label='Email'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.address,
                              id: 'address',
                              name: 'address',
                            }}
                            placeholder='Enter address'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('address', value)
                            }}
                            onBlur={handleBlur}
                            label='Address'
                            error={errors}
                            touched={touched}
                            required={false}
                          />
                        </div>
                      </div>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputSingleSelect
                            input={{
                              value: values.location_id,
                              id: 'location_id',
                              name: 'location_id',
                              options: this.state.activeLocationDropdown,
                            }}
                            placeholder='Select Location'
                            onChangeMethod={(value: string) => {
                              setFieldValue('location_id', value)
                            }}
                            label='Select Location'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.state,
                              id: 'state',
                              name: 'state',
                            }}
                            placeholder='Enter State'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('state', value)
                            }}
                            onBlur={handleBlur}
                            label='State'
                            error={errors}
                            touched={touched}
                            required={false}
                          />
                        </div>
                      </div>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.pincode,
                              id: 'pincode',
                              name: 'pincode',
                            }}
                            placeholder='Enter Pincode'
                            onChange={(value: number) => {
                              handleChange(value)
                              setFieldValue('pincode', value)
                            }}
                            onBlur={handleBlur}
                            label='Pincode'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.pan,
                              id: 'pan',
                              name: 'pan',
                            }}
                            placeholder='Enter Pan No.'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('pan', value)
                            }}
                            onBlur={handleBlur}
                            label='PAN No.'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.gstin,
                              id: 'gstin',
                              name: 'gstin',
                            }}
                            placeholder='Enter GSTIN.'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('gstin', value)
                            }}
                            onBlur={handleBlur}
                            label='GSTIN.'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <InputButtonComponent onClick={handleSubmit}>Create</InputButtonComponent>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
          {/* <div className='card-footer'></div> */}
        </div>
      </div>
    )
  }
}
