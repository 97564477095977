import React, {Component} from 'react'
import {InputSingleFileUpload} from '../../../modules/comman/formComponents/InputSingleFileUpload'
import {Alert} from 'antd'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {Button} from 'antd'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {bulkUploadDiscountData} from '../../inventory/redux/InventoryCRUD'
import {Spin} from 'antd'
import {config} from '../../../comman/constants'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'

interface IAddClientProps {
  data?: any
  handleCancel?: any
}

class BulkUploadDiscountModal extends React.Component<IAddClientProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      viewFileUploadModal: false,
      uploadMedia: [],
      video_url: '',
      copied: false,
      loading: false,
      isVideoLinked: false,
      file_name: '',
    }
  }
  viewFileUploadModalRef: {show: () => void} | undefined

  public uploadMedia = (fileName: string, FileData: Array<any>, customFileData: Object) => {
    if (fileName && FileData.length >= 1) {
      this.setState({
        uploadMedia: FileData,
      })
      console.log('!!!!!!!!!!!uploadMedia')
    }
    //close modal popup
    this.setState({
      viewFileUploadModal: false,
      isVideoLinked: false,
    })
  }

  public handleSubmit = async () => {
    try {
      await bulkUploadDiscountData(this.state.file_name)

      Toaster({
        type: 'success',
        title: 'Bulk Uload',
        description: 'File Uploaded Successfully',
      })
    } catch (e) {
      Toaster({
        type: 'error',
        title: 'Bulk Uload',
        description: 'File Upload Failed',
      })
    } finally {
      this.props.handleCancel()
    }
  }

  render() {
    return (
      <>
        {console.log(this.state.file_name, 'this.state.file_name')}
        <div>
          <InputSingleFileUpload
            // ref={(e: any) => {
            //   this.viewFileUploadModalRef = e
            // }}
            buttonLabel={'Choose File'}
            onBlur={() => {}}
            error={() => {}}
            touched={() => {}}
            name='fileName'
            input={{
              id: 'import-inventory',
              name: config.importStockUploadKey,
              action: config.importStockUploadPath,
              headers: {
                authorization: 'authorized-text',
              },
              accept: config.fileExtensionsForDropZone,
              acceptMimeTypes: config.fileExtensionsMimeTypes,
              mediaType: config.importStockUploadKey,
              mediaModuleType: config.importStockUploadKey,
            }}
            onUpload={(fileName: string, FileData: Array<any>, customFileData: Object) => {
              this.uploadMedia(fileName, FileData, customFileData)
              this.setState({
                file_name: fileName && FileData.length >= 1 ? fileName : '',
              })
            }}
          />
        </div>
        <div className='fv-plugins-message-container text-primary mt-2 d-flex justify-content-end'>
          <div className='fv-help-block'>
            <a href={config.downloadSampleFilePathForDiscountStock} target='_blank'>
              {'Download Sample file'}
            </a>
          </div>
        </div>
        {this.state.file_name && this.state.file_name.length && (
          <div
            style={{marginTop: '26px', marginLeft: '12px', fontWeight: 'bold', fontSize: '16px'}}
          >
            <span style={{marginRight: '15px'}}>{this.state.file_name} Uploaded Successfully.</span>
            <span>
              <InputButtonComponent onClick={this.handleSubmit.bind(this)}>
                Submit
              </InputButtonComponent>
            </span>
          </div>
        )}
      </>
    )
  }
}

export default BulkUploadDiscountModal
