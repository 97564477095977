import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {ClientWrapper} from '../pages/client/ClientWrapper'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {LocationWrapper} from '../pages/location/LocationWrapper'
import {ManagerWrapper} from '../pages/manager/ManagerWrapper'
import {StaffWrapper} from '../pages/staff/StaffWrapper'
import {InventoryWrapper} from '../pages/inventory/InventoryWrapper'
import {PackageWrapper} from '../pages/package/PackageWrapper'
import {StockDetailWrapper} from '../modules/auth/components/StockDetail'
import {CartWrapper} from '../pages/cart/CartWrapper'
import {CourierWrapper} from '../pages/courier/CourierWrapper'
import {TallyWrapper} from '../pages/tally/TallyWrapper'

export function PrivateRoutes() {
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        {/* For Dashboard */}
        <Route path='/dashboard' exact component={DashboardWrapper} />

        {/* For Client */}
        <Route path='/client' component={ClientWrapper} />

        {/* For Location */}
        <Route path='/location' component={LocationWrapper} />

        {/* For Manager */}
        <Route path='/manager' component={ManagerWrapper} />

        {/* For Staff */}
        <Route path='/staff' component={StaffWrapper} />

        {/* For Inventory */}
        <Route path='/inventory' component={InventoryWrapper} />

        {/* For Packages */}
        <Route path='/packages' component={PackageWrapper} />

        {/* For Courier */}
        <Route path='/courier' component={CourierWrapper} />

        {/* For Courier */}
        <Route path='/tally' component={TallyWrapper} />

        {/* For Cart */}
        {/* <Route path='/cart' component={CartWrapper} /> */}

        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='/error/404' />
      </Switch>
    </Suspense>
  )
}
