/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as courier from '../redux/CourierRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {EyeOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
import {
  getCourierData,
  editCourierData,
  getSearchCourierData,
  deleteCourierData,
} from '../redux/CourierCRUD'
import {EditCourierForm} from './EditCourierForm'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  courierData: any
  courierDataTotal: number
  courierLoading: boolean
  setListCourierData: (courierData: any) => void
  setListCourierDataTotal: (courierDataTotal: number) => void
  setCourierLoading: (courierLoading: boolean) => void
  history?: History<LocationState>
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  courierSelectedData: any
}

type PathParamsType = {
  param1: string
}
class ListCourierData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      courierSelectedData: null,
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListCourierData(pagination)
    )
  }

  public getListCourierData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listCourierSearchData(start, end)
        } else {
          this.listCourierData(start, end)
        }
      }
    )
  }

  public listCourierData = async (start: number, end: any) => {
    const {setCourierLoading} = this.props
    try {
      const {setListCourierData, setListCourierDataTotal} = this.props
      await setCourierLoading(true)
      let courierData = await getCourierData(start, end)
      if (courierData.data) {
        await setListCourierData(courierData.data.docs)
        await setListCourierDataTotal(courierData.data.totalDocs)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setCourierLoading(false)
    }
  }

  public listCourierSearchData = async (start: number, end: any) => {
    const {setCourierLoading} = this.props
    try {
      const {setListCourierData, setListCourierDataTotal} = this.props
      const {searchText} = this.state

      await setCourierLoading(true)
      let courierData = await getSearchCourierData(start, end, searchText)
      if (courierData.data) {
        await setListCourierData(courierData.data.docs)
        await setListCourierDataTotal(courierData.data.totalDocs)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setCourierLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listCourierSearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public onCreateCourier = () => {
    const {history} = this.props
    history.push('/courier/create')
  }

  public deleteCourier = async (item: any) => {
    const {setCourierLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setCourierLoading(true)
      await deleteCourierData(item._id)

      Toaster({
        type: 'success',
        title: 'COURIER',
        description: 'Deleted SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listCourierSearchData(startIndex, limitNumber)
        return
      }
      this.listCourierData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setCourierLoading(false)
    }
  }

  public onChangeStatus = async (item: any, checked: boolean) => {
    const {setCourierLoading} = this.props
    // try {
    //   const {startIndex, limitNumber, searchText} = this.state

    //   await setCourierLoading(true)
    //   await changeStatusLocationData(item.id, checked)

    //   Toaster({
    //     type: 'success',
    //     title: 'COURIER',
    //     description: 'Status changed successFully',
    //   })

    //   if (searchText != null && searchText !== '') {
    //     this.listCourierSearchData(startIndex, limitNumber)
    //     return
    //   }
    //   this.listCourierData(startIndex, limitNumber)
    // } catch (e) {
    //   console.log('!!!!Error', e)
    // } finally {
    //   await setCourierLoading(false)
    // }
  }

  public onEditSubmit = async (values: any, locationId: string) => {
    const {setCourierLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setCourierLoading(true)
      await editCourierData(values, locationId)

      Toaster({
        type: 'success',
        title: 'COURIER',
        description: 'Edited SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listCourierSearchData(startIndex, limitNumber)
        return
      }
      this.listCourierData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setCourierLoading(false)
    }
  }

  public showEditModal = async (item: any) => {
    this.setState({
      courierSelectedData: item,
    })
  }

  public closeEditModal = async () => {
    this.setState({
      courierSelectedData: null,
    })
  }

  counter = 0
  tempCounter = 0
  public columns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Name',
      key: 'name',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.name ? item.name : '-'}</strong>
          </div>
        )
      },
    },
    {
      title: 'Address',
      key: 'address',
      render: (text: any, item: any, index: number) => {
        return <div>{item.address ? item.address : '-'}</div>
      },
    },
    {
      title: 'Actions',
      width: 130,
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Edit'>
                <Button
                  shape='circle'
                  icon={<EditOutlined />}
                  onClick={() => {
                    this.showEditModal(item)
                  }}
                />
              </Tooltip>
            </div>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Delete'>
                <Popconfirm
                  onConfirm={() => {
                    //call delete courier api
                    this.deleteCourier(item)
                  }}
                  title='Are you sure you want to delete courier?'
                >
                  <Button shape='circle' icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        )
      },
    },
  ]

  public render() {
    const {courierData, courierDataTotal, courierLoading} = this.props
    const {searchText, courierSelectedData} = this.state

    return (
      <div>
        <div className='container'>
          <div className='card card-custom'>
            <div className='card-header'>
              <h3 className='card-title table-card-title' style={{width: '25%'}}>
                <InputSearchText
                  onChange={(value: string) => {
                    this.onSearchChange(value)
                  }}
                  placeholder='Search Courier'
                  input={{
                    value: searchText,
                  }}
                />
              </h3>
              <div className='card-toolbar'>
                <InputButtonComponent
                  onClick={() => {
                    this.onCreateCourier()
                  }}
                >
                  Add Courier
                </InputButtonComponent>
              </div>
            </div>
            <div className='card-body'>
              <div style={{overflowX: 'auto'}}>
                <ListTable
                  rowKey={(record: {id: any}) => record.id}
                  onChange={this.handleTableChange}
                  column={this.columns}
                  paginationConfig={{
                    total: courierDataTotal,
                    showSizeChanger: true,
                    pageSize: this.state.pagination.pageSize,
                    current: this.state.pagination.current,
                  }}
                  isLoading={courierLoading}
                  dataSource={courierData}
                  counter={this.counter}
                  tempCounter={this.tempCounter}
                />
                <div>
                  <CommanModal
                    show={courierSelectedData ? true : false}
                    handleClose={() => {
                      this.closeEditModal()
                    }}
                    title={'Edit Courier'}
                    submitText={'Save Changes'}
                  >
                    <div>
                      {courierSelectedData && (
                        <EditCourierForm
                          data={courierSelectedData}
                          onSubmit={(values: any, locationId: string) => {
                            this.onEditSubmit(values, locationId)
                          }}
                          handleCancel={() => {
                            this.closeEditModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  courierData: state.courier.courierData,
  courierDataTotal: state.courier.courierDataTotal,
  courierLoading: state.courier.courierLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListCourierData: (courierData: any) => {
      dispatch(courier.actions.setCourierData(courierData))
    },
    setListCourierDataTotal: (courierDataTotal: number) => {
      dispatch(courier.actions.setCourierDataTotal(courierDataTotal))
    },
    setCourierLoading: (courierLoading: boolean) => {
      dispatch(courier.actions.setCourierLoading(courierLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListCourierData))
