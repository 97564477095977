import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {PackageModal} from '../models/PackageModal'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setPackageData: 'SET_PACKAGE_DATA',
  setPackageDataTotal: 'SET_PACKAGE_DATA_TOTAL',
  setPackageLoading: 'SET_PACKAGE_LOADER',
}

const initialClientState: IClientState = {
  packageData: undefined,
  packageDataTotal: undefined,
  packageLoading: false,
}

export interface IClientState {
  packageData?: PackageModal
  packageDataTotal?: number
  packageLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'package', whitelist: ['packageData', 'packageDataTotal']},
  (state: IClientState = initialClientState, action: ActionWithPayload<IClientState>) => {
    switch (action.type) {
      case actionTypes.setPackageData: {
        const packageData = action.payload?.packageData
        return {...state, packageData}
      }

      case actionTypes.setPackageDataTotal: {
        const packageDataTotal = action.payload?.packageDataTotal
        return {...state, packageDataTotal}
      }

      case actionTypes.setPackageLoading: {
        const packageLoading = action.payload?.packageLoading
        return {...state, packageLoading}
      }

      default:
        return state
    }
  }
)

export const actions = {
  setPackageData: (packageData: Array<PackageModal>) => ({
    type: actionTypes.setPackageData,
    payload: {packageData: packageData},
  }),
  setPackageDataTotal: (packageDataTotal: number) => ({
    type: actionTypes.setPackageDataTotal,
    payload: {packageDataTotal},
  }),
  setPackageLoading: (packageLoading: boolean) => ({
    type: actionTypes.setPackageLoading,
    payload: {packageLoading},
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.setPackageData, function* loginSaga() {
    yield put(actions.setPackageLoading(false))
  })

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser())
  // })

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const {data: user} = yield getUserByToken()
  //   yield put(actions.fulfillUser(user))
  // })
}
