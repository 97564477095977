import React from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_PACKAGE_URL = `${API_URL}api/v1/auth/admin//packages/`
export const SEARCH_PACKAGE_URL = `${API_URL}api/v1/auth/admin//packages/`
export const CREATE_PACKAGE_URL = `${API_URL}api/v1/auth/admin/package/`
export const EDIT_PACKAGE_URL = `${API_URL}api/v1/auth/admin/package/`
export const DELETE_PACKAGE_URL = `${API_URL}api/v1/auth/admin/package/`
export const CHANGE_STATUS_PACKAGE_URL = `${API_URL}api/v1/auth/admin/package/`
export const GET_ACTIVE_PACKAGE_URL = `${API_URL}api/v1/auth/admin/active-package/`
export const GET_VIEW_PACKAGE_URL = `${API_URL}api/v1/auth/admin/package/`

// Get package Data
export async function getPackageData(startIndex: number, limitNumber: number) {
  try {
    let packageData = await axios.post(`${GET_PACKAGE_URL}${startIndex}/${limitNumber}`, {
      searchText: '',
    })
    return packageData
  } catch (e) {
    throw e
  }
}

// Get package Data
export async function getViewPackageData(packageId: string) {
  try {
    let packageData = await axios.get(`${GET_VIEW_PACKAGE_URL}${packageId}`)
    return packageData
  } catch (e) {
    throw e
  }
}

export async function getActivePackageData() {
  try {
    let packageData = await axios.get(`${GET_ACTIVE_PACKAGE_URL}`)
    return packageData
  } catch (e) {
    throw e
  }
}

// Search package Data
export async function getSearchPackageData(
  startIndex: number,
  limitNumber: number,
  searchText: string
) {
  try {
    let packageData = await axios.post(`${SEARCH_PACKAGE_URL}${startIndex}/${limitNumber}`, {
      searchText,
    })
    return packageData
  } catch (e) {
    throw e
  }
}

// Create Package Data
export async function createPackageData(packageObject: any) {
  try {
    let createPackageData = await axios.post(`${CREATE_PACKAGE_URL}`, {
      ...packageObject,
    })
    return createPackageData
  } catch (e) {
    throw e
  }
}

// Delete Package Data
export async function deletePackageData(packageId: string) {
  try {
    let deletePackageData = await axios.delete(`${DELETE_PACKAGE_URL}${packageId}`)
    return deletePackageData
  } catch (e) {
    throw e
  }
}

// Edit Package Data
export async function editPackageData(packageObject: any, id: any) {
  try {
    let editPackageData = await axios.put(`${EDIT_PACKAGE_URL}`, {
      ...packageObject,
    })
    return editPackageData
  } catch (e) {
    throw e
  }
}
