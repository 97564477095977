import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputNumberText} from '../../../modules/comman/formComponents/InputNumberText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'
import {InputMultiSelect} from '../../../modules/comman/formComponents/InputMultiSelect'
import {getActiveLocationData} from '../../location/redux/LocationCRUD'

interface IRenderFormikForm {
  values: IStockFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IStockFormValues {
  rapnet: string
  shape: string
  color: string
  weight: number
  clarity: string
  rap: number
  price_per_caret: number
  cut: string
  polish: string
  symmetry: string
  id: string
  fluorescence: string
  measurement: string
  table: number
  depth: number
  lab: string
  cert_number: string
  cert_link: string
  discount: string
  no_bgm: string
  location: string
  video_link: string
}

interface IAddStockProps {
  onSubmit: any
  data?: any
  handleCancel?: any
}

interface ActiveLocationType {
  value?: any
  label?: any
}
export class EditStockCertDataForm extends React.Component<IAddStockProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      activeLocationDropdown: [],
    }
  }

  public validateForm = (values: IStockFormValues) => {
    const errors: any = {}
    // console.log('Validating form -- ', values)

    if (!values.shape) {
      errors.shape = 'Shape is required'
    }
    if (!values.color) {
      errors.color = 'Color is required'
    }
    if (!values.weight) {
      errors.weight = 'Weight is required'
    }
    if (!values.clarity) {
      errors.clarity = 'Clarity is required'
    }
    if (!values.rap) {
      errors.rap = 'Rap is required'
    }
    // if (!values.cut) {
    //   errors.cut = 'Cut is required'
    // }
    if (!values.polish) {
      errors.polish = 'Polish is required'
    }
    if (!values.symmetry) {
      errors.symmetry = 'Symmetry is required'
    }
    if (!values.id) {
      errors.id = 'Id is required'
    }
    if (!values.discount) {
      errors.discount = 'Discount is required'
    }

    if (!values.cert_number) {
      errors.cert_number = 'Cert No is required'
    }
    
    console.log('Validating errors -- ', errors)
    return errors
  }

  public handleSubmit = (values: IStockFormValues, action: any) => {
    console.log('Basic Value - ', values)

    this.props.onSubmit(values, values.id)
    this.props.handleCancel()

    this.setState({
      visible: false,
    })
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public render() {
    return (
      <div className='container'>
        <div className='card card-custom'>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  rapnet: this.props.data.rapnet ? this.props.data.rapnet : '',
                  shape: this.props.data.shape ? this.props.data.shape : '',
                  color: this.props.data.color ? this.props.data.color : '',
                  clarity: this.props.data.clarity ? this.props.data.clarity : '',
                  weight: this.props.data.weight ? this.props.data.weight : NaN,
                  rap: this.props.data.rap ? this.props.data.rap : NaN,
                  price_per_caret: this.props.data.price_per_caret
                    ? this.props.data.price_per_caret
                    : NaN,
                  cut: this.props.data.cut ? this.props.data.cut : '',
                  polish: this.props.data.polish ? this.props.data.polish : '',
                  symmetry: this.props.data.symmetry ? this.props.data.symmetry : '',
                  id: this.props.data._id ? this.props.data._id : '',
                  fluorescence: this.props.data.fluorescence ? this.props.data.fluorescence : '',
                  measurement: this.props.data.measurement ? this.props.data.measurement : '',
                  table:
                    this.props.data.table || this.props.data.table == 0
                      ? this.props.data.table
                      : NaN,
                  depth:
                    this.props.data.depth || this.props.data.depth == 0
                      ? this.props.data.depth
                      : NaN,
                  lab: this.props.data.lab ? this.props.data.lab : '',
                  cert_number: this.props.data.cert_number ? this.props.data.cert_number : '',
                  cert_link: this.props.data.cert_link ? this.props.data.cert_link : '',
                  discount: this.props.data.discount ? this.props.data.discount : '',
                  no_bgm: this.props.data.no_bgm ? this.props.data.no_bgm : '',
                  location: this.props.data.location ? this.props.data.location : '',
                  video_link: this.props.data.video_link ? this.props.data.video_link : '',
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.discount,
                              id: 'discount',
                              name: 'discount',
                            }}
                            placeholder='Enter Discount'
                            onChange={(value: any) => {
                              handleChange(value)
                              setFieldValue('discount', value)
                            }}
                            onBlur={handleBlur}
                            label='Discount'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.shape,
                              id: 'shape',
                              name: 'shape',
                            }}
                            placeholder='Enter Shape'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('shape', value)
                            }}
                            onBlur={handleBlur}
                            label='Shape'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>

                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.color,
                              id: 'color',
                              name: 'color',
                            }}
                            placeholder='Enter Color'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('color', value)
                            }}
                            onBlur={handleBlur}
                            label='Color'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.weight,
                              id: 'weight',
                              name: 'weight',
                            }}
                            placeholder='Enter Weight'
                            onChange={(value: any) => {
                              handleChange(value)
                              setFieldValue('weight', value)
                            }}
                            onBlur={handleBlur}
                            label='Weight'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>

                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.clarity,
                              id: 'clarity',
                              name: 'clarity',
                            }}
                            placeholder='Enter Clarity'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('clarity', value)
                            }}
                            onBlur={handleBlur}
                            label='Clarity'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.rap,
                              id: 'rap',
                              name: 'rap',
                            }}
                            placeholder='Enter Rap'
                            onChange={(value: any) => {
                              handleChange(value)
                              setFieldValue('rap', value)
                            }}
                            onBlur={handleBlur}
                            label='Rap'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>

                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.polish,
                              id: 'polish',
                              name: 'polish',
                            }}
                            placeholder='Enter Polish'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('polish', value)
                            }}
                            onBlur={handleBlur}
                            label='Polish'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.cut,
                              id: 'cut',
                              name: 'cut',
                            }}
                            placeholder='Enter Cut'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('cut', value)
                            }}
                            onBlur={handleBlur}
                            label='Cut'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>

                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.symmetry,
                              id: 'symmetry',
                              name: 'symmetry',
                            }}
                            placeholder='Enter Symmetry'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('symmetry', value)
                            }}
                            onBlur={handleBlur}
                            label='Symmetry'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.cert_number,
                              id: 'cert_number',
                              name: 'cert_number',
                            }}
                            placeholder='Enter Cert Number'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('cert_number', value)
                            }}
                            onBlur={handleBlur}
                            label='Cert Number'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>

                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.fluorescence,
                              id: 'fluorescence',
                              name: 'fluorescence',
                            }}
                            placeholder='Enter Florence '
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('fluorescence', value)
                            }}
                            required={false}
                            onBlur={handleBlur}
                            label='Florence'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.measurement,
                              id: 'measurement',
                              name: 'measurement',
                            }}
                            placeholder='Enter Measurement'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('measurement', value)
                            }}
                            required={false}
                            onBlur={handleBlur}
                            label='Measurement'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>

                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.depth,
                              id: 'depth',
                              name: 'depth',
                            }}
                            placeholder='Enter Depth'
                            onChange={(value: number) => {
                              handleChange(value)
                              setFieldValue('depth', value)
                            }}
                            required={false}
                            onBlur={handleBlur}
                            label='Depth'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.lab,
                              id: 'lab',
                              name: 'lab',
                            }}
                            placeholder='Enter Lab'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('lab', value)
                            }}
                            required={false}
                            onBlur={handleBlur}
                            label='Lab'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>

                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.rapnet,
                              id: 'rapnet',
                              name: 'rapnet',
                            }}
                            placeholder='Enter Rap Net'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('rapnet', value)
                            }}
                            required={false}
                            onBlur={handleBlur}
                            label='Rap Net'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.table,
                              id: 'table',
                              name: 'table',
                            }}
                            required={false}
                            placeholder='Enter Table'
                            onChange={(value: number) => {
                              handleChange(value)
                              setFieldValue('table', value)
                            }}
                            onBlur={handleBlur}
                            label='Table'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>

                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.location,
                              id: 'location',
                              name: 'location',
                            }}
                            placeholder='Enter Location'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('location', value)
                            }}
                            required={false}
                            onBlur={handleBlur}
                            label='Location'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.video_link,
                              id: 'video_link',
                              name: 'video_link',
                            }}
                            placeholder='Enter Video Link'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('video_link', value)
                            }}
                            required={false}
                            onBlur={handleBlur}
                            label='Video Link'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.no_bgm,
                              id: 'no_bgm',
                              name: 'no_bgm',
                            }}
                            placeholder='Enter BGM'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('no_bgm', value)
                            }}
                            required={false}
                            onBlur={handleBlur}
                            label='BGM'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>

                      <InputButtonComponent onClick={handleSubmit}>Edit</InputButtonComponent>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
          {/* <div className='card-footer'></div> */}
        </div>
      </div>
    )
  }
}
