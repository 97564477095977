/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as inventory from '../redux/InventoryRedux'
import {config} from '../../../comman/constants'
import {History, LocationState} from 'history'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {getInventoryMemoData} from '../redux/InventoryCRUD'
import {Card} from 'antd'
var moment = require('moment')
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  inventoryData: any
  inventoryDataTotal: number
  inventoryLoading: boolean
  setListInventoryData: (inventoryData: any) => void
  setListInventoryDataTotal: (inventoryDataTotal: number) => void
  setInventoryLoading: (inventoryLoading: boolean) => void
  history?: History<LocationState>
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  memoSelectedData: any
}

type PathParamsType = {
  param1: string
}

interface IAddStockProps {
  onSubmit: any
  data?: any
  handleCancel?: any
}

class ViewMemoData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps & IAddStockProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      memoSelectedData: null,
    }
  }

  public componentDidMount = async () => {
    if (this.props.data && this.props.data._id) {
      await this.listMemoData(0, 10)
    }
  }

  public listMemoData = async (start: number, end: any) => {
    const {setInventoryLoading} = this.props
    try {
      const {setListInventoryData, setListInventoryDataTotal} = this.props
      await setInventoryLoading(true)
      let inventoryData = await getInventoryMemoData(start, end, this.props.data._id)
      if (inventoryData.data) {
        this.setState({
          memoSelectedData: inventoryData.data,
        })
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setInventoryLoading(false)
    }
  }

  public dateFormat(date: any) {
    var oldDate = new Date(date),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.format('LL')
    return newDate
  }

  public render() {
    const {inventoryData, inventoryDataTotal, inventoryLoading} = this.props
    const {searchText, memoSelectedData} = this.state

    return (
      <>
        {this.state.memoSelectedData &&
          this.state.memoSelectedData.length &&
          this.state.memoSelectedData &&
          this.state.memoSelectedData.map((item: any) => {
            return (
              <div
                className='col-sm-12 col-md-6 col-lg-6'
                style={{display: 'inline-block', marginBottom: '10px'}}
              >
                <Card title='MEMO' style={{width: '100%'}}>
                  <div className='group row' style={{marginBottom: '10px'}}>
                    <div className='col-sm-6 col-md-4 col-lg-4 font-weight-bold text-muted'>
                      Staff Name:
                    </div>
                    <div className='col-sm-6 col-md-8 col-lg-8'>
                      {item.created_by
                        ? item.created_by.first_name && item.created_by.last_name
                          ? `${item.created_by.first_name}  ${item.created_by.last_name}`
                          : item.created_by.first_name
                          ? item.created_by.first_name
                          : '-'
                        : '-'}
                    </div>
                  </div>
                  <hr></hr>
                  <div className='group row' style={{marginBottom: '10px'}}>
                    <div className='col-sm-6 col-md-4 col-lg-4 font-weight-bold text-muted'>
                      Client Name:
                    </div>
                    <div className='col-sm-6 col-md-8 col-lg-8'>
                      {item.client_id
                        ? item.client_id.first_name && item.client_id.last_name
                          ? `${item.client_id.first_name}  ${item.client_id.last_name}`
                          : item.client_id.first_name
                          ? item.client_id.first_name
                          : '-'
                        : '-'}
                    </div>
                  </div>
                  <hr></hr>
                  <div className='group row'>
                    <div className='col-sm-6 col-md-4 col-lg-4 font-weight-bold text-muted'>
                      Date:
                    </div>
                    {item.created_at ? this.dateFormat(item.created_at) : '-'}
                  </div>
                </Card>
              </div>
            )
          })}
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  inventoryData: state.inventory.inventoryData,
  inventoryDataTotal: state.inventory.inventoryDataTotal,
  inventoryLoading: state.inventory.inventoryLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListInventoryData: (inventoryData: any) => {
      dispatch(inventory.actions.setInventoryData(inventoryData))
    },
    setListInventoryDataTotal: (inventoryDataTotal: number) => {
      dispatch(inventory.actions.setInventoryDataTotal(inventoryDataTotal))
    },
    setInventoryLoading: (inventoryLoading: boolean) => {
      dispatch(inventory.actions.setInventoryLoading(inventoryLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewMemoData))
