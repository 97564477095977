import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputNumberText} from '../../../modules/comman/formComponents/InputNumberText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'
import {InputMultiSelect} from '../../../modules/comman/formComponents/InputMultiSelect'
import {getActiveLocationData} from '../../location/redux/LocationCRUD'

interface IRenderFormikForm {
  values: IStockFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IStockFormValues {
  weight: number
  id: string
  shape: string
  price_per_caret: number
  location: string
}

interface IAddStockProps {
  onSubmit: any
  data?: any
  handleCancel?: any
}

interface ActiveLocationType {
  value?: any
  label?: any
}
export class EditStockStarMeleeDataForm extends React.Component<IAddStockProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      activeLocationDropdown: [],
    }
  }

  public validateForm = (values: IStockFormValues) => {
    const errors: any = {}
    // console.log('Validating form -- ', values)
    if (!values.weight) {
      errors.weight = 'Weight is required'
    }

    if (!values.shape) {
      errors.shape = 'Shape is required'
    }


    if (!values.id) {
      errors.id = 'Id is required'
    }

    if (!values.price_per_caret) {
      errors.price_per_caret = 'Price Per Caret is required'
    }

    if (values.price_per_caret && values.price_per_caret < 0) {
      errors.price_per_caret = 'Price Per Caret must be positive'
    }

    console.log('Validating errors -- ', errors)
    return errors
  }

  public handleSubmit = (values: IStockFormValues, action: any) => {
    console.log('Basic Value - ', values)

    this.props.onSubmit(values, values.id)
    this.props.handleCancel()

    this.setState({
      visible: false,
    })
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public render() {
    return (
      <div className='container'>
        <div className='card card-custom'>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  weight: this.props.data.weight ? this.props.data.weight : NaN,
                  id: this.props.data._id ? this.props.data._id : '',
                  shape: this.props.data.shape ? this.props.data.shape : '',
                  price_per_caret: this.props.data.price_per_caret ? this.props.data.price_per_caret : NaN,
                  location: this.props.data.location ? this.props.data.location : ''
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      <div className='form-group row'>
                      <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.shape,
                              id: 'shape',
                              name: 'shape',
                            }}
                            placeholder='Enter Shape'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('shape', value)
                            }}
                            onBlur={handleBlur}
                            label='Shape'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.weight,
                              id: 'weight',
                              name: 'weight',
                            }}
                            placeholder='Enter Weight'
                            onChange={(value: any) => {
                              handleChange(value)
                              setFieldValue('weight', value)
                            }}
                            onBlur={handleBlur}
                            label='Weight'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div className='form-group row'>
                      <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.price_per_caret,
                              id: 'price_per_caret',
                              name: 'price_per_caret',
                            }}
                            placeholder='Enter Price Per Caret'
                            onChange={(value: any) => {
                              handleChange(value)
                              setFieldValue('price_per_caret', value)
                            }}
                            onBlur={handleBlur}
                            label='Price Per Caret'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.location,
                              id: 'location',
                              name: 'location',
                            }}
                            placeholder='Enter Location'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('location', value)
                            }}
                            onBlur={handleBlur}
                            label='Location'
                            error={errors}
                            touched={touched}
                            required={false}
                          />
                        </div>
                      </div>
                      <InputButtonComponent onClick={handleSubmit}>Edit</InputButtonComponent>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
          {/* <div className='card-footer'></div> */}
        </div>
      </div>
    )
  }
}
