import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setCourierData: 'SET_PACKAGE_DATA',
  setCourierDataTotal: 'SET_PACKAGE_DATA_TOTAL',
  setCourierLoading: 'SET_PACKAGE_LOADER',
}

const initialClientState: IClientState = {
  courierData: undefined,
  courierDataTotal: undefined,
  courierLoading: false,
}

export interface IClientState {
  courierData?: any
  courierDataTotal?: number
  courierLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'courier', whitelist: ['courierData', 'courierDataTotal']},
  (state: IClientState = initialClientState, action: ActionWithPayload<IClientState>) => {
    switch (action.type) {
      case actionTypes.setCourierData: {
        const courierData = action.payload?.courierData
        return {...state, courierData}
      }

      case actionTypes.setCourierDataTotal: {
        const courierDataTotal = action.payload?.courierDataTotal
        return {...state, courierDataTotal}
      }

      case actionTypes.setCourierLoading: {
        const courierLoading = action.payload?.courierLoading
        return {...state, courierLoading}
      }

      default:
        return state
    }
  }
)

export const actions = {
  setCourierData: (courierData: Array<any>) => ({
    type: actionTypes.setCourierData,
    payload: {courierData: courierData},
  }),
  setCourierDataTotal: (courierDataTotal: number) => ({
    type: actionTypes.setCourierDataTotal,
    payload: {courierDataTotal},
  }),
  setCourierLoading: (courierLoading: boolean) => ({
    type: actionTypes.setCourierLoading,
    payload: {courierLoading},
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.setCourierData, function* loginSaga() {
    yield put(actions.setCourierLoading(false))
  })

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser())
  // })

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const {data: user} = yield getUserByToken()
  //   yield put(actions.fulfillUser(user))
  // })
}
