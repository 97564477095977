import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'
import {InputSingleFileUpload} from '../../../modules/comman/formComponents/InputSingleFileUpload'
import {PictureOutlined} from '@ant-design/icons'
import {Button} from 'antd'
import ListUploadedMediaItem from '../../../modules/comman/ListComponenets/ListUploadedMediaItem'
import {config} from '../../../comman/constants'

interface IRenderFormikForm {
  values: IImportInventoryFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IImportInventoryFormValues {
  offset: string
  fileName: string
}

interface IImportInventoryProps {
  onSubmit: any
  data?: any
  handleCancel?: any
}

export class ImportInventoryStarMeleeForm extends React.Component<IImportInventoryProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      viewFileUploadModal: false,
      isLoading: false,
      uploadMedia: [],
    }
  }

  viewFileUploadModalRef: {show: () => void} | undefined

  public validateForm = (values: IImportInventoryFormValues) => {
    const errors: any = {}

    if (!values.fileName) {
      errors.fileName = 'Please select file'
    }

    return errors
  }

  public handleSubmit = (values: IImportInventoryFormValues, action: any) => {
    this.props.onSubmit(values)

    this.setState({
      visible: false,
    })
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public uploadMedia = (fileName: string, FileData: Array<any>, customFileData: Object) => {
    if (fileName && FileData.length >= 1) {
      this.setState({
        uploadMedia: FileData,
      })
    }
    //close modal popup
    this.setState({
      viewFileUploadModal: false,
    })
  }

  public showMediaModal = (params: any) => {
    this.setState(
      {
        viewFileUploadModal: true,
      },
      () => {
        this.viewFileUploadModalRef?.show?.()
      }
    )
  }

  public render() {
    return (
      <div>
        <div className='card card-custom'>
          <div className='card-header'>
            <h3 className='card-title'>Import Inventory Non Cert</h3>
          </div>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  offset: '',
                  fileName: '',
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          {/* <InputText
                            input={{
                              value: values.offset,
                              id: 'offset',
                              name: 'offset',
                            }}
                            placeholder='Enter offset'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('offset', value)
                            }}
                            onBlur={handleBlur}
                            label='Offset'
                            required={false}
                            error={errors}
                            touched={touched}
                          /> */}

                          <div
                            style={{
                              display: 'inline-block',
                              width: '100%',
                              marginTop: '20px',
                              alignItems: 'center',
                            }}
                          >
                            {/* <div style={{float: 'left', width: '10%'}}>
                              <PictureOutlined style={{width: '100%', fontSize: '30px'}} />
                            </div>

                            <Button style={{width: '90%'}} onClick={this.showMediaModal.bind(this)}>
                              Choose Stock File
                            </Button> */}

                            {/* {this.state.viewFileUploadModal ? ( */}
                            <InputSingleFileUpload
                              // ref={(e: any) => {
                              //   this.viewFileUploadModalRef = e
                              // }}
                              buttonLabel={'Choose Stock File'}
                              onBlur={handleBlur}
                              error={errors}
                              touched={touched}
                              name='fileName'
                              input={{
                                id: 'import-inventory',
                                name: config.importStockUploadKey,
                                action: config.importStockUploadPath,
                                headers: {
                                  authorization: 'authorized-text',
                                },
                                accept: config.fileExtensionsForDropZone,
                                acceptMimeTypes: config.fileExtensionsMimeTypes,
                                mediaType: config.importStockUploadKey,
                                mediaModuleType: config.importStockUploadKey,
                              }}
                              onUpload={(
                                fileName: string,
                                FileData: Array<any>,
                                customFileData: Object
                              ) => {
                                this.uploadMedia(fileName, FileData, customFileData)
                                setFieldValue(
                                  'fileName',
                                  fileName && FileData.length >= 1 ? fileName : ''
                                )
                              }}
                            />

                            <div className='fv-plugins-message-container text-primary mt-2 d-flex justify-content-end'>
                              <div className='fv-help-block'>
                                <a href={config.downloadSampleFilePathForMelee} target='_blank'>
                                  {'Download Sample file'}
                                </a>
                              </div>
                            </div>

                            {this.state.uploadMedia &&
                            this.state.uploadMedia[0] &&
                            this.state.uploadMedia.length >= 1 ? (
                              <ListUploadedMediaItem
                                name={this.state.uploadMedia[0].response.file}
                                url={`${config.importStockPreviewPath}${this.state.uploadMedia[0].response.file}`}
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <InputButtonComponent onClick={handleSubmit}>Import</InputButtonComponent>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
          {/* <div className='card-footer'></div> */}
        </div>
      </div>
    )
  }
}
