import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {ManagerModal} from '../models/ManagerModal'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setManagerData: 'SET_MANAGER_DATA',
  setManagerDataTotal: 'SET_MANAGER_DATA_TOTAL',
  setManagerLoading: 'SET_MANAGER_LOADER',
}

const initialClientState: IClientState = {
  managerData: undefined,
  managerDataTotal: undefined,
  managerLoading: false,
}

export interface IClientState {
  managerData?: ManagerModal
  managerDataTotal?: number
  managerLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'manager', whitelist: ['managerData', 'managerDataTotal']},
  (state: IClientState = initialClientState, action: ActionWithPayload<IClientState>) => {
    switch (action.type) {
      case actionTypes.setManagerData: {
        const managerData = action.payload?.managerData
        return {...state, managerData}
      }

      case actionTypes.setManagerDataTotal: {
        const managerDataTotal = action.payload?.managerDataTotal
        return {...state, managerDataTotal}
      }

      case actionTypes.setManagerLoading: {
        const managerLoading = action.payload?.managerLoading
        return {...state, managerLoading}
      }

      default:
        return state
    }
  }
)

export const actions = {
  setManagerData: (managerData: Array<ManagerModal>) => ({
    type: actionTypes.setManagerData,
    payload: {managerData: managerData},
  }),
  setManagerDataTotal: (managerDataTotal: number) => ({
    type: actionTypes.setManagerDataTotal,
    payload: {managerDataTotal},
  }),
  setManagerLoading: (managerLoading: boolean) => ({
    type: actionTypes.setManagerLoading,
    payload: {managerLoading},
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.setManagerData, function* loginSaga() {
    yield put(actions.setManagerLoading(false))
  })

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser())
  // })

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const {data: user} = yield getUserByToken()
  //   yield put(actions.fulfillUser(user))
  // })
}
