/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as client from '../redux/ClientRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {UserOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons'
import {
  changeStatusClientData,
  deleteClientData,
  editClientData,
  getClientData,
  getSearchClientData,
} from '../redux/ClientCRUD'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
import {EditClientForm} from './EditClientForm'
import {IClientFormValues} from './CreateClientForm'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  clientData: any
  clientDataTotal: number
  clientLoading: boolean
  setListClientData: (clientData: any) => void
  setListClientDataTotal: (clientDataTotal: number) => void
  setClientLoading: (clientLoading: boolean) => void
  history?: History<LocationState>
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  clientSelectedData: any
}

type PathParamsType = {
  param1: string
}
class ListClientData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      clientSelectedData: null,
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListClientData(pagination)
    )
  }

  public getListClientData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listClientSearchData(start, end)
        } else {
          this.listClientData(start, end)
        }
      }
    )
  }

  public listClientData = async (start: number, end: any) => {
    const {setClientLoading} = this.props
    try {
      const {setListClientData, setListClientDataTotal} = this.props
      await setClientLoading(true)
      let clientData = await getClientData(start, end)
      if (clientData.data) {
        await setListClientData(clientData.data.docs)
        await setListClientDataTotal(clientData.data.totalDocs)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setClientLoading(false)
    }
  }

  public listClientSearchData = async (start: number, end: any) => {
    const {setClientLoading} = this.props
    try {
      const {setListClientData, setListClientDataTotal} = this.props
      const {searchText} = this.state

      await setClientLoading(true)
      let clientData = await getSearchClientData(start, end, searchText)
      if (clientData.data) {
        await setListClientData(clientData.data.docs)
        await setListClientDataTotal(clientData.data.totalDocs)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setClientLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listClientSearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public onCreateClient = () => {
    const {history} = this.props
    history.push('/client/create')
  }

  public deleteClient = async (item: any) => {
    const {setClientLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setClientLoading(true)
      await deleteClientData(item._id)

      Toaster({
        type: 'success',
        title: 'CLIENT',
        description: 'Deleted SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listClientSearchData(startIndex, limitNumber)
        return
      }
      this.listClientData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setClientLoading(false)
    }
  }

  public onChangeStatus = async (item: any, checked: boolean) => {
    const {setClientLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setClientLoading(true)
      await changeStatusClientData(item._id, checked)

      Toaster({
        type: 'success',
        title: 'CLIENT',
        description: 'Status changed successFully',
      })

      if (searchText != null && searchText !== '') {
        this.listClientSearchData(startIndex, limitNumber)
        return
      }
      this.listClientData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setClientLoading(false)
    }
  }

  public onEditSubmit = async (values: IClientFormValues, clientId: string) => {
    const {setClientLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setClientLoading(true)
      await editClientData(values, clientId)

      Toaster({
        type: 'success',
        title: 'CLIENT',
        description: 'Edited SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listClientSearchData(startIndex, limitNumber)
        return
      }
      this.listClientData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setClientLoading(false)
    }
  }

  public showEditModal = async (item: any) => {
    this.setState({
      clientSelectedData: item,
    })
  }

  public closeEditModal = async () => {
    this.setState({
      clientSelectedData: null,
    })
  }

  counter = 0
  tempCounter = 0
  public columns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      // fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Full Name',
      key: 'Full Name',
      // fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item.first_name && item.last_name
                ? `${item.first_name} ${item.last_name}`
                : item.first_name
                ? item.first_name
                : '-'}
            </strong>
          </div>
        )
      },
    },
    {
      title: 'Email',
      key: 'email',
      // width: 250,
      render: (text: any, item: any, index: number) => {
        return <div>{item.email ? item.email : '-'}</div>
      },
    },
    {
      title: 'Company Name',
      key: 'company_name',
      render: (text: any, item: any, index: number) => {
        return <div>{item.company_name ? item.company_name : '-'}</div>
      },
    },
    {
      title: 'Location',
      key: 'name',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.location_id ? (item.location_id.name ? item.location_id.name : '-') : '-'}
          </div>
        )
      },
    },
    {
      title: 'Phone',
      key: 'phone',
      render: (text: any, item: any, index: number) => {
        return <div>{item.phone ? item.phone : '-'}</div>
      },
    },
    {
      title: 'isBroker',
      key: 'isBroker',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.is_broker != null ? (
              <Tooltip placement='bottom' title='Broker Activation'>
                {
                  <Switch
                    checked={item.is_broker}
                    style={{
                      backgroundColor: item.is_broker ? PRIMARY_COLOR : '#bababa',
                    }}
                    onChange={(checked: boolean) => {
                      //call onChange Method with item
                      this.onChangeStatus(item, checked)
                    }}
                  />
                }
              </Tooltip>
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      title: 'Actions',
      // width: 130,
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Edit'>
                <Button
                  shape='circle'
                  icon={<EditOutlined />}
                  onClick={() => {
                    this.showEditModal(item)
                  }}
                />
              </Tooltip>
            </div>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Delete'>
                <Popconfirm
                  onConfirm={() => {
                    //call delete client api
                    this.deleteClient(item)
                  }}
                  title='Are you sure you want to delete client?'
                >
                  <Button shape='circle' icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        )
      },
    },
  ]

  public render() {
    const {clientData, clientDataTotal, clientLoading} = this.props
    const {searchText, clientSelectedData} = this.state

    return (
      <div>
        <div className='container'>
          <div className='card card-custom'>
            <div className='card-header'>
              <h3 className='card-title table-card-title' style={{width: '25%'}}>
                <InputSearchText
                  onChange={(value: string) => {
                    this.onSearchChange(value)
                  }}
                  placeholder='Search Client'
                  input={{
                    value: searchText,
                  }}
                />
              </h3>
              <div className='card-toolbar'>
                <InputButtonComponent
                  onClick={() => {
                    this.onCreateClient()
                  }}
                >
                  Add Client
                </InputButtonComponent>
              </div>
            </div>
            <div className='card-body'>
              <div style={{overflowX: 'auto'}}>
                <ListTable
                  rowKey={(record: {_id: any}) => record._id}
                  onChange={this.handleTableChange}
                  column={this.columns}
                  paginationConfig={{
                    total: clientDataTotal,
                    showSizeChanger: true,
                    pageSize: this.state.pagination.pageSize,
                    current: this.state.pagination.current,
                  }}
                  isLoading={clientLoading}
                  dataSource={clientData}
                  counter={this.counter}
                  tempCounter={this.tempCounter}
                  isScroll={true}
                  scrollWidth={800}
                />
                <div>
                  <CommanModal
                    show={clientSelectedData ? true : false}
                    handleClose={() => {
                      this.closeEditModal()
                    }}
                    title={'Edit Client'}
                    submitText={'Save Changes'}
                  >
                    <div>
                      {clientSelectedData && (
                        <EditClientForm
                          data={clientSelectedData}
                          onSubmit={(values: IClientFormValues, clientId: string) => {
                            this.onEditSubmit(values, clientId)
                          }}
                          handleCancel={() => {
                            this.closeEditModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  clientData: state.client.clientData,
  clientDataTotal: state.client.clientDataTotal,
  clientLoading: state.client.clientLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListClientData: (clientData: any) => {
      dispatch(client.actions.setClientData(clientData))
    },
    setListClientDataTotal: (clientDataTotal: number) => {
      dispatch(client.actions.setClientDataTotal(clientDataTotal))
    },
    setClientLoading: (clientLoading: boolean) => {
      dispatch(client.actions.setClientLoading(clientLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListClientData))
