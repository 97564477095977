/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getTallyDateData} from '../redux/TallyCRUD'
import {useDispatch} from 'react-redux'
import * as tally from '../redux/TallyRedux'
import ListTallyDateData from './ListTallyDateData'

const ListPage: FC = () => {
  const dispatch = useDispatch()

  const callTallyDateData = async () => {
    try {
      let tallyData = await getTallyDateData(0, 10)
      if (tallyData.data) {
        await dispatch(tally.actions.setTallyLoading(true))
        // once tally data set then loading will automatically off using saga middleware
        await dispatch(tally.actions.setTallyData(tallyData.data.docs))
        await dispatch(tally.actions.setTallyDataTotal(tallyData.data.totalDocs))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callTallyDateData()
  }, [])

  return (
    <div>
      <ListTallyDateData />
    </div>
  )
}

const ListWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'list',
            path: '/tally/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'list',
            path: '/tally/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.TALLY'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWrapper}
