/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

type Props = {
  onChange: (value: string) => void
  placeholder?: string
  input: {
    value?: string
  }
  disabled?: boolean
}

const InputSearchText: React.FC<Props> = ({onChange, placeholder, input, disabled = false}) => {
  return (
    <div className='table-search'>
      <input
        {...input}
        type='text'
        className='form-control form-control-lg form-control-solid pr-15'
        name='search'
        id='search'
        placeholder={placeholder}
        data-kt-search-element='input'
        onChange={(event: any) => {
          onChange(event.target.value)
        }}
        disabled={disabled}
      />
    </div>
  )
}

export {InputSearchText}

//<InputSearchText
//   onChange={(value: string) => {
//     this.onSearchChange(value)
//   }}
//   placeholder='Search Client'
//   input={{
//     value: searchText,
//   }}
// />
