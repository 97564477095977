/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import toastr from 'toastr'
type Props = {
  title: string
  type: string
  description?: string
}

const Toaster = (props: Props) => {
  toastr.options = {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: false,
    positionClass: 'toast-top-right',
    preventDuplicates: false,
    onclick: null,
    showDuration: '300',
    hideDuration: '1000',
    timeOut: '5000',
    extendedTimeOut: '1000',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
  }

  switch (props.type) {
    case 'success':
      toastr.success(props.description, props.title)
      break

    case 'error':
      toastr.error(props.description, props.title)
      break

    case 'warning':
      toastr.warning(props.description, props.title)
      break

    default:
      toastr.success(props.description, props.title)
      break
  }
}

export {Toaster}

// link
// github link:- https://github.com/CodeSeven/toastr
// Example
//  <button
//    type='button'
//    onClick={() => {
//      Toaster({
//        title: 'success',
//        type: 'success',
//        description: 'success message',
//      })
//    }}
//  >
//    {' '}
//    Show Toast
//  </button>
