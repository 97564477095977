/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {CreateWrapper} from './container/CreatePackage'
import {ListWraper} from './container/List'

export function PackagePage() {
  useEffect(() => {}, [])

  return (
    <div>
      <Switch>
        <Route path='/packages/list' exact={true} component={ListWraper} />
        <Route path='/packages/create' exact={true} component={CreateWrapper} />
        <Redirect from='/packages' exact={true} to='/packages/list' />
        <Redirect to='/packages/list' />
      </Switch>
    </div>
  )
}
