/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getStaffData} from '../redux/StaffCRUD'
import {useDispatch} from 'react-redux'
import * as staff from '../redux/StaffRedux'
import ListStaffData from './ListStaffData'

const ListPage: FC = () => {
  const dispatch = useDispatch()

  const callStaffData = async () => {
    try {
      let staffData = await getStaffData(0, 10)
      if (staffData.data) {
        await dispatch(staff.actions.setStaffLoading(true))
        // once staff data set then loading will automatically off using saga middleware
        await dispatch(staff.actions.setStaffData(staffData.data.docs))
        await dispatch(staff.actions.setStaffDataTotal(staffData.data.totalDocs))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callStaffData()
  }, [])

  return (
    <div>
      <ListStaffData />
    </div>
  )
}

const ListWraper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'list',
            path: '/staff/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'list',
            path: '/staff/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.STAFF'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWraper}
