/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {LocationPage} from '../../component/location'

const LocationWrapper: FC = () => {
  return (
    <div>
      <LocationPage />
    </div>
  )
}

export {LocationWrapper}
