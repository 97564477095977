/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {createClientData, getClientData} from '../redux/ClientCRUD'
import * as client from '../redux/ClientRedux'
import {CreateClientForm} from './CreateClientForm'
import ListClientData from './ListClientData'
import {IClientFormValues} from './CreateClientForm'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  // call initial data for Form
  const callInitialData = async () => {
    try {
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callInitialData()
  }, [])

  const callClientData = async () => {
    try {
      let clientData = await getClientData(0, 10)
      if (clientData.data) {
        await dispatch(client.actions.setClientLoading(true))
        // once client data set then loading will automatically off using saga middleware
        await dispatch(client.actions.setClientData(clientData.data.docs))
        await dispatch(client.actions.setClientDataTotal(clientData.data.totalDocs))
        history.push('/client/list')
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  const addSubmit = async (values: IClientFormValues) => {
    try {
      await createClientData(values)
      Toaster({
        type: 'success',
        title: 'CLIENT',
        description: 'Created SuccessFully',
      })
      await callClientData()
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  return (
    <div>
      <CreateClientForm onSubmit={(values: IClientFormValues) => addSubmit(values)} />
    </div>
  )
}

const CreateWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'create',
            path: '/client/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'create',
            path: '/client/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.CLIENT'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {CreateWrapper}
