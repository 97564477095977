import React from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_STAFF_URL = `${API_URL}api/v1/auth/admin/staffs/`
export const SEARCH_STAFF_URL = `${API_URL}api/v1/auth/admin/staffs/`
export const CREATE_STAFF_URL = `${API_URL}api/v1/auth/admin/staff/`
export const EDIT_STAFF_URL = `${API_URL}api/v1/auth/admin/staff/`
export const DELETE_STAFF_URL = `${API_URL}api/v1/auth/admin/staff/`
export const CHANGE_STATUS_STAFF_URL = `${API_URL}api/v1/auth/admin/staff/`

// Get staff Data
export async function getStaffData(startIndex: number, limitNumber: number) {
  try {
    let staffData = await axios.post(`${GET_STAFF_URL}${startIndex}/${limitNumber}`, {
      searchText: '',
    })
    return staffData
  } catch (e) {
    throw e
  }
}

// Search staff Data
export async function getSearchStaffData(
  startIndex: number,
  limitNumber: number,
  searchText: string
) {
  try {
    let staffData = await axios.post(`${SEARCH_STAFF_URL}${startIndex}/${limitNumber}`, {
      searchText,
    })
    return staffData
  } catch (e) {
    throw e
  }
}

// Create Staff Data
export async function createStaffData(staffObject: any) {
  try {
    let createStaff = await axios.post(`${CREATE_STAFF_URL}`, {
      ...staffObject,
    })
    return createStaff
  } catch (e) {
    throw e
  }
}

// Delete Staff Data
export async function deleteStaffData(staffId: string) {
  try {
    let deleteStaff = await axios.delete(`${DELETE_STAFF_URL}${staffId}`)
    return deleteStaff
  } catch (e) {
    throw e
  }
}

// Edit Staff Data
export async function editStaffData(staffObject: any, staffId: string) {
  try {
    let editStaffData = await axios.put(`${EDIT_STAFF_URL}`, {
      ...staffObject,
    })
    return editStaffData
  } catch (e) {
    throw e
  }
}
