/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ManagerPage} from '../../component/manager'

const ManagerWrapper: FC = () => {
  return (
    <div>
      <ManagerPage />
    </div>
  )
}

export {ManagerWrapper}
