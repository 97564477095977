/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getManagerData} from '../redux/ManagerCRUD'
import {useDispatch} from 'react-redux'
import * as manager from '../redux/ManagerRedux'
import ListManagerData from './ListManagerData'

const ListPage: FC = () => {
  const dispatch = useDispatch()

  const callManagerData = async () => {
    try {
      let locationData = await getManagerData(0, 10)
      if (locationData.data) {
        await dispatch(manager.actions.setManagerLoading(true))
        // once manager data set then loading will automatically off using saga middleware
        await dispatch(manager.actions.setManagerData(locationData.data.docs))
        await dispatch(manager.actions.setManagerDataTotal(locationData.data.totalDocs))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callManagerData()
  }, [])

  return (
    <div>
      <ListManagerData />
    </div>
  )
}

const ListWraper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'list',
            path: '/manager/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'list',
            path: '/manager/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.MANAGER'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWraper}
