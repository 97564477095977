/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {ListWrapper} from './container/DateList'

export function TallyPage() {
  useEffect(() => {}, [])

  return (
    <div>
      <Switch>
    
        <Route path='/tally/list' exact={true} 
        component={
            ListWrapper
            }
             />
    
        <Redirect from='/tally' exact={true} to='/tally/list' />
        <Redirect to='/tally/list' />
      </Switch>
    </div>
  )
}
