/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as tally from '../redux/TallyRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {UserOutlined, EditOutlined, EyeOutlined} from '@ant-design/icons'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
import {getTallyDateData} from '../redux/TallyCRUD'
import {ListWrapper} from './List'
import ViewList from './ViewList'

var moment = require('moment')

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  tallyData: any
  tallyDataTotal: number
  tallyLoading: boolean
  setListTallyData: (tallyData: any) => void
  setListTallyDataTotal: (tallyDataTotal: number) => void
  setTallyLoading: (tallyLoading: boolean) => void
  history?: History<LocationState>
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  tallySelectedData: any
}

type PathParamsType = {
  param1: string
}
class ListTallyDateData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      tallySelectedData: null,
    }
  }

  public dateFormat(date: any) {
    var oldDate = new Date(date),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.format('LL')
    return newDate
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListTallyDateData(pagination)
    )
  }

  public getListTallyDateData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listTallyDateSearchData(start, end)
        } else {
          this.listTallyDateData(start, end)
        }
      }
    )
  }

  public listTallyDateData = async (start: number, end: any) => {
    const {setTallyLoading} = this.props
    try {
      const {setListTallyData, setListTallyDataTotal} = this.props
      await setTallyLoading(true)
      let tallyData = await getTallyDateData(start, end)
      if (tallyData.data) {
        await setListTallyData(tallyData.data.docs)
        await setListTallyDataTotal(tallyData.data.totalDocs)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setTallyLoading(false)
    }
  }

  public listTallyDateSearchData = async (start: number, end: any) => {
    const {setTallyLoading} = this.props
    try {
      const {setListTallyData, setListTallyDataTotal} = this.props
      const {searchText} = this.state

      await setTallyLoading(true)
      let tallyData = await getTallyDateData(start, end)
      if (tallyData.data) {
        await setListTallyData(tallyData.data.docs)
        await setListTallyDataTotal(tallyData.data.totalDocs)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setTallyLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listTallyDateSearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public showEditModal = async (item: any) => {
    this.setState({
      tallySelectedData: item,
    })
  }

  public closeEditModal = async () => {
    this.setState({
      tallySelectedData: null,
    })
    this.listTallyDateData(this.state.startIndex,this.state.limitNumber)
  }

  counter = 0
  tempCounter = 0
  public columns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Date',
      key: 'date',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item.date ? this.dateFormat(item.date)
                : '-'}
            </strong>
          </div>
        )
      },
    },
    {
      title: 'Total Stones',
      key: 'total_stones',
      render: (text: any, item: any, index: number) => {
        return <div>{item.total_stones ? item.total_stones : '0'}</div>
      },
    },
    {
        title: 'Total Certified Stones',
        key: 'total_certified_stones',
        render: (text: any, item: any, index: number) => {
          return <div>{item.total_certified_stones ? item.total_certified_stones : '0'}</div>
        },
      },
      {
        title: 'Total Non Certified Stones',
        key: 'total_non_certified_stones',
        render: (text: any, item: any, index: number) => {
          return <div>{item.total_non_certified_stones ? item.total_non_certified_stones : '0'}</div>
        },
      },
      {
        title: 'Total Star Melee Stones',
        key: 'total_star_melee_stones',
        render: (text: any, item: any, index: number) => {
          return <div>{item.total_star_melee_stones ? item.total_star_melee_stones : '0'}</div>
        },
      },
    {
      title: 'Actions',
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='View'>
                <Button
                  shape='circle'
                  icon={<EyeOutlined />}
                  onClick={() => {
                    this.showEditModal(item)
                  }}
                />
              </Tooltip>
            </div>
          </div>
        )
      },
    },
  ]

  public render() {
    const {tallyData, tallyDataTotal, tallyLoading} = this.props
    const {searchText, tallySelectedData} = this.state

    return (
      <div>
        <div className='container'>
            <div className='card-body'>
              <div style={{overflowX: 'auto'}}>
                <ListTable
                  rowKey={(record: {_id: any}) => record._id}
                  onChange={this.handleTableChange}
                  column={this.columns}
                  paginationConfig={{
                    total: tallyDataTotal,
                    showSizeChanger: true,
                    pageSize: this.state.pagination.pageSize,
                    current: this.state.pagination.current,
                  }}
                  isLoading={tallyLoading}
                  dataSource={tallyData}
                  counter={this.counter}
                  tempCounter={this.tempCounter}
                />
                <div>
                <CommanModal
                    show={tallySelectedData ? true : false}
                    handleClose={() => {
                      this.closeEditModal()
                    }}
                    increaseModalWidth = {true}
                    title={'View Stones'}
                    submitText={'Save Changes'}
                  >
                    <div>
                      {tallySelectedData && (
                        <ViewList
                          data={tallySelectedData}
                          onSubmit={() => {}}
                          handleCancel={() => {
                            this.closeEditModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  tallyData: state.tally.tallyData,
  tallyDataTotal: state.tally.tallyDataTotal,
  tallyLoading: state.tally.tallyLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListTallyData: (tallyData: any) => {
      dispatch(tally.actions.setTallyData(tallyData))
    },
    setListTallyDataTotal: (tallyDataTotal: number) => {
      dispatch(tally.actions.setTallyDataTotal(tallyDataTotal))
    },
    setTallyLoading: (tallyLoading: boolean) => {
      dispatch(tally.actions.setTallyLoading(tallyLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListTallyDateData))
