import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {StaffModal} from '../models/StaffModal'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setStaffData: 'SET_STAFF_DATA',
  setStaffDataTotal: 'SET_STAFF_DATA_TOTAL',
  setStaffLoading: 'SET_STAFF_LOADER',
}

const initialClientState: IClientState = {
  staffData: undefined,
  staffDataTotal: undefined,
  staffLoading: false,
}

export interface IClientState {
  staffData?: StaffModal
  staffDataTotal?: number
  staffLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'staff', whitelist: ['staffData', 'staffDataTotal']},
  (state: IClientState = initialClientState, action: ActionWithPayload<IClientState>) => {
    switch (action.type) {
      case actionTypes.setStaffData: {
        const staffData = action.payload?.staffData
        return {...state, staffData}
      }

      case actionTypes.setStaffDataTotal: {
        const staffDataTotal = action.payload?.staffDataTotal
        return {...state, staffDataTotal}
      }

      case actionTypes.setStaffLoading: {
        const staffLoading = action.payload?.staffLoading
        return {...state, staffLoading}
      }

      default:
        return state
    }
  }
)

export const actions = {
  setStaffData: (staffData: Array<StaffModal>) => ({
    type: actionTypes.setStaffData,
    payload: {staffData: staffData},
  }),
  setStaffDataTotal: (staffDataTotal: number) => ({
    type: actionTypes.setStaffDataTotal,
    payload: {staffDataTotal},
  }),
  setStaffLoading: (staffLoading: boolean) => ({
    type: actionTypes.setStaffLoading,
    payload: {staffLoading},
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.setStaffData, function* loginSaga() {
    yield put(actions.setStaffLoading(false))
  })

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser())
  // })

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const {data: user} = yield getUserByToken()
  //   yield put(actions.fulfillUser(user))
  // })
}
