import React from 'react'
import {Helmet} from 'react-helmet'

type Props = {
  title: string
  link: any
  meta: any
}

const CommanPage: React.FC<Props> = ({title, link, meta, children}) => {
  return (
    <>
      <Helmet title={title} link={link} meta={meta} />
      {children}
    </>
  )
}

CommanPage.defaultProps = {
  title: '',
  link: [],
  meta: [],
}

export default CommanPage
