/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as location from '../redux/LocationRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {UserOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
import {
  deleteLocationData,
  editLocationData,
  getLocationData,
  getSearchLocationData,
} from '../redux/LocationCRUD'
import {EditLocationForm} from './EditLocationForm'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  locationData: any
  locationDataTotal: number
  locationLoading: boolean
  setListLocationData: (locationData: any) => void
  setListLocationDataTotal: (locationDataTotal: number) => void
  setLocationLoading: (locationLoading: boolean) => void
  history?: History<LocationState>
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  locationSelectedData: any
}

type PathParamsType = {
  param1: string
}
class ListLocationData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      locationSelectedData: null,
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListLocationData(pagination)
    )
  }

  public getListLocationData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listLocationSearchData(start, end)
        } else {
          this.listLocationData(start, end)
        }
      }
    )
  }

  public listLocationData = async (start: number, end: any) => {
    const {setLocationLoading} = this.props
    try {
      const {setListLocationData, setListLocationDataTotal} = this.props
      await setLocationLoading(true)
      let locationData = await getLocationData(start, end)
      if (locationData.data) {
        await setListLocationData(locationData.data.docs)
        await setListLocationDataTotal(locationData.data.totalDocs)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setLocationLoading(false)
    }
  }

  public listLocationSearchData = async (start: number, end: any) => {
    const {setLocationLoading} = this.props
    try {
      const {setListLocationData, setListLocationDataTotal} = this.props
      const {searchText} = this.state

      await setLocationLoading(true)
      let locationData = await getSearchLocationData(start, end, searchText)
      if (locationData.data) {
        await setListLocationData(locationData.data.docs)
        await setListLocationDataTotal(locationData.data.totalDocs)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setLocationLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listLocationSearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public onCreateLocation = () => {
    const {history} = this.props
    history.push('/location/create')
  }

  public deleteLocation = async (item: any) => {
    const {setLocationLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setLocationLoading(true)
      await deleteLocationData(item._id)

      Toaster({
        type: 'success',
        title: 'LOCATION',
        description: 'Deleted SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listLocationSearchData(startIndex, limitNumber)
        return
      }
      this.listLocationData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setLocationLoading(false)
    }
  }

  public onChangeStatus = async (item: any, checked: boolean) => {
    const {setLocationLoading} = this.props
    // try {
    //   const {startIndex, limitNumber, searchText} = this.state

    //   await setLocationLoading(true)
    //   await changeStatusLocationData(item.id, checked)

    //   Toaster({
    //     type: 'success',
    //     title: 'LOCATION',
    //     description: 'Status changed successFully',
    //   })

    //   if (searchText != null && searchText !== '') {
    //     this.listLocationSearchData(startIndex, limitNumber)
    //     return
    //   }
    //   this.listLocationData(startIndex, limitNumber)
    // } catch (e) {
    //   console.log('!!!!Error', e)
    // } finally {
    //   await setLocationLoading(false)
    // }
  }

  public onEditSubmit = async (values: any, locationId: string) => {
    const {setLocationLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setLocationLoading(true)
      await editLocationData(values, locationId)

      Toaster({
        type: 'success',
        title: 'LOCATION',
        description: 'Edited SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listLocationSearchData(startIndex, limitNumber)
        return
      }
      this.listLocationData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setLocationLoading(false)
    }
  }

  public showEditModal = async (item: any) => {
    this.setState({
      locationSelectedData: item,
    })
  }

  public closeEditModal = async () => {
    this.setState({
      locationSelectedData: null,
    })
  }

  counter = 0
  tempCounter = 0
  public columns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Name',
      key: 'name',
      fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.name ? item.name : '-'}</strong>
          </div>
        )
      },
    },
    {
      title: 'Area',
      key: 'area',
      width: 250,
      render: (text: any, item: any, index: number) => {
        return <div>{item.area ? item.area : '-'}</div>
      },
    },
    {
      title: 'City',
      key: 'city',
      width: 250,
      render: (text: any, item: any, index: number) => {
        return <div>{item.city ? item.city : '-'}</div>
      },
    },
    {
      title: 'isActive',
      key: 'isActive',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.is_active != null ? (
              <Tooltip placement='bottom' title='Category Activation'>
                {
                  <Switch
                    checked={item.is_active}
                    style={{
                      backgroundColor: item.is_active ? PRIMARY_COLOR : '#bababa',
                    }}
                    onChange={(checked: boolean) => {
                      //call onChange Method with item
                      this.onChangeStatus(item, checked)
                    }}
                  />
                }
              </Tooltip>
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      title: 'Actions',
      width: 130,
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Edit'>
                <Button
                  shape='circle'
                  icon={<EditOutlined />}
                  onClick={() => {
                    this.showEditModal(item)
                  }}
                />
              </Tooltip>
            </div>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Delete'>
                <Popconfirm
                  onConfirm={() => {
                    //call delete location api
                    this.deleteLocation(item)
                  }}
                  title='Are you sure you want to delete location?'
                >
                  <Button shape='circle' icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        )
      },
    },
  ]

  public render() {
    const {locationData, locationDataTotal, locationLoading} = this.props
    const {searchText, locationSelectedData} = this.state

    return (
      <div>
        <div className='container'>
          <div className='card card-custom'>
            <div className='card-header'>
              <h3 className='card-title table-card-title' style={{width: '25%'}}>
                <InputSearchText
                  onChange={(value: string) => {
                    this.onSearchChange(value)
                  }}
                  placeholder='Search Location'
                  input={{
                    value: searchText,
                  }}
                />
              </h3>
              <div className='card-toolbar'>
                <InputButtonComponent
                  onClick={() => {
                    this.onCreateLocation()
                  }}
                >
                  Add Location
                </InputButtonComponent>
              </div>
            </div>
            <div className='card-body'>
              <div style={{overflowX: 'auto'}}>
                <ListTable
                  rowKey={(record: {id: any}) => record.id}
                  onChange={this.handleTableChange}
                  column={this.columns}
                  paginationConfig={{
                    total: locationDataTotal,
                    showSizeChanger: true,
                    pageSize: this.state.pagination.pageSize,
                    current: this.state.pagination.current,
                  }}
                  isLoading={locationLoading}
                  dataSource={locationData}
                  counter={this.counter}
                  tempCounter={this.tempCounter}
                />
                <div>
                  <CommanModal
                    show={locationSelectedData ? true : false}
                    handleClose={() => {
                      this.closeEditModal()
                    }}
                    title={'Edit Location'}
                    submitText={'Save Changes'}
                  >
                    <div>
                      {locationSelectedData && (
                        <EditLocationForm
                          data={locationSelectedData}
                          onSubmit={(values: any, locationId: string) => {
                            this.onEditSubmit(values, locationId)
                          }}
                          handleCancel={() => {
                            this.closeEditModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  locationData: state.location.locationData,
  locationDataTotal: state.location.locationDataTotal,
  locationLoading: state.location.locationLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListLocationData: (locationData: any) => {
      dispatch(location.actions.setLocationData(locationData))
    },
    setListLocationDataTotal: (locationDataTotal: number) => {
      dispatch(location.actions.setLocationDataTotal(locationDataTotal))
    },
    setLocationLoading: (locationLoading: boolean) => {
      dispatch(location.actions.setLocationLoading(locationLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListLocationData))
