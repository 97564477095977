import React, {Component} from 'react'
import {InputSingleFileUpload} from '../../../modules/comman/formComponents/InputSingleFileUpload'
import {Alert} from 'antd'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {Button} from 'antd'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {linkVideoFileNameToStock} from '../../inventory/redux/InventoryCRUD'
import {Spin} from 'antd'

interface IAddClientProps {
  data?: any
  handleCancel?: any
}

class VideoUploadModal extends React.Component<IAddClientProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      viewFileUploadModal: false,
      uploadMedia: [],
      video_url: '',
      copied: false,
      loading: false,
      isVideoLinked: false,
    }
  }
  viewFileUploadModalRef: {show: () => void} | undefined

  public uploadMedia = (fileName: string, FileData: Array<any>, customFileData: Object) => {
    if (fileName && FileData.length >= 1) {
      this.setState({
        uploadMedia: FileData,
      })
      console.log('!!!!!!!!!!!uploadMedia')
    }
    //close modal popup
    this.setState({
      viewFileUploadModal: false,
      isVideoLinked: false,
    })
  }

  public showToaster = () => {
    Toaster({
      type: 'success',
      title: 'URL',
      description: 'URL Copied SuccessFully',
    })
  }

  //   public openNotificationWithIcon = (type: any) => {
  //     api[type]({
  //       message: 'Notification Title',
  //       description:
  //         'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
  //     });
  //     };

  render() {
    return (
      <>
        <div>
          <InputSingleFileUpload
            ref={(e: any) => {
              this.viewFileUploadModalRef = e
            }}
            //   buttonLabel={'Choose Provider Image'}
            onBlur={() => {}}
            error={() => {}}
            touched={() => {}}
            //   required={false}
            //   label='Item Image'
            name='video_url'
            input={{
              id: 'video',
              name: 'video',
              action: `${
                process.env.REACT_APP_API_URL || 'https://api.scimpex.co/'
              }file/upload/stock/video`,
              headers: {
                authorization: 'authorized-text',
              },
              accept: '.html',
              // acceptMimeTypes: ['video/quicktime', '.mp4'],
              // mediaType: '.mov',
              // mediaModuleType: '.mov',
            }}
            onUpload={(fileName: string, FileData: Array<any>, customFileData: Object) => {
              this.uploadMedia(fileName, FileData, customFileData)
            }}
          />
        </div>
        {this.state.loading && (
          <div style={{margin: '0 auto', textAlign: 'center', marginTop: '80px'}}>
            <Spin />
          </div>
        )}
        {this.state.uploadMedia &&
          this.state.uploadMedia[0] &&
          this.state.uploadMedia.length >= 1 &&
          !this.state.isVideoLinked && (
            //   <CopyToClipboard
            //     text={`${process.env.REACT_APP_API_URL || 'https://api.scimpex.co/'}stock/${this.state.uploadMedia[0].name}`}
            //     onCopy={() => this.setState({copied: true})}
            //   >
            //     <span>
            //       <Alert
            //         message='`${process.env.REACT_APP_API_URL || 'https://api.scimpex.co/'}stock/${this.state.uploadMedia[0].name}`'
            //         type='success'
            //       />
            //     </span>
            //   </CopyToClipboard>
            <div style={{margin: '0 auto', textAlign: 'center', marginTop: '70px'}}>
              <h4 style={{marginBottom: '10px', fontWeight: 'bold'}}>
                PLEASE LINK UPLOADED FILE TO STONE
              </h4>
              <Button
                type='primary'
                onClick={async () => {
                  try {
                    this.setState({
                      loading: true,
                    })
                    const inventoryData = await linkVideoFileNameToStock(
                      this.state.uploadMedia[0].name,
                      this.state.uploadMedia[0].name.split('.')[0]
                    )
                    this.setState({
                      isVideoLinked: true,
                      loading: false,
                    })
                  } catch (error: any) {
                    this.setState({
                      loading: false,
                    })
                  }
                }}
              >
                Link to Stone
              </Button>
            </div>
          )}
        {this.state.uploadMedia &&
          this.state.uploadMedia[0] &&
          this.state.uploadMedia.length >= 1 &&
          this.state.isVideoLinked && (
            //   <CopyToClipboard
            //     text={`${process.env.REACT_APP_API_URL || 'https://api.scimpex.co/'}stock/${this.state.uploadMedia[0].name}`}
            //     onCopy={() => this.setState({copied: true})}
            //   >
            //     <span>
            //       <Alert
            //         message='`${process.env.REACT_APP_API_URL || 'https://api.scimpex.co/'}stock/${this.state.uploadMedia[0].name}`'
            //         type='success'
            //       />
            //     </span>
            //   </CopyToClipboard>
            <div style={{margin: '10%', alignItems: 'center'}}>
              <Alert
                message='Url Successfully Generated'
                description={`https://panel.scimpex.co/stock/${
                  this.state.uploadMedia[0].name.split('.')[0]
                }`}
                type='success'
                showIcon
              />
              <CopyToClipboard
                text={`https://panel.scimpex.co/stock/${
                  this.state.uploadMedia[0].name.split('.')[0]
                }`}
                onCopy={() => {
                  this.setState({
                    copied: true,
                  })
                }}
              >
                {/* single child to which event is applied*/}

                <div className='copy-area' style={{margin: '10px'}}>
                  {/*button to copy text */}
                  <Button variant='contained' onClick={this.showToaster}>
                    Click to Copy
                  </Button>
                </div>
              </CopyToClipboard>
            </div>
          )}
      </>
    )
  }
}

export default VideoUploadModal
