/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getInventoryData, getTwinInventoryData} from '../redux/InventoryCRUD'
import {useDispatch, useSelector} from 'react-redux'
import * as inventory from '../redux/InventoryRedux'
import ListTwinInventoryData from './ListTwinInventoryData'

const ListPage: FC = () => {
  const dispatch = useDispatch()

  const [searchPaginationRequest, setSearchPaginationRequest] = useState({skip: 0, limit: 10})

  const inventoryState = useSelector((state: any) => state.inventory)

  const callInventoryData = async () => {
    try {
      let twinInventoryData = await getTwinInventoryData(
        searchPaginationRequest.skip,
        searchPaginationRequest.limit
      )
      if (twinInventoryData.data) {
        await dispatch(inventory.actions.setTwinInventoryLoading(true))
        // once inventory data set then loading will automatically off using saga middleware
        await dispatch(inventory.actions.setTwinInventoryData(twinInventoryData.data, true))
        // await dispatch(inventory.actions.setTwinInventoryDataTotal(twinInventoryData.data.total))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  /**
   * loadMoreData function used to call to load more data
   */
  const loadMoreData = async () => {
    const newSearchPaginationRequest = searchPaginationRequest
    newSearchPaginationRequest.skip = searchPaginationRequest.skip + 10
    newSearchPaginationRequest.limit = searchPaginationRequest.limit
    setSearchPaginationRequest((oldData) => newSearchPaginationRequest)
    console.log('newSearchPaginationRequest', newSearchPaginationRequest)

    try {
      let twinInventoryData = await getTwinInventoryData(
        searchPaginationRequest.skip,
        searchPaginationRequest.limit
      )
      if (twinInventoryData.data) {
        // once inventory data set then loading will automatically off using saga middleware
        await dispatch(inventory.actions.setMoreTwinInventoryData(twinInventoryData.data, true))
        // await dispatch(inventory.actions.setTwinInventoryDataTotal(twinInventoryData.data.total))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
    //call respected service to get the data end
  }

  useEffect(() => {
    callInventoryData()
  }, [])

  return (
    <div>
      <ListTwinInventoryData
        loadMoreData={loadMoreData}
        data={inventoryState.twinInventoryData.data}
      />
    </div>
  )
}

const ListTwinWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'List',
            path: '/inventory/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/inventory/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.INVENTORY'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListTwinWrapper}
