import * as React from 'react'
import ReactPlayer from 'react-player'
import {RouteComponentProps} from 'react-router'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import * as inventory from '../redux/InventoryRedux'
import {History, LocationState} from 'history'
import {Tag} from 'antd'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {Toaster} from '../../../modules/comman/components/Toaster'
import {
  returnPriceInThousandFormat,
  returnPriceInThousandFormatWithCurrencySign,
  returnStockId,
  returnStockString,
} from '../../../comman/helper'
import {config} from '../../../comman/constants'
import {KpiCardComponent} from '../../../modules/comman/components/KpiCardComponent'
import { Row } from 'antd'
import { Col } from 'antd'

interface IViewInventoryProps {
  singleInventoryData?: any
  inventoryLoading: boolean
  inventoryCartData?: any
  setInventoryCartData: (inventoryCartData: any) => void
  setInventoryLoading: (inventoryLoading: boolean) => void
  isPublic?: boolean
  inventoryId?: string
}

interface IViewInventoryState {
  data: any
  isLoading: boolean
  roleData: any
}

type PathParamsType = {
  id: string
}

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'

class ViewInventoryForm extends React.Component<
  RouteComponentProps<PathParamsType> & IViewInventoryProps,
  IViewInventoryState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      roleData: [],
    }
  }

  unusedKey = [
    '__v',
    '_deleted',
    '_id',
    'updatedAt',
    'createdAt',
    'is_active',
    'diamond_image',
    'diamond_video',
    'status',
    'video',
  ]
  priceItemKeys = ['final_price', 'rap_per_caret']
  upperCaseKey = [
    'stock_id',
    'color',
    'cut',
    'shape',
    'clarity',
    'polish',
    'symmetry',
    'availability',
    'brand',
    'culet_condition',
    'diamond_type',
    'girdle_condition',
    'treatment',
    'fluorescence_intensity',
  ]

  componentDidMount = () => {
    const {match} = this.props
  }

  public addSelectedItemsToCart = async () => {
    const {singleInventoryData, inventoryCartData, setInventoryLoading, setInventoryCartData} =
      this.props
    try {
      await setInventoryLoading(true)

      let inventoryFilterIndex = -1
      if (inventoryCartData) {
        inventoryFilterIndex = inventoryCartData.findIndex(
          (item: any) => item.stock_id === singleInventoryData.stock_id
        )
      }
      if (
        singleInventoryData &&
        Object.keys(singleInventoryData).length >= 1 &&
        inventoryFilterIndex === -1
      ) {
        let tempInvItem: any = [
          {
            _id: singleInventoryData._id,
            stock_id: singleInventoryData.stock_id,
            stock_data: singleInventoryData,
          },
        ]
        await setInventoryCartData([].concat(inventoryCartData || [], tempInvItem))

        Toaster({
          type: 'success',
          title: 'INVENTORY',
          description: 'Item Added to cart',
        })
      } else {
        Toaster({
          type: 'error',
          title: 'INVENTORY',
          description: 'Item is already added to the cart',
        })
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setInventoryLoading(false)
    }
  }

  public render() {
    const {singleInventoryData, inventoryId} = this.props
    console.log(
      singleInventoryData &&
        singleInventoryData.video_file_name &&
        singleInventoryData.video_file_name.split('.')
    )
    return (
      <div style={{width: '100%', display: 'inline-block'}}>
        <Row style={{width: '100%', marginBottom: '10px'}}>
          <Col lg={12} md={24} sm={24}>
            {inventoryId && singleInventoryData && singleInventoryData.video_link ? (
              <div style={{}}>
                <ReactPlayer
                  playing={true}
                  loop={true}
                  width={'100%'}
                  style={{width: '100%'}}
                  controls={true}
                  url={`${singleInventoryData.video_link}`}
                />
              </div>
            ) : inventoryId &&
              singleInventoryData &&
              singleInventoryData.video_file_name &&
              singleInventoryData.video_file_name.split('.')[
                singleInventoryData.video_file_name.split('.').length - 1
              ] === 'mp4' ? (
              <div style={{}}>
                <ReactPlayer
                  playing={true}
                  loop={true}
                  controls={true}
                  url={`${API_URL}stock/${singleInventoryData.video_file_name}`}
                />
              </div>
            ) : inventoryId &&
              singleInventoryData &&
              singleInventoryData.video_file_name &&
              singleInventoryData.video_file_name.split('.')[
                singleInventoryData.video_file_name.split('.').length - 1
              ] === 'html' ? (
              // <div>
              //   <iframe
              //     style={{width: '100%', height: '200px'}}
              //     src={`${API_URL}stock/video/${singleInventoryData.video_file_name}`}
              //   />
              // </div>
              // <div style={{marginBottom: '20px'}}>
              <div style={{ marginBottom: "10px" }}>
                <object
                  data={`${API_URL}stock/video/${singleInventoryData.video_file_name}`}
                  width='100%'
                  height='500'
                  type='text/html'
                >
                  Alternative Content
                </object>
              </div>
            ) : null}
          </Col>
          <Col lg={12} md={24} sm={24}>
            {inventoryId && singleInventoryData && singleInventoryData.image_file_name && (
              <div style={{width: '100%', height: '500px', marginBottom: "10px"}}>
                <img
                  style={{width: '100%', height: '100%'}}
                  src={`${API_URL}stock/image/${singleInventoryData.image_file_name}`}
                  alt='Diamond'
                />
              </div>
            )}
          </Col>
        </Row>
        {/* drc-box */}
        {singleInventoryData && (
          <div className='row g-5 g-xl-8'>
            {singleInventoryData.stock_id && (
              <KpiCardComponent
                icon='/media/icons/duotune/art/art002.svg'
                title='Stock Id'
                value={returnStockId(singleInventoryData.stock_id)}
                // path='/buy-request/list'
                background='white'
              />
            )}
            {singleInventoryData.status && !this.props.isPublic && (
              <KpiCardComponent
                icon='/media/icons/duotune/art/art002.svg'
                title='Status'
                value={singleInventoryData.status}
                // path='/buy-request/list'
                background='white'
              />
            )}
            {singleInventoryData.final_price && (
              <KpiCardComponent
                icon='/media/icons/duotune/art/art002.svg'
                title='Price'
                value={returnPriceInThousandFormatWithCurrencySign(singleInventoryData.final_price)}
                // path='/buy-request/list'
                background='white'
              />
            )}
            <KpiCardComponent
              icon='/media/icons/duotune/art/art002.svg'
              title='Stock Details'
              value={returnStockString(singleInventoryData)}
              // path='/buy-request/list'
              background='white'
            />
          </div>
        )}
        <div className='card card-custom'>
          {singleInventoryData ? (
            <>
              <div className='card-header'>
                <h3 className='card-title table-card-title' style={{width: '25%'}}>
                  {`Inventory Details (${
                    singleInventoryData && singleInventoryData.stock_id
                      ? returnStockId(singleInventoryData.stock_id)
                      : ''
                  })`}
                  {singleInventoryData && singleInventoryData.status && !this.props.isPublic && (
                    <>
                      <div className='ml-3'>
                        <Tag
                          color={
                            config.statusTagColor[singleInventoryData.status]
                              ? config.statusTagColor[singleInventoryData.status]
                              : 'default'
                          }
                        >
                          {singleInventoryData.status}
                        </Tag>
                      </div>
                    </>
                  )}
                </h3>
              </div>
              <div className='card-body'>
                <div className='row'>
                  {/* <div className='col-md-2 font-weight-bold text-muted p-3'>
                    <label>{'Image:'}</label>
                  </div>
                  <div className='col-md-4'>
                    {singleInventoryData.diamond_image ? (
                      <a href={singleInventoryData.diamond_image} target='_blank'>
                        {singleInventoryData.diamond_image}
                      </a>
                    ) : (
                      <label>{'No Image Available'}</label>
                    )}
                  </div> */}

                  {/* <div className='col-md-2 font-weight-bold text-muted p-3'>
                    <label>{'Video:'}</label>
                  </div>
                  <div className='col-md-4'>
                    {singleInventoryData.video_link ? (
                      <a href={singleInventoryData.video_link} target='_blank'>
                        {singleInventoryData.video_link}
                      </a>
                    ) : (
                      <label>{'No Video Available'}</label>
                    )}
                  </div> */}
                </div>
                {singleInventoryData && (
                  <div className='row'>
                    {[
                      '_id',
                      'status',
                      'type',
                      'old_stock_id',
                      'stock_id',
                      'rapnet',
                      'shape',
                      'weight',
                      'color',
                      'clarity',
                      'rap',
                      'price_per_caret',
                      'total_price',
                      'cut',
                      'polish',
                      'symmetry',
                      'fluorescence',
                      'measurement',
                      'table',
                      'depth',
                      'lab',
                      'cert_number',
                      'discount',
                      'no_bgm',
                      'location',
                      'video_link',
                      'item',
                      'no_of_stones',
                      'is_active',
                      'created_at',
                      'updated_at',
                      '_deleted',
                      'packages',
                      '__v',
                      'video_file_name',
                      'memos',
                      'total_used_stones_package',
                      'total_used_weight_package',
                      'image_file_name',
                    ].map((itemLabel: any, index: number) =>
                      !this.unusedKey.includes(itemLabel) &&
                      singleInventoryData[itemLabel] &&
                      typeof singleInventoryData[itemLabel] === 'string' ? (
                        <>
                          <div className='col-md-2 font-weight-bold text-muted p-3' key={index}>
                            <label>{`${itemLabel.replace(/_/g, ' ').toUpperCase()}:`}</label>
                          </div>
                          <div className='col-md-4 p-3'>
                            <label>
                              {this.priceItemKeys.includes(itemLabel)
                                ? returnPriceInThousandFormatWithCurrencySign(
                                    singleInventoryData[itemLabel]
                                  )
                                : this.upperCaseKey.includes(itemLabel)
                                ? singleInventoryData[itemLabel].toUpperCase()
                                : singleInventoryData[itemLabel]}
                            </label>
                          </div>
                        </>
                      ) : null
                    )}
                  </div>
                )}
              </div>
              {singleInventoryData &&
              singleInventoryData.status &&
              singleInventoryData.status === 'AVAILABLE' &&
              !this.props.isPublic ? (
                <div className='card-footer'>
                  <div className='mr-5'>
                    <InputButtonComponent
                      onClick={() => {
                        this.addSelectedItemsToCart()
                      }}
                    >
                      Add to Cart
                    </InputButtonComponent>
                  </div>
                </div>
              ) : null}
            </>
          ) : this.props.inventoryLoading ? (
            <div className='d-flex justify-content-center'>
              <div className='spinner-border'></div>
            </div>
          ) : (
            <div className='d-flex justify-content-center'>
              <div> No Stock Found </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  inventoryLoading: state.inventory.inventoryLoading,
  inventoryCartData: state.inventory.inventoryCartData,
  singleInventoryData: state.inventory.singleInventoryData,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setInventoryLoading: (inventoryLoading: boolean) => {
      dispatch(inventory.actions.setInventoryLoading(inventoryLoading))
    },

    setInventoryCartData: (inventoryCartData: any) => {
      dispatch(inventory.actions.setInventoryCartData(inventoryCartData))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewInventoryForm))
