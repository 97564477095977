/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as staff from '../redux/StaffRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {UserOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
import {deleteStaffData, getSearchStaffData, getStaffData, editStaffData} from '../redux/StaffCRUD'
import {EditStaffForm} from './EditStaffForm'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  staffData: any
  staffDataTotal: number
  staffLoading: boolean
  setListStaffData: (staffData: any) => void
  setListStaffDataTotal: (staffDataTotal: number) => void
  setStaffLoading: (staffLoading: boolean) => void
  history?: History<LocationState>
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  staffSelectedData: any
}

type PathParamsType = {
  param1: string
}
class ListStaffData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      staffSelectedData: null,
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListStaffData(pagination)
    )
  }

  public getListStaffData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listStaffSearchData(start, end)
        } else {
          this.listStaffData(start, end)
        }
      }
    )
  }

  public listStaffData = async (start: number, end: any) => {
    const {setStaffLoading} = this.props
    try {
      const {setListStaffData, setListStaffDataTotal} = this.props
      await setStaffLoading(true)
      let staffData = await getStaffData(start, end)
      if (staffData.data) {
        await setListStaffData(staffData.data.docs)
        await setListStaffDataTotal(staffData.data.totalDocs)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setStaffLoading(false)
    }
  }

  public listStaffSearchData = async (start: number, end: any) => {
    const {setStaffLoading} = this.props
    try {
      const {setListStaffData, setListStaffDataTotal} = this.props
      const {searchText} = this.state

      await setStaffLoading(true)
      let staffData = await getSearchStaffData(start, end, searchText)
      if (staffData.data) {
        await setListStaffData(staffData.data.docs)
        await setListStaffDataTotal(staffData.data.totalDocs)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setStaffLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listStaffSearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public onCreateStaff = () => {
    const {history} = this.props
    history.push('/staff/create')
  }

  public deleteStaff = async (item: any) => {
    const {setStaffLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setStaffLoading(true)
      await deleteStaffData(item._id)

      Toaster({
        type: 'success',
        title: 'STAFF',
        description: 'Deleted SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listStaffSearchData(startIndex, limitNumber)
        return
      }
      this.listStaffData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setStaffLoading(false)
    }
  }

  public onChangeStatus = async (item: any, checked: boolean) => {
    const {setStaffLoading} = this.props
    // try {
    //   const {startIndex, limitNumber, searchText} = this.state

    //   await setStaffLoading(true)
    //   await changeStatusLocationData(item.id, checked)

    //   Toaster({
    //     type: 'success',
    //     title: 'STAFF',
    //     description: 'Status changed successFully',
    //   })

    //   if (searchText != null && searchText !== '') {
    //     this.listStaffSearchData(startIndex, limitNumber)
    //     return
    //   }
    //   this.listStaffData(startIndex, limitNumber)
    // } catch (e) {
    //   console.log('!!!!Error', e)
    // } finally {
    //   await setStaffLoading(false)
    // }
  }

  public onEditSubmit = async (values: any, clientId: string) => {
    const {setStaffLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setStaffLoading(true)
      await editStaffData(values, clientId)

      Toaster({
        type: 'success',
        title: 'STAFF',
        description: 'Edited SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listStaffSearchData(startIndex, limitNumber)
        return
      }
      this.listStaffData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setStaffLoading(false)
    }
  }

  public showEditModal = async (item: any) => {
    this.setState({
      staffSelectedData: item,
    })
  }

  public closeEditModal = async () => {
    this.setState({
      staffSelectedData: null,
    })
  }

  counter = 0
  tempCounter = 0
  public columns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Name',
      key: 'name',
      fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {' '}
              {item.first_name && item.last_name
                ? `${item.first_name} ${item.last_name}`
                : item.first_name
                ? item.first_name
                : '-'}
            </strong>
          </div>
        )
      },
    },
    {
      title: 'Location',
      key: 'locations',
      render: (text: any, item: any, index: number) => {
        let locationArray: any = []
        if (item && item.locations && !!item.locations.length) {
          let tempId: any
          item.locations.map((id: any) => {
            if (id && id.location_id && id.location_id.name) {
              tempId = id.location_id.name
              locationArray.push(tempId)
            }
          })
        }
        return <div>{locationArray ? locationArray.join(' , ') : '-'}</div>
      },
    },
    {
      title: 'Email',
      key: 'email',
      width: 250,
      render: (text: any, item: any, index: number) => {
        return <div>{item.email ? item.email : '-'}</div>
      },
    },
    {
      title: 'Phone',
      key: 'phone',
      render: (text: any, item: any, index: number) => {
        return <div>{item.phone ? item.phone : '-'}</div>
      },
    },
    // {
    //   title: 'isActive',
    //   key: 'isActive',
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         {item.is_active != null ? (
    //           <Tooltip placement='bottom' title='Category Activation'>
    //             {
    //               <Switch
    //                 checked={item.is_active}
    //                 style={{
    //                   backgroundColor: item.is_active ? PRIMARY_COLOR : '#bababa',
    //                 }}
    //                 onChange={(checked: boolean) => {
    //                   //call onChange Method with item
    //                   this.onChangeStatus(item, checked)
    //                 }}
    //               />
    //             }
    //           </Tooltip>
    //         ) : (
    //           '-'
    //         )}
    //       </div>
    //     )
    //   },
    // },
    {
      title: 'Actions',
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Edit'>
                <Button
                  shape='circle'
                  icon={<EditOutlined />}
                  onClick={() => {
                    this.showEditModal(item)
                  }}
                />
              </Tooltip>
            </div>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Delete'>
                <Popconfirm
                  onConfirm={() => {
                    //call delete staff api
                    this.deleteStaff(item)
                  }}
                  title='Are you sure you want to delete staff?'
                >
                  <Button shape='circle' icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        )
      },
    },
  ]

  public render() {
    const {staffData, staffDataTotal, staffLoading} = this.props
    const {searchText, staffSelectedData} = this.state

    return (
      <div>
        <div className='container'>
          <div className='card card-custom'>
            <div className='card-header'>
              <h3 className='card-title table-card-title' style={{width: '25%'}}>
                <InputSearchText
                  onChange={(value: string) => {
                    this.onSearchChange(value)
                  }}
                  placeholder='Search Staff'
                  input={{
                    value: searchText,
                  }}
                />
              </h3>
              <div className='card-toolbar'>
                <InputButtonComponent
                  onClick={() => {
                    this.onCreateStaff()
                  }}
                >
                  Add Staff
                </InputButtonComponent>
              </div>
            </div>
            <div className='card-body'>
              <div style={{overflowX: 'auto'}}>
                <ListTable
                  rowKey={(record: {id: any}) => record.id}
                  onChange={this.handleTableChange}
                  column={this.columns}
                  paginationConfig={{
                    total: staffDataTotal,
                    showSizeChanger: true,
                    pageSize: this.state.pagination.pageSize,
                    current: this.state.pagination.current,
                  }}
                  isLoading={staffLoading}
                  dataSource={staffData}
                  counter={this.counter}
                  tempCounter={this.tempCounter}
                />
                <div>
                  <CommanModal
                    show={staffSelectedData ? true : false}
                    handleClose={() => {
                      this.closeEditModal()
                    }}
                    title={'Edit Staff'}
                    submitText={'Save Changes'}
                  >
                    <div>
                      {staffSelectedData && (
                        <EditStaffForm
                          data={staffSelectedData}
                          onSubmit={(values: any, clientId: string) => {
                            this.onEditSubmit(values, clientId)
                          }}
                          handleCancel={() => {
                            this.closeEditModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  staffData: state.staff.staffData,
  staffDataTotal: state.staff.staffDataTotal,
  staffLoading: state.staff.staffLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListStaffData: (staffData: any) => {
      dispatch(staff.actions.setStaffData(staffData))
    },
    setListStaffDataTotal: (staffDataTotal: number) => {
      dispatch(staff.actions.setStaffDataTotal(staffDataTotal))
    },
    setStaffLoading: (staffLoading: boolean) => {
      dispatch(staff.actions.setStaffLoading(staffLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListStaffData))
