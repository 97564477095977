/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

type Props = {
  onChange: (value: string) => void
  onBlur: (value: any) => void
  placeholder?: string
  label: string
  input: {
    value?: string
    name: string
    id: string
  }
  iconFontClass?: string
  type?: string
  disabled?: boolean
  className?: string
  error: any
  touched: any
  required?: boolean
}

const InputText: React.FC<Props> = ({
  onChange,
  onBlur,
  placeholder,
  label,
  input,
  iconFontClass,
  type = 'text',
  disabled = false,
  className,
  error,
  touched,
  required = true,
}) => {
  return (
    <>
      <label>
        {label} {required && <span className='text-danger'>*</span>}
      </label>

      <input
        {...input}
        placeholder={placeholder}
        type={type}
        className={
          touched && error && error[input.name] && !disabled
            ? 'form-control border-danger'
            : 'form-control'
        }
        disabled={disabled}
        autoComplete='off'
        onChange={(event: any) => {
          onChange(event.target.value)
        }}
        onBlur={onBlur}
      />
      {touched && error && error[input.name] && !disabled && (
        <div className='fv-plugins-message-container text-danger'>
          <div className='fv-help-block'>{error[input.name]}</div>
        </div>
      )}
    </>
  )
}

export {InputText}

// <InputText
//   input={{
//     value: values.bank_name,
//     id: 'bank_name',
//     name: 'bank_name',
//   }}
//   placeholder='Enter bank name'
//   onChange={(value: string) => {
//     handleChange(value)
//     setFieldValue('bank_name', value)
//   }}
//   onBlur={handleBlur}
//   label='Bank Name'
//   error={errors}
//   touched={touched}
// />
