/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getCourierData} from '../redux/CourierCRUD'
import {useDispatch} from 'react-redux'
import * as courier from '../redux/CourierRedux'
import ListCourierData from './ListCourierData'

const ListPage: FC = () => {
  const dispatch = useDispatch()

  const callPackageData = async () => {
    try {
      let locationData = await getCourierData(0, 10)
      if (locationData.data) {
        await dispatch(courier.actions.setCourierLoading(true))
        // once courier data set then loading will automatically off using saga middleware
        await dispatch(courier.actions.setCourierData(locationData.data.docs))
        await dispatch(courier.actions.setCourierDataTotal(locationData.data.totalDocs))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callPackageData()
  }, [])

  return (
    <div>
      <ListCourierData />
    </div>
  )
}

const ListWraper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'list',
            path: '/courier/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'list',
            path: '/courier/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.COURIER'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWraper}
