/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

type Props = {
  onChange: (value: number) => void
  onBlur: (value: any) => void
  placeholder?: string
  label: string
  input: {
    value?: number
    name: string
    id: string
  }
  iconFontClass?: string
  type?: string
  disabled?: boolean
  className?: string
  error: any
  touched: any
  required?: boolean
}

const InputNumberText: React.FC<Props> = ({
  onChange,
  onBlur,
  placeholder,
  label,
  input,
  iconFontClass,
  type = 'number',
  disabled = false,
  className,
  error,
  touched,
  required = true,
}) => {
  return (
    <>
      <label>
        {label} {required && <span className='text-danger'>*</span>}
      </label>

      <input
        {...input}
        placeholder={placeholder}
        type={type}
        className={
          touched && error && error[input.name] && !disabled
            ? 'form-control border-danger'
            : 'form-control'
        }
        disabled={disabled}
        autoComplete='off'
        onChange={(event: any) => {
          onChange(event.target.value)
        }}
        onBlur={onBlur}
        min={1}
      />
      {touched && error && error[input.name] && !disabled && (
        <div className='fv-plugins-message-container text-danger'>
          <div className='fv-help-block'>{error[input.name]}</div>
        </div>
      )}
    </>
  )
}

export {InputNumberText}

//   <InputNumberText
//     input={{
//       value: values.bank_number,
//       id: 'bank_number',
//       name: 'bank_number',
//     }}
//     placeholder='Enter bank Number'
//     onChange={(value: number) => {
//       handleChange(value)
//       setFieldValue('bank_number', value)
//     }}
//     onBlur={handleBlur}
//     label='Bank Number'
//     error={errors}
//     touched={touched}
//   />
