/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as tally from '../redux/TallyRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Tag} from 'antd'
import {UserOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
import {getSearchTallyData, getTallyData,exportAllTallyStoneList} from '../redux/TallyCRUD'
import {
  returnPriceInThousandFormat,
  returnPriceInThousandFormatWithCurrencySign,
  returnStockId,
} from '../../../comman/helper'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'


const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  data: any
  tallyData: any
  tallyDataTotal: number
  tallyLoading: boolean
  setListTallyData: (tallyData: any) => void
  setListTallyDataTotal: (tallyDataTotal: number) => void
  setTallyLoading: (tallyLoading: boolean) => void
  history?: History<LocationState>
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  tallySelectedData: any
}

type PathParamsType = {
  param1: string
}
class ListNonTallyStoneData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      tallySelectedData: null,
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListTallyData(pagination)
    )
  }

  public getListTallyData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listTallySearchData(start, end)
        } else {
          this.listTallyData(start, end)
        }
      }
    )
  }

  public listTallyData = async (start: number, end: any) => {
    const {setTallyLoading} = this.props
    try {
      const {setListTallyData, setListTallyDataTotal} = this.props
      await setTallyLoading(true)
      let tallyData = await getTallyData(this.props.data?._id,this.props.data?.date,'untally')
      if (tallyData.data) {
        await setListTallyData(tallyData.data)
        await setListTallyDataTotal(tallyData.data.length)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setTallyLoading(false)
    }
  }

  public listTallySearchData = async (start: number, end: any) => {
    const {setTallyLoading} = this.props
    try {
      const {setListTallyData, setListTallyDataTotal} = this.props
      const {searchText} = this.state

      await setTallyLoading(true)
      let tallyData = await getSearchTallyData(start, end, searchText)
      if (tallyData.data) {
        await setListTallyData(tallyData.data)
        await setListTallyDataTotal(tallyData.data.length)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setTallyLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listTallySearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public onCreateStaff = () => {
    const {history} = this.props
    history.push('/tally/create')
  }

  public showEditModal = async (item: any) => {
    this.setState({
      tallySelectedData: item,
    })
  }

  public closeEditModal = async () => {
    this.setState({
      tallySelectedData: null,
    })
  }

  public generateCsv = async () => {
    try {
      this.setState(
        {
          isLoading: true,
        },
        () => {
              let csvArray = [];
 let res = this.props.tallyData

              csvArray.push([
                'STATUS',
                'STOCK ID',
                'OLD STOCK ID',
                'TYPE',
                'RAPNET',
                'SHAPE',
                'WEIGHT',
                'COL',
                'CL',
                'RAP',
                'CUT',
                'PO',
                'SYM',
                'FL',
                'MEASUREMENT',
                'TABLE',
                'DEPTH',
                'LAB',
                'CERT NO',
                'CERT Link',
                'DISC',
                'NO BGM',
                'LOCATION',
                'P/Ct $',
                'VIDEO Link',
                'IMAGE Link',
              ])

              res.map((data: any) => {
                csvArray.push([
                  data && data.status ? data.status : '',
                  data && data.stock_id ? returnStockId(data.stock_id) : '',
                  data && data.old_stock_id ? returnStockId(data.old_stock_id) : '',
                  data && data.type ? data.type : '',
                  data && data.rapnet ? data.rapnet : '',
                  data && data.shape ? returnStockId(data.shape) : '',
                  data && data.weight ? data.weight : '',
                  data && data.color ? returnStockId(data.color) : '',
                  data && data.clarity ? returnStockId(data.clarity) : '',
                  data && data.rap ? data.rap : '',
                  data && data.cut ? returnStockId(data.cut) : '',
                  data && data.polish ? returnStockId(data.polish) : '',
                  data && data.symmetry ? returnStockId(data.symmetry) : '',
                  data && data.fluorescence
                    ? returnStockId(data.fluorescence)
                    : '',
                  data && data.measurement ? data.measurement : '',
                  data && data.table ? data.table : '',
                  data && data.depth ? data.depth : '',
                  data && data.lab ? data.lab : '',
                  data && data.cert_number ? data.cert_number : '',
                  data && data.cert_link ? data.cert_link : '',
                  data && data.discount ? data.discount : '',
                  data && data.no_bgm ? data.no_bgm : '',
                  data && data.location ? data.location : '',
                  data && data.price_per_caret ? data.price_per_caret : '',
                  data && data.video_link ? data.video_link : '',
                  data && data.image_link ? data.image_link : '',
                ])
              })

              const rows = csvArray
              let csvName = 'NonTallyStone.csv'
              let csvContent = 'data:text/csv;charset=utf-8,'

              rows.forEach(function (rowArray: any) {
                let row = rowArray.join(',')
                csvContent += row + '\r\n'
              })

              var encodedUri = encodeURI(csvContent)
              // // // // window.open(encodedUri);
              var link = document.createElement('a')
              link.setAttribute('href', encodedUri)
              link.setAttribute('download', csvName)
              document.body.appendChild(link) // Required for FF

              link.click()

              this.setState({
                isLoading: false,
              })
            })
    } catch (e) {
      console.log('error in generate csv', e)
    }
  }


  counter = 0
  tempCounter = 0
  public columns = [
    // {
    //   title: 'Sr. No.',
    //   key: 'serial_number',
    //   fixed: window.innerWidth < 768 ? false : true,
    //   width: 60,
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         <span>{++index + this.tempCounter}</span>
    //       </div>
    //     )
    //   },
    // },
    {
      // title: 'Stock Id',
      title: 'STOCK ID',
      key: 'stock_id',
      width: 80,
      fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <a href={`/inventory/preview/${item._id}`} target='_blank'>
              <strong>{item.stock_id ? returnStockId(item.stock_id) : '-'}</strong>
            </a>
          </div>
        )
      },
    },
    {
      // title: 'Status',
      title: 'STATUS',
      key: 'status',
      width: 80,
      fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {' '}
            {item.status ? (
              <Tag
                color={
                  config.statusTagColor[item.status]
                    ? config.statusTagColor[item.status]
                    : 'default'
                }
              >
                {item.status}
              </Tag>
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      // title: 'Stock Id',
      title: 'OLD STOCK ID',
      key: 'old_stock_id',
      width: 80,
      render: (text: any, item: any, index: number) => {
        return <div>{item.old_stock_id ? returnStockId(item.old_stock_id) : '-'}</div>
      },
    },
        {
      title: 'TYPE',
      key: 'type',
      width: 60,
      render: (text: any, item: any, index: number) => {
        return <div>{item.type ? item.type : '-'}</div>
      },
    },
    {
      // title: 'Image',
      title: 'MEDIA',
      key: 'diamond_image',
      width: 90,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.diamond_image ? (
              <a href={item.diamond_image} target='_blank'>
                {item.diamond_image}
              </a>
            ) : (
              <span className='text-muted'>{'No Image Available'}</span>
            )}
            {' | '}
            {item.video_link ? (
              <a href={item.video_link} target='_blank'>
                {item.video_link}
              </a>
            ) : item.video_file_name ? (
              <a href={`${API_URL}stock/video/${item.video_file_name}`} target='_blank'>
                {`${API_URL}stock/video/${item.video_file_name}`}
              </a>
            ) : (
              <span className='text-muted'>{'No Video Available'}</span>
            )}
          </div>
        )
      },
    },
    {
      // title: 'Shape',
      title: 'SHAPE',
      key: 'shape',
      width: 70,
      render: (text: any, item: any, index: number) => {
        return <div>{item.shape ? returnStockId(item.shape) : '-'}</div>
      },
    },
    {
      // title: 'Weight',
      title: 'WEIGHT',
      key: 'weight',
      width: 75,
      render: (text: any, item: any, index: number) => {
        return <div>{item.weight ? item.weight : '-'}</div>
      },
    },
    {
      // title: 'Color',
      title: 'COLOR',
      key: 'color',
      // ellipsis: true,
      // width: 40,
      width: 65,
      render: (text: any, item: any, index: number) => {
        return <div>{item.color ? returnStockId(item.color) : '-'}</div>
      },
    },
    {
      // title: 'Clarity',
      title: 'CLARITY',
      key: 'clarity',
      width: 75,
      render: (text: any, item: any, index: number) => {
        return <div>{item.clarity ? returnStockId(item.clarity) : '-'}</div>
      },
    },
    {
      // title: 'Cut',
      title: 'CUT',
      key: 'cut',
      // width: 40,
      width: 45,
      render: (text: any, item: any, index: number) => {
        return <div>{item.cut ? returnStockId(item.cut) : '-'}</div>
      },
    },
    {
      // title: 'Polish',
      title: 'POLISH',
      // ellipsis: true,
      key: 'polish',
      width: 70,
      render: (text: any, item: any, index: number) => {
        return <div>{item.polish ? returnStockId(item.polish) : '-'}</div>
      },
    },
    {
      // title: 'Symmetry',
      title: 'SYM',
      // ellipsis: true,
      key: 'symmetry',
      width: 50,
      render: (text: any, item: any, index: number) => {
        return <div>{item.symmetry ? returnStockId(item.symmetry) : '-'}</div>
      },
    },

    {
      // title: 'Fluorescence Intensity',
      title: 'FL',
      // ellipsis: true,
      key: 'fluorescence',
      width: 50,
      render: (text: any, item: any, index: number) => {
        return <div>{item.fluorescence ? returnStockId(item.fluorescence) : '-'}</div>
      },
    },
    {
      // title: 'Lab Grown Type',
      title: 'LAB',
      // ellipsis: true,
      key: 'lab_grown_type',
      width: 50,
      render: (text: any, item: any, index: number) => {
        return <div>{item.lab_grown_type ? item.lab_grown_type : '-'}</div>
      },
    },
    {
      // title: 'Certificate No.',
      title: 'CERTIFICATE',
      key: 'cert_number',
      width: 100,
      // ellipsis: true,
      render: (text: any, item: any, index: number) => {
        return <div>{item.cert_number ? item.cert_number : '-'}</div>
      },
    },
    {
      // title: 'Discounts',
      title: 'DISCOUNTS',
      // ellipsis: true,
      key: 'discount',
      width: 100,
      render: (text: any, item: any, index: number) => {
        return <div>{item.discount ? item.discount : '-'}</div>
      },
    },
    {
      // title: 'Price Per Caret',
      title: '$/CT',
      // ellipsis: true,
      key: 'price_per_caret',
      width: 60,
      render: (text: any, item: any, index: number) => {
        return <div>{item.price_per_caret ? item.price_per_caret : '-'}</div>
      },
    },
    {
      // title: 'Final Price',
      title: 'FINAL PRICE',
      // ellipsis: true,
      key: 'final_price',
      width: 100,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.total_price ? returnPriceInThousandFormatWithCurrencySign(item.total_price) : '-'}
          </div>
        )
      },
    },
    {
      // title: 'Depth',
      title: 'DEPTH',
      key: 'depth',
      width: 70,
      render: (text: any, item: any, index: number) => {
        return <div>{item.depth ? item.depth : '-'}</div>
      },
    },
    {
      // title: 'Table',
      title: 'TABLE',
      key: 'table',
      width: 70,
      render: (text: any, item: any, index: number) => {
        return <div>{item.table ? item.table : '-'}</div>
      },
    },
    {
      // title: 'Measurements',
      title: 'MEASUREMENTS',
      // ellipsis: true,
      key: 'measurement',
      width: 140,
      render: (text: any, item: any, index: number) => {
        return <div>{item.measurement ? item.measurement : '-'}</div>
      },
    },
    {
      // title: 'Rap Price',
      title: 'RAP PRICE',
      // ellipsis: true,
      key: 'rap',
      width: 80,
      render: (text: any, item: any, index: number) => {
        return <div>{item.rap ? item.rap : '-'}</div>
      },
    },
  ]

  public render() {
    const {tallyData, tallyDataTotal, tallyLoading} = this.props
    const {searchText, tallySelectedData} = this.state

    return (
      <div>
        <div className='container'>
          <div className='card card-custom'>
            <div className='card-header'>
              <h3 className='card-title table-card-title' style={{width: '25%'}}>
                {/* <InputSearchText
                  onChange={(value: string) => {
                    this.onSearchChange(value)
                  }}
                  placeholder='Search Tally Stones'
                  input={{
                    value: searchText,
                  }}
                /> */}
              </h3>
              <div className='card-toolbar'>
              <InputButtonComponent
                      onClick={() => {
                        this.generateCsv()
                      }}
                    >
                      Export Non Tally Stones
                    </InputButtonComponent>
              </div>
            </div>
            <div className='card-body'>
              <div style={{overflowX: 'auto'}}>
                <ListTable
                  rowKey={(record: {_id: any}) => record._id}
                  onChange={this.handleTableChange}
                  column={this.columns}
                  paginationConfig={{
                    total: tallyDataTotal,
                    showSizeChanger: true,
                    pageSize: this.state.pagination.pageSize,
                    current: this.state.pagination.current,
                  }}
                  isScroll={true}
                  scrollWidth={3000}
                  isLoading={tallyLoading}
                  dataSource={tallyData}
                  counter={this.counter}
                  tempCounter={this.tempCounter}
                />
                <div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  tallyData: state.tally.tallyData,
  tallyDataTotal: state.tally.tallyDataTotal,
  tallyLoading: state.tally.tallyLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListTallyData: (tallyData: any) => {
      dispatch(tally.actions.setTallyData(tallyData))
    },
    setListTallyDataTotal: (tallyDataTotal: number) => {
      dispatch(tally.actions.setTallyDataTotal(tallyDataTotal))
    },
    setTallyLoading: (tallyLoading: boolean) => {
      dispatch(tally.actions.setTallyLoading(tallyLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListNonTallyStoneData))
