import React from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_ALL_KPIS_URL = `${API_URL}api/v1/auth/admin/stats/`

// Get all kpis Data
export async function getAllKpisData() {
  try {
    let allKpisData = await axios.get(`${GET_ALL_KPIS_URL}`)

    return allKpisData
  } catch (e) {
    throw e
  }
}
