/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {createLocationData, getLocationData} from '../redux/LocationCRUD'
import * as location from '../redux/LocationRedux'
import {CreateLocationForm} from './CreateLocationForm'
import ListLocationData from './ListLocationData'
import {IClientFormValues} from './CreateLocationForm'

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  // call initial data for Form
  const callInitialData = async () => {
    try {
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callInitialData()
  }, [])

  const callClientData = async () => {
    try {
      let locationData = await getLocationData(0, 10)
      if (locationData.data) {
        await dispatch(location.actions.setLocationLoading(true))
        // once location data set then loading will automatically off using saga middleware
        await dispatch(location.actions.setLocationData(locationData.data.docs))
        await dispatch(location.actions.setLocationDataTotal(locationData.data.totalDocs))
        history.push('/location/list')
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  const addSubmit = async (values: IClientFormValues) => {
    try {
      await createLocationData(values)
      await callClientData()
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  return (
    <div>
      <CreateLocationForm onSubmit={(values: IClientFormValues) => addSubmit(values)} />
    </div>
  )
}

const CreateWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'create',
            path: '/location/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'create',
            path: '/location/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.LOCATION'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {CreateWrapper}
