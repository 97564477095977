/* Based on the template in Web Starter Kit :
https://github.com/google/web-starter-kit/blob/master/app/index.html
*/
// import chromeFavicon from '../../public/images/android-icon-192x192.png';
// import appleFavicon from '../../public/images/apple-icon-152x152.png';
// import msFavicon from '../../public/images/ms-icon-144x144.png';
// import favicon from '../../public/images/favicon-16x16.png';
const leadingTitle = 'SC Impex'

const metaAssets = () => {
  return [
    {charset: 'utf-8'},
    // Meta descriptions are commonly used on search engine result pages to
    // display preview snippets for a given page.
    {
      name: 'description',
      content: leadingTitle,
    },
    // Setting IE=edge tells Internet Explorer to use the latest engine to
    //  render the page and execute Javascript
    {'http-equiv': 'X-UA-Compatible', content: 'IE=edge'},
    // Using the viewport tag allows you to control the width and scaling of
    // the browser's viewport:
    // - include width=device-width to match the screen's width in
    // device-independent pixels
    // - include initial-scale=1 to establish 1:1 relationship between css pixels
    // and device-independent pixels
    // - ensure your page is accessible by not disabling user scaling.
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, shrink-to-fit=no',
    },
    // Disable tap highlight on IE
    {name: 'msapplication-tap-highlight', content: 'no'},
    // Add to homescreen for Chrome on Android
    {name: 'mobile-web-app-capable', content: 'yes'},
    // Add to homescreen for Safari on IOS
    {name: 'apple-mobile-web-app-capable', content: 'yes'},
    {name: 'apple-mobile-web-app-status-bar-style', content: 'black'},
    {name: 'apple-mobile-web-app-title', content: leadingTitle},
    // Tile icon for Win8 (144x144 + tile color)
    {name: 'msapplication-TileImage', content: '/images/ms-icon-144x144.png'},
    {name: 'msapplication-TileColor', content: '#3372DF'},
  ]
}

const linkAssets = () => {
  const links = [
    // Add to homescreen for Chrome on Android
    {
      rel: 'icon',
      href: 'https://hp.azureedge.net/Assests/images/favicon.ico',
    },
    {
      rel: 'icon',
      sizes: '192x192',
      href: 'https://hp.azureedge.net/Assests/images/apple-touch-icon.png',
    },
    // Add to homescreen for Safari on IOS
    {
      rel: 'apple-touch-icon',
      sizes: '114x114',
      href: 'https://hp.azureedge.net/Assests/images/apple-touch-icon-114x114.png',
    },
    // SEO: If your mobile URL is different from the desktop URL,
    // add a canonical link to the desktop page https://developers.google.com/webmasters/smartphone-sites/feature-phones
    // { 'rel': 'canonical', 'href': 'http://www.example.com/' }
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700',
    },
    {
      rel: 'stylesheet',
      href: 'https://cdnjs.cloudflare.com/ajax/libs/antd/4.0.4/antd.css',
    },
    {
      rel: 'stylesheet',
      href: '/css/metronic/pages/login/login-3.css',
    },
    {rel: 'stylesheet', href: '/css/metronic/style.bundle.css'},
    {
      rel: 'stylesheet',
      href: '/css/metronic/plugins/custom/prismjs/prismjs.bundle.css',
    },
    {
      rel: 'stylesheet',
      href: '/css/metronic/plugins/global/plugins.bundle.css',
    },
    {
      rel: 'stylesheet',
      href: '/css/metronic/themes/layout/header/base/light.css',
    },
    {
      rel: 'stylesheet',
      href: '/css/metronic/themes/layout/header/menu/light.css',
    },
    // {
    //   rel: 'stylesheet',
    //   href: '/css/metronic/themes/layout/header/base/dark.css',
    // },
    // {
    //   rel: 'stylesheet',
    //   href: '/css/metronic/themes/layout/header/menu/dark.css',
    // },
    // {
    //   rel: 'stylesheet',
    //   href: '/css/metronic/themes/layout/aside/light.css',
    // },
    // {
    //   rel: 'stylesheet',
    //   href: '/css/metronic/themes/layout/brand/light.css',
    // },
    {
      rel: 'stylesheet',
      href: '/css/metronic/themes/layout/aside/dark.css',
    },
    {
      rel: 'stylesheet',
      href: '/css/metronic/themes/layout/brand/dark.css',
    },
    {
      rel: 'stylesheet',
      href: '/css/react-datepicker.min.css',
    },
    {
      rel: 'stylesheet',
      href: '/css/main.css?v=1.1',
    },
  ]

  return links
}

export const title = leadingTitle
export const meta = metaAssets()
export const link = linkAssets()
