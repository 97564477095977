/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getInventoryData, getSearchInventoryData} from '../redux/InventoryCRUD'
import {useDispatch} from 'react-redux'
import * as inventory from '../redux/InventoryRedux'
import ListInventoryData from './ListInventoryData'
import ListSearchForm from './ListSearchForm'
import {Tabs} from 'antd'
import ListInventoryNonCertData from './ListInventoryNonCertData'
import ListInventoryStarMeleeData from './ListInventoryStarMeleeData'
import {useLocation} from 'react-router-dom'

const {TabPane} = Tabs

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const data = location.state

  const [searchPagination, setSearchPagination] = useState({
    skip: 0,
    limit: 10,
    filterObject: {},
  })
  const [TabKey, setTabKey] = useState('1')

  const callback = (key: any) => {
    setTabKey(key)
    if (key == 1) {
      callInventoryData('CERT')
    } else if (key == 2) {
      callInventoryData('NON_CERT')
    } else if (key == 3) {
      callInventoryData('STAR_MELEE')
    }
  }

  const callInventoryData = async (type: string) => {
    try {
      if (!type) {
        type = TabKey == '1' ? 'CERT' : TabKey == '2' ? 'NON_CERT' : 'STAR_MELEE'
      }
      let inventoryData = await getInventoryData(
        searchPagination.skip,
        searchPagination.limit,
        type
      )
      if (inventoryData.data) {
        await dispatch(inventory.actions.setInventoryLoading(true))
        // once inventory data set then loading will automatically off using saga middleware
        await dispatch(inventory.actions.setInventoryData(inventoryData.data.docs))
        await dispatch(inventory.actions.setInventoryDataTotal(inventoryData.data.totalDocs))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  const callInventoryDataForDashboardRedirect = async (values: any, searchText: any) => {
    try {
      await dispatch(inventory.actions.setInventoryLoading(true))
      await dispatch(inventory.actions.setSearchParamsData(values))
      await dispatch(inventory.actions.setSearchTextData(''))
      let inventoryData = await getSearchInventoryData(
        searchPagination.skip,
        searchPagination.limit,
        values,
        TabKey == '1' ? 'CERT' : TabKey == '2' ? 'NON_CERT' : 'STAR_MELEE',
        searchText,
        ''
      )
      if (inventoryData.data) {
        await dispatch(inventory.actions.setInventoryData(inventoryData.data.docs))
        await dispatch(inventory.actions.setInventoryDataTotal(inventoryData.data.totalDocs))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await dispatch(inventory.actions.setInventoryLoading(false))
    }
  }

  useEffect(() => {
    // const data = location.state
    console.log(data, 'data')
    if (data) {
      let searchParams =  {status: [data]}
    
      callInventoryDataForDashboardRedirect(searchParams, '')
    } else {
      dispatch(inventory.actions.setSearchParamsData({}))
      dispatch(inventory.actions.setSearchTextData(''))
      callInventoryData('CERT')
    }
  }, [])

  const searchInventoryData = async (values: any, searchText: any,location: any) => {
    try {
      await dispatch(inventory.actions.setInventoryLoading(true))
      let inventoryData = await getSearchInventoryData(
        searchPagination.skip,
        searchPagination.limit,
        values,
        TabKey == '1' ? 'CERT' : TabKey == '2' ? 'NON_CERT' : 'STAR_MELEE',
        searchText,
        location
      )
      if (inventoryData.data) {
        await dispatch(inventory.actions.setInventoryData(inventoryData.data.docs))
        await dispatch(inventory.actions.setInventoryDataTotal(inventoryData.data.totalDocs))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await dispatch(inventory.actions.setInventoryLoading(false))
    }
  }

  return (
    <div>
      <ListSearchForm cardData={data} onSubmit={searchInventoryData} onClearSearch={callInventoryData} />
      <Tabs style={{fontWeight: '500'}} activeKey={TabKey} onChange={callback}>
        <TabPane tab='CERT' key='1'>
          {TabKey === '1' && <ListInventoryData />}
        </TabPane>
        <TabPane tab='NON CERT' key='2'>
          {TabKey === '2' && <ListInventoryNonCertData />}
        </TabPane>
        <TabPane tab='STAR MELEE' key='3'>
          {TabKey === '3' && <ListInventoryStarMeleeData />}
        </TabPane>
      </Tabs>
      {/* <ListInventoryData /> */}
    </div>
  )
}

const ListWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'List',
            path: '/inventory/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/inventory/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.INVENTORY'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWrapper}
