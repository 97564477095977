import * as React from 'react'
import {PaperClipOutlined, SoundOutlined, FileTextOutlined} from '@ant-design/icons'
import {config} from '../../../comman/constants'

export default function ListUploadedMediaItem(props: any) {
  const {name, type, thumbMediaUrl, url, width} = props

  const videoRegx = new RegExp(`.(${config.videoExtensions})`)
  const audioRegx = new RegExp(`.(${config.audioExtensions})`)
  const imageRegx = new RegExp(`.(${config.imageExtensions})`)
  const fileRegx = new RegExp(`.(${config.fileExtensions})`)

  return (
    <>
      <div className='row col-md-12 mt-5'>
        <div className='col-md-1'>
          {(videoRegx.test(name) && (
            <a href={url} target='_blank' className='ant-upload-text-icon'>
              <img src={thumbMediaUrl} width={width ? width : 50} />
            </a>
          )) ||
            (audioRegx.test(name) && (
              <a href={url} target='_blank' className='ant-upload-text-icon'>
                <SoundOutlined />
              </a>
            )) ||
            (imageRegx.test(name) && (
              <a href={url} target='_blank' className='ant-upload-text-icon'>
                <img src={url} width={width ? width : 50} />
              </a>
            )) ||
            (fileRegx.test(name) && (
              <a href={url} target='_blank' className='ant-upload-text-icon'>
                <FileTextOutlined />
              </a>
            )) || <PaperClipOutlined />}
        </div>
        <div className='col-md-11'>{name && <span className='font-weight-bold'>{name}</span>}</div>
      </div>
    </>
  )
}
