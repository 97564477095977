/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as inventory from '../redux/InventoryRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {UserOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons'
import {
  changeStatusInventoryData,
  deleteInventoryData,
  editInventoryData,
  getInventoryData,
  getSearchInventoryData,
} from '../redux/InventoryCRUD'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/Toaster'
import {CommanModal} from '../../../modules/comman/components/Modal'
import {IInventoryFormValues} from './CreateInventoryForm'
import {returnStockId} from '../../../comman/helper'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  matrixInventoryData: any
  matrixInventoryDataTotal: number
  matrixInventoryLoading: boolean
  setListMatrixInventoryData: (matrixInventoryData: any) => void
  setListMatrixInventoryDataTotal: (matrixInventoryDataTotal: number) => void
  setMatrixInventoryLoading: (matrixInventoryLoading: boolean) => void
  history?: History<LocationState>
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  inventorySelectedData: any
  colorCount: number
  clarityCount: number
}

type PathParamsType = {
  param1: string
}
class ListMatrixInventoryData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      inventorySelectedData: null,
      colorCount: 0,
      clarityCount: 0,
    }
  }

  colorCount: number = 0
  clarityCount: number = 0

  componentDidUpdate() {
    this.colorCount = 0
    this.clarityCount = 0
  }

  public returnMatrixCount = (colorValue: string, clarityValue: string) => {
    let count = 0
    const {matrixInventoryData} = this.props
    let matrixFilterData = matrixInventoryData.filter(
      (invData: any) => invData._id.clarity === clarityValue && invData._id.color === colorValue
    )
    if (matrixFilterData.length >= 1)
      matrixFilterData.map((filterInvItem: any) => (count = filterInvItem.count))

    return count
  }

  public calculateColorSum = (colorValue: string) => {
    let count = 0
    const {matrixInventoryData} = this.props
    matrixInventoryData.map((invData: any) => {
      if (invData._id.color === colorValue) {
        count += invData.count
      }
    })
    this.colorCount += count
    return count
  }

  public calculateClaritySum = (clarityValue: string) => {
    let count = 0
    const {matrixInventoryData} = this.props
    matrixInventoryData.map((invData: any) => {
      if (invData._id.clarity === clarityValue) {
        count += invData.count
      }
    })
    this.clarityCount += count
    return count
  }

  public render() {
    const {matrixInventoryData, matrixInventoryLoading} = this.props
    return (
      <div>
        <div>
          {matrixInventoryLoading || !matrixInventoryData ? (
            <div className='d-flex justify-content-center'>
              <div className='spinner-border'></div>
            </div>
          ) : (
            <div className='card card-custom'>
              <div className='card-header'>
                <h3 className='card-title table-card-title' style={{width: '25%'}}></h3>
              </div>
              <div className='card-body'>
                <div style={{overflowX: 'auto'}}>
                  <div className='ant-table-wrapper'>
                    <div className='ant-spin-nested-loading'>
                      <div className='ant-spin-container'>
                        <div className='ant-table ant-table-small ant-table-ping-right ant-table-fixed-column ant-table-scroll-horizontal'>
                          <div className='ant-table-container'>
                            <div className='ant-table-content custom-table-wrapper'>
                              <table style={{width: '80%', minWidth: '100%', tableLayout: 'fixed'}}>
                                <thead className='ant-table-thead'>
                                  <tr>
                                    <th>{''}</th>
                                    {config.matrixTableClarity.map(
                                      (clarityName: any, clarityIndex: number) => (
                                        <th
                                          className='ant-table-cell custom-table-sticky-col'
                                          key={clarityIndex}
                                        >
                                          {returnStockId(clarityName.value)}
                                        </th>
                                      )
                                    )}
                                    <th>{'Total'}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {config.matrixTableColor.map(
                                    (colorName: any, colorIndex: number) => (
                                      <>
                                        <tr>
                                          <td className='ant-table-cell' key={colorIndex}>
                                            <strong>{returnStockId(colorName.value)}</strong>
                                          </td>
                                          {config.matrixTableClarity.map(
                                            (clarityName: any, clarityIndex: number) => (
                                              <>
                                                <td
                                                  className='ant-table-cell custom-table-sticky-col'
                                                  key={clarityIndex}
                                                >
                                                  <label className='text-dark'>
                                                    {this.returnMatrixCount(
                                                      colorName.value,
                                                      clarityName.value
                                                    )}
                                                  </label>
                                                </td>
                                              </>
                                            )
                                          )}
                                          <td>
                                            <strong>
                                              {this.calculateColorSum(colorName.value)}
                                            </strong>
                                          </td>
                                        </tr>
                                      </>
                                    )
                                  )}
                                  <tr>
                                    <td className='ant-table-cell'>
                                      <strong>{'Total'}</strong>
                                    </td>
                                    {config.matrixTableClarity.map(
                                      (clarityName: any, clarityIndex: number) => (
                                        <>
                                          <td
                                            className='ant-table-cell custom-table-sticky-col'
                                            key={clarityIndex}
                                          >
                                            <label className='text-dark'>
                                              <strong>
                                                {this.calculateClaritySum(clarityName.value)}
                                              </strong>
                                            </label>
                                          </td>
                                        </>
                                      )
                                    )}
                                    <td className='ant-table-cell'>
                                      <strong>{this.colorCount + this.clarityCount}</strong>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  matrixInventoryData: state.inventory.matrixInventoryData,
  matrixInventoryDataTotal: state.inventory.matrixInventoryDataTotal,
  matrixInventoryLoading: state.inventory.matrixInventoryLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListMatrixInventoryData: (matrixInventoryData: any) => {
      dispatch(inventory.actions.setInventoryData(matrixInventoryData))
    },
    setListMatrixInventoryDataTotal: (matrixInventoryDataTotal: number) => {
      dispatch(inventory.actions.setInventoryDataTotal(matrixInventoryDataTotal))
    },
    setMatrixInventoryLoading: (matrixInventoryLoading: boolean) => {
      dispatch(inventory.actions.setMatrixInventoryLoading(matrixInventoryLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListMatrixInventoryData))
