import React from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_TALLY_URL = `${API_URL}api/v1/auth/both/tally/stones`
export const SEARCH_TALLY_URL = `${API_URL}api/v1/auth/admin/tally/`
export const GET_CSV_TALLY_URL = `${API_URL}api/v1/auth/admin/tally/`
export const GET_TALLY_DATE_URL = `${API_URL}api/v1/auth/admin/tally/dates`

var moment = require('moment')

const format = "YYYY-MM-DD";

// Get staff Data
export async function getTallyData(tally_id: string, date: string,type?: string) {
  try {
    if(date) {
      date = moment(date).format(format);
    }
    let tallyData = await axios.get(`${GET_TALLY_URL}?tally_id=${tally_id ? tally_id : ''}&type=${type}&date=${date ? date : ''}`)
    return tallyData
  } catch (e) {
    throw e
  }
}

// Search staff Data
export async function getSearchTallyData(
  startIndex: number,
  limitNumber: number,
  searchText: string
) {
  try {
    let tallyData = await axios.post(`${SEARCH_TALLY_URL}${startIndex}/${limitNumber}`, {
      searchText,
    })
    return tallyData
  } catch (e) {
    throw e
  }
}

export async function exportAllTallyStoneList(type: string) {
  try {
    let exportAllStockData = await axios.get(`${GET_CSV_TALLY_URL}?type=${type}`)
    return exportAllStockData
  } catch (e) {
    throw e
  }
}

export async function getTallyDateData(
  startIndex: number,
  limitNumber: number,
) {
  try {
    let tallyDateData = await axios.get(`${GET_TALLY_DATE_URL}?skip=${startIndex}&limit=${limitNumber}`)
    return tallyDateData
  } catch (e) {
    throw e
  }
}
