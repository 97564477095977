import React from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_LOCATION_URL = `${API_URL}api/v1/auth/admin/locations/`
export const SEARCH_LOCATION_URL = `${API_URL}api/v1/auth/admin/locations/`
export const CREATE_LOCATION_URL = `${API_URL}api/v1/auth/admin/location/`
export const EDIT_LOCATION_URL = `${API_URL}api/v1/auth/admin/location/`
export const DELETE_LOCATION_URL = `${API_URL}api/v1/auth/admin/location/`
export const CHANGE_STATUS_LOCATION_URL = `${API_URL}api/v1/auth/admin/location/`
export const GET_ACTIVE_LOCATION_URL = `${API_URL}api/v1/auth/admin/active-locations/`

// Get location Data
export async function getLocationData(startIndex: number, limitNumber: number) {
  try {
    let locationData = await axios.post(`${GET_LOCATION_URL}${startIndex}/${limitNumber}`, {
      searchText: '',
    })
    return locationData
  } catch (e) {
    throw e
  }
}

export async function getActiveLocationData() {
  try {
    let locationData = await axios.get(`${GET_ACTIVE_LOCATION_URL}`)
    return locationData
  } catch (e) {
    throw e
  }
}

// Search location Data
export async function getSearchLocationData(
  startIndex: number,
  limitNumber: number,
  searchText: string
) {
  try {
    let locationData = await axios.post(`${SEARCH_LOCATION_URL}${startIndex}/${limitNumber}`, {
      searchText,
    })
    return locationData
  } catch (e) {
    throw e
  }
}

// Create Location Data
export async function createLocationData(managerObject: any) {
  try {
    let createLocationData = await axios.post(`${CREATE_LOCATION_URL}`, {
      ...managerObject,
    })
    return createLocationData
  } catch (e) {
    throw e
  }
}

// Delete Location Data
export async function deleteLocationData(loationId: string) {
  try {
    let deleteLocationData = await axios.delete(`${DELETE_LOCATION_URL}${loationId}`)
    return deleteLocationData
  } catch (e) {
    throw e
  }
}

// Edit Location Data
export async function editLocationData(managerObject: any, locationId: any) {
  try {
    let editLocationData = await axios.put(`${EDIT_LOCATION_URL}`, {
      ...managerObject,
    })
    return editLocationData
  } catch (e) {
    throw e
  }
}
