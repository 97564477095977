import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getTallyData, getSearchTallyData} from '../redux/TallyCRUD'
import {useDispatch} from 'react-redux'
import * as tally from '../redux/TallyRedux'
import ListTallyStoneData from './ListTallyStoneData'
import ListNonTallyStoneData from './ListNonTallyStoneData'
import {Tabs} from 'antd'
import {useLocation} from 'react-router-dom'

const {TabPane} = Tabs

const ViewList = (props: any) => {
    const dispatch = useDispatch()
  const location = useLocation()
  const data = location.state

  const [searchPagination, setSearchPagination] = useState({
    skip: 0,
    limit: 10,
    filterObject: {},
  })

  const [TabKey, setTabKey] = useState('1')

  const callback = (key: any) => {
    setTabKey(key)
    if (key == 1) {
      callTallyData('tally')
    } else if (key == 2) {
      callTallyData('untally')
    }
  }

  const callTallyData = async (type: string) => {
    try {
      await dispatch(tally.actions.setTallyData([]))
      await dispatch(tally.actions.setTallyDataTotal(0))
      if (!type) {
        type = TabKey == '1' ? 'tally' : 'untally'
      }
      let tally_id = '';
      let date= ''; 
      if(props.data) {
        tally_id = props.data ? props.data._id ?  props.data._id  : '' : ''
        date = props.data ? props.data.date ?  props.data.date  : '' : '' 
      }
      let tallyData = await getTallyData(
        tally_id,
        date,
        type
      )
      if (tallyData.data) {
        await dispatch(tally.actions.setTallyLoading(true))
        // once tally data set then loading will automatically off using saga middleware
        await dispatch(tally.actions.setTallyData(tallyData.data))
        await dispatch(tally.actions.setTallyDataTotal(tallyData.data.length))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    // const data = location.state
    console.log(data, 'data')
      callTallyData('tally')
  }, [])


  return (
    <div>
      <Tabs style={{fontWeight: '500'}} activeKey={TabKey} onChange={callback}>
        <TabPane tab='TALLY STONE' key='1'>
          {TabKey === '1' && <ListTallyStoneData data={props.data} />}
        </TabPane>
        <TabPane tab='UNTALLY STONE' key='2'>
          {TabKey === '2' && <ListNonTallyStoneData data={props.data} />}
        </TabPane>
      </Tabs>
    </div>
  )
}

export default ViewList