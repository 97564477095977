/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {CourierPage} from '../../component/courier'

const CourierWrapper: FC = () => {
  return (
    <div>
      <CourierPage />
    </div>
  )
}

export {CourierWrapper}
