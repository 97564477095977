export function returnStockString(singleStockObject: any) {
  let stockString = ''
  if (singleStockObject.shape) {
    stockString += singleStockObject.shape + ' | '
  }
  if (singleStockObject.weight) {
    stockString += singleStockObject.weight + ' | '
  }
  if (singleStockObject.color) {
    stockString += singleStockObject.color + ' | '
  }
  if (singleStockObject.clarity) {
    stockString += singleStockObject.clarity + ' | '
  }
  let cutPolishSym = ''
  if (singleStockObject.cut) {
    cutPolishSym += singleStockObject.cut + ' - '
  }
  if (singleStockObject.polish) {
    cutPolishSym += singleStockObject.polish + ' - '
  }
  if (singleStockObject.symmetry) {
    cutPolishSym += singleStockObject.symmetry
  }
  if (cutPolishSym != '') {
    stockString += cutPolishSym.replace(/^\-|\-\s$/g, '') + ' | '
  }
  if (singleStockObject.fluorescence_intensity) {
    stockString += singleStockObject.fluorescence_intensity
  }
  if (singleStockObject.fluorescence) {
    stockString += singleStockObject.fluorescence
  }
  stockString = stockString.replace(/^\||\|\s$/g, '')
  return stockString.toUpperCase()
}

export function returnStockId(stockId: string) {
  return stockId.toUpperCase()
}

export function returnPriceInThousandFormat(price: number, digit: number = 2) {
  // return (Math.round(price * 100) / 100).toFixed(digit).toLocaleString()
  return price.toLocaleString(undefined, {
    maximumFractionDigits: digit,
    minimumFractionDigits: digit,
  })
}

export function returnPriceInThousandFormatWithCurrencySign(price: number, digit: number = 2) {
  // return (Math.round(price * 100) / 100).toFixed(digit).toLocaleString()
  return (
    '$' +
    price.toLocaleString(undefined, {
      maximumFractionDigits: digit,
      minimumFractionDigits: digit,
    })
  )
}
