import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setTallyData: 'SET_TALLY_DATA',
  setTallyDataTotal: 'SET_TALLY_DATA_TOTAL',
  setTallyLoading: 'SET_TALLY_LOADER',
}

const initialClientState: ITallyState = {
  tallyData: undefined,
  tallyDataTotal: undefined,
  tallyLoading: false,
}

export interface ITallyState {
  tallyData?: any
  tallyDataTotal?: number
  tallyLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'tally', whitelist: ['tallyData', 'tallyDataTotal']},
  (state: ITallyState = initialClientState, action: ActionWithPayload<ITallyState>) => {
    switch (action.type) {
      case actionTypes.setTallyData: {
        const tallyData = action.payload?.tallyData
        return {...state, tallyData}
      }

      case actionTypes.setTallyDataTotal: {
        const tallyDataTotal = action.payload?.tallyDataTotal
        return {...state, tallyDataTotal}
      }

      case actionTypes.setTallyLoading: {
        const tallyLoading = action.payload?.tallyLoading
        return {...state, tallyLoading}
      }

      default:
        return state
    }
  }
)

export const actions = {
  setTallyData: (tallyData: Array<any>) => ({
    type: actionTypes.setTallyData,
    payload: {tallyData: tallyData},
  }),
  setTallyDataTotal: (tallyDataTotal: number) => ({
    type: actionTypes.setTallyDataTotal,
    payload: {tallyDataTotal},
  }),
  setTallyLoading: (tallyLoading: boolean) => ({
    type: actionTypes.setTallyLoading,
    payload: {tallyLoading},
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.setTallyData, function* loginSaga() {
    yield put(actions.setTallyLoading(false))
  })

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser())
  // })

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const {data: user} = yield getUserByToken()
  //   yield put(actions.fulfillUser(user))
  // })
}
