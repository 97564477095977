import React from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_MANAGER_URL = `${API_URL}api/v1/auth/admin/managers/`
export const SEARCH_MANAGER_URL = `${API_URL}api/v1/auth/admin/managers/`
export const CREATE_MANAGER_URL = `${API_URL}api/v1/auth/admin/manager/`
export const EDIT_MANAGER_URL = `${API_URL}api/v1/auth/admin/manager/`
export const DELETE_MANAGER_URL = `${API_URL}api/v1/auth/admin/manager/`
export const CHANGE_STATUS_MANAGER_URL = `${API_URL}v1/auth/manager/active-status/`

// Get manager Data
export async function getManagerData(startIndex: number, limitNumber: number) {
  try {
    let managerdata = await axios.post(`${GET_MANAGER_URL}${startIndex}/${limitNumber}`, {
      searchText: '',
    })
    return managerdata
  } catch (e) {
    throw e
  }
}

// Search manager Data
export async function getSearchManagerData(
  startIndex: number,
  limitNumber: number,
  searchText: string
) {
  try {
    let managerdata = await axios.post(`${SEARCH_MANAGER_URL}${startIndex}/${limitNumber}`, {
      searchText,
    })
    return managerdata
  } catch (e) {
    throw e
  }
}

// Create Manager Data
export async function createManagerData(managerObject: any) {
  try {
    let createManagerData = await axios.post(`${CREATE_MANAGER_URL}`, {
      ...managerObject,
    })
    return createManagerData
  } catch (e) {
    throw e
  }
}

// Delete Manager Data
export async function deleteManagerData(managerId: string) {
  try {
    let deleteManagerData = await axios.delete(`${DELETE_MANAGER_URL}${managerId}`)
    return deleteManagerData
  } catch (e) {
    throw e
  }
}

// Edit Manager Data
export async function editManagerData(managerObject: any, managerId: string) {
  try {
    let editManagerData = await axios.put(`${EDIT_MANAGER_URL}`, {
      ...managerObject,
    })
    return editManagerData
  } catch (e) {
    throw e
  }
}
