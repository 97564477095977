/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {CreateWrapper} from './container/CreateCourier'
import {ListWraper} from './container/List'

export function CourierPage() {
  useEffect(() => {}, [])

  return (
    <div>
      <Switch>
        <Route path='/courier/list' exact={true} component={ListWraper} />
        <Route path='/courier/create' exact={true} component={CreateWrapper} />
        <Redirect from='/courier' exact={true} to='/courier/list' />
        <Redirect to='/courier/list' />
      </Switch>
    </div>
  )
}
