/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {createInventoryData, getInventoryData} from '../redux/InventoryCRUD'
import {getRoleDataForDropDown} from '../redux/InventoryCRUD'
import * as inventory from '../redux/InventoryRedux'
import {CreateInventoryForm} from './CreateInventoryForm'
import {IInventoryFormValues} from './CreateInventoryForm'

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [roleData, setRoleData] = useState([])

  // call initial data for Form
  const callInitialData = async () => {
    try {
      let roleData = await getRoleDataForDropDown()
      if (roleData.data) {
        setRoleData(roleData.data)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callInitialData()
  }, [])

  const callInventoryData = async () => {
    try {
      let inventoryData = await getInventoryData(0, 10, 'CERT')
      if (inventoryData.data) {
        await dispatch(inventory.actions.setInventoryLoading(true))
        // once inventory data set then loading will automatically off using saga middleware
        await dispatch(inventory.actions.setInventoryData(inventoryData.data.docs))
        await dispatch(inventory.actions.setInventoryDataTotal(inventoryData.data.totalDocs))
        history.push('/inventory/list')
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  const addSubmit = async (values: IInventoryFormValues) => {
    try {
      await createInventoryData(values)
      await callInventoryData()
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  return (
    <div>
      {roleData && roleData.length > 0 && (
        <CreateInventoryForm
          roleData={roleData}
          onSubmit={(values: IInventoryFormValues) => addSubmit(values)}
        />
      )}
    </div>
  )
}

const CreateWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'Create',
            path: '/inventory/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'Create',
            path: '/inventory/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.INVENTORY'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {CreateWrapper}
