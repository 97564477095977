import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {config} from '../../../comman/constants'

interface IRenderFormikForm {
  values: ISearchFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface ISearchFormValues {
  size: any
  color: any
  clarity: any
  finish: any
  fluorescence_intensity: any
}

interface IEditInventoryProps {
  onSubmit: any
  onClearSearch: any
  // data: any
  // handleCancel?: any
}

export default class ListMatrixSearchForm extends React.Component<IEditInventoryProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      roleData: [],
      searchParams: {},
      colorType: 'white',
      sizeInputSearchVal: [],
      expanded: true,
    }
  }

  public handleExpandClick = () => {
    this.setState({expanded: !this.state.expanded})
  }

  public validateForm = (values: ISearchFormValues) => {
    const errors: any = {}

    const {searchParams} = this.state

    if (this.sizeSearchType === 'input' && searchParams['size'] && searchParams['size'][0]) {
      let getSizeInputValue = searchParams['size'][0]
      let sizeInputArray = getSizeInputValue.split('-')
      if (!sizeInputArray[0]) {
        errors.from = 'Please enter from value'
      }
      if (!sizeInputArray[1]) {
        errors.to = 'Please enter to value'
      }
      if (sizeInputArray.length >= 2) {
        if (sizeInputArray[0] === sizeInputArray[1]) {
          errors.to = 'Please enter the different values'
        } else if (parseInt(sizeInputArray[0]) > parseInt(sizeInputArray[1])) {
          errors.to = 'To value should greater then From'
        } else if (parseInt(sizeInputArray[1]) < parseInt(sizeInputArray[0])) {
          errors.to = 'From value should lesser then To'
        }
      }
    }

    console.log('errors', errors)

    return errors
  }

  public handleSubmit = (values: ISearchFormValues, action: any) => {
    const {onSubmit} = this.props
    const {searchParams} = this.state
    onSubmit(searchParams)
  }

  public clearSearch = () => {
    const {onClearSearch} = this.props
    this.setState({searchParams: {}})
    onClearSearch()
  }

  public onChangeFilterButton = (searchItem: string, searchItemValue: string) => {
    console.log('searchItem', searchItem)
    console.log('searchItemValue', searchItemValue)
    if (searchItem === 'size' && this.sizeSearchType === 'input') {
      this.setState({
        searchParams: {
          ...this.state.searchParams,
          [searchItem]: [searchItemValue],
        },
      })
    } else {
      if (Object.keys(this.state.searchParams).length >= 1 && this.state.searchParams[searchItem]) {
        let filterIndex = Object.keys(this.state.searchParams[searchItem]).findIndex(
          (item) => this.state.searchParams[searchItem][item] === searchItemValue
        )
        if (filterIndex !== -1) {
          this.state.searchParams[searchItem].splice(filterIndex, 1) //remove the search element from particular search item
          if (this.state.searchParams[searchItem].length < 1) {
            delete this.state.searchParams[searchItem] //delete object if the filter last filter is remove from the search state
          }
          this.setState({
            searchParams: {...this.state.searchParams},
          })
        } else {
          if (searchItem === 'size' && this.sizeSearchType === 'button') {
            this.setState({
              searchParams: {
                ...this.state.searchParams,
                [searchItem]: [searchItemValue],
              },
            })
          } else {
            this.setState({
              searchParams: {
                ...this.state.searchParams,
                [searchItem]: [...(this.state.searchParams[searchItem] || []), searchItemValue],
              },
            })
          }
        }
      } else {
        if (searchItem === 'size' && this.sizeSearchType === 'button') {
          this.setState({
            searchParams: {
              ...this.state.searchParams,
              [searchItem]: [searchItemValue],
            },
          })
        } else {
          this.setState({
            searchParams: {
              ...this.state.searchParams,
              [searchItem]: [...(this.state.searchParams[searchItem] || []), searchItemValue],
            },
          })
        }
      }
    }
  }

  sizeSearchType: string = 'button'
  tempSearchValue: any = []
  public onChangeSizeInput(name: string, value: string) {
    if (this.state.searchParams['size'] && this.sizeSearchType === 'button') {
      delete this.state.searchParams['size']
      this.tempSearchValue = []
    }
    if (name === 'from') {
      this.tempSearchValue[0] = value
      // this.setState({sizeInputSearchVal: [value, this.state.sizeInputSearchVal[1] || '']})
    }
    if (name === 'to') {
      this.tempSearchValue[1] = value
      // this.setState({sizeInputSearchVal: [this.state.sizeInputSearchVal[0] || '', value]})
    }

    this.setState({sizeInputSearchVal: this.tempSearchValue})
    if (this.state.sizeInputSearchVal.length >= 2) {
      this.onChangeFilterButton('size', this.tempSearchValue.join('-'))
    }
    this.sizeSearchType = 'input'
  }

  public onChangeSizeButton(item: string, itemValue: string) {
    if (this.state.searchParams['size'] && this.sizeSearchType === 'input') {
      delete this.state.searchParams['size']
      this.tempSearchValue = []
    }
    this.onChangeFilterButton(item, itemValue)
    this.setState({sizeInputSearchVal: [], sizeSearchType: 'button'})
    this.sizeSearchType = 'button'
  }

  public render() {
    const {searchParams} = this.state

    return (
      <div className='mb-10'>
        <div className='card card-custom'>
          <div className='card-header cursor-pointer' onClick={() => this.handleExpandClick()}>
            <h3 className='card-title table-card-title' style={{width: '25%'}}>
              {'Advanced Search'}
            </h3>
            <div className='card-toolbar'>
              <i className={`ki icon-nm ${this.state.expanded ? 'ki-plus' : 'ki-minus'}`}></i>
            </div>
          </div>
          <div className={`card-body ${this.state.expanded ? 'collapse' : 'expand'}`}>
            <div>
              <Formik
                initialValues={{
                  size: '',
                  color: '',
                  clarity: '',
                  finish: '',
                  fluorescence_intensity: '',
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      <div className='form-group row'>
                        <div className='row col-md-12 p-4'>
                          <div className='col-md-2'>
                            <label className='font-weight-bold'>{'Size'}</label>
                          </div>
                          <div className='col-md-1'>
                            <input
                              name='from'
                              type='number'
                              min='0'
                              className={
                                touched && errors && errors['from']
                                  ? 'p-3 m-1 form-control form-control-solid border-danger'
                                  : 'p-3 m-1 form-control form-control-solid'
                              }
                              placeholder='From'
                              value={
                                this.state.sizeInputSearchVal &&
                                this.state.sizeInputSearchVal.length >= 1 &&
                                this.state.sizeInputSearchVal[0]
                                  ? this.state.sizeInputSearchVal[0]
                                  : ''
                              }
                              onChange={(event) =>
                                this.onChangeSizeInput('from', event.target.value)
                              }
                            />
                            {touched && errors && errors['from'] && (
                              <div className='fv-plugins-message-container text-danger'>
                                <div className='fv-help-block'>{errors['from']}</div>
                              </div>
                            )}
                            <input
                              name='to'
                              type='number'
                              min='0'
                              value={
                                this.state.sizeInputSearchVal &&
                                this.state.sizeInputSearchVal.length >= 1 &&
                                this.state.sizeInputSearchVal[1]
                                  ? this.state.sizeInputSearchVal[1]
                                  : ''
                              }
                              className={
                                touched && errors && errors['to']
                                  ? 'p-3 m-1 form-control form-control-solid border-danger'
                                  : 'p-3 m-1 form-control form-control-solid'
                              }
                              placeholder='To'
                              onChange={(event) => this.onChangeSizeInput('to', event.target.value)}
                            />
                            {touched && errors && errors['to'] && (
                              <div className='fv-plugins-message-container text-danger'>
                                <div className='fv-help-block'>{errors['to']}</div>
                              </div>
                            )}
                          </div>
                          <div className='col-md-9'>
                            {config.inventorySearchParamsValue[1] &&
                              config.inventorySearchParamsValue[1].map(
                                (
                                  searchItem: {value: string; label: string; icon: string},
                                  subIndex: number
                                ) => {
                                  return (
                                    <button
                                      onClick={(e: any) =>
                                        this.onChangeSizeButton('size', searchItem.value)
                                      }
                                      className={`btn font-weight-bold p-3 m-1 ${
                                        Object.keys(this.state.searchParams).length >= 1 &&
                                        this.state.searchParams['size'] &&
                                        Object.keys(this.state.searchParams['size']).findIndex(
                                          (subItem) =>
                                            this.state.searchParams['size'][subItem] ===
                                            searchItem.value
                                        ) !== -1
                                          ? 'btn-danger'
                                          : 'btn-secondary'
                                      }`}
                                    >
                                      {searchItem.label}
                                    </button>
                                  )
                                }
                              )}
                          </div>
                        </div>

                        <div className='row col-md-12 p-4'>
                          <div className='col-md-2'>
                            <label className='font-weight-bold'>{'Lab'}</label>
                          </div>
                          <div className='col-md-10'>
                            {config.inventorySearchParamsValue[5] &&
                              config.inventorySearchParamsValue[5].map(
                                (
                                  searchItem: {value: string; label: string; icon: string},
                                  subIndex: number
                                ) => {
                                  return (
                                    <button
                                      onClick={(e: any) =>
                                        this.onChangeFilterButton('lab', searchItem.value)
                                      }
                                      className={`btn font-weight-bold p-3 m-1 ${
                                        Object.keys(this.state.searchParams).length >= 1 &&
                                        this.state.searchParams['lab'] &&
                                        Object.keys(this.state.searchParams['lab']).findIndex(
                                          (subItem) =>
                                            this.state.searchParams['lab'][subItem] ===
                                            searchItem.value
                                        ) !== -1
                                          ? 'btn-danger'
                                          : 'btn-secondary'
                                      }`}
                                    >
                                      {searchItem.label}
                                    </button>
                                  )
                                }
                              )}
                          </div>
                        </div>
                        <div className='row col-md-12 p-4'>
                          <div className='col-md-2'>
                            <label className='font-weight-bold'>{'Cut'}</label>
                          </div>
                          <div className='col-md-10'>
                            {config.inventorySearchParamsValue[6] &&
                              config.inventorySearchParamsValue[6].map(
                                (
                                  searchItem: {value: string; label: string; icon: string},
                                  subIndex: number
                                ) => {
                                  return (
                                    <button
                                      onClick={(e: any) =>
                                        this.onChangeFilterButton('cut', searchItem.value)
                                      }
                                      className={`btn font-weight-bold p-3 m-1 ${
                                        Object.keys(this.state.searchParams).length >= 1 &&
                                        this.state.searchParams['cut'] &&
                                        Object.keys(this.state.searchParams['cut']).findIndex(
                                          (subItem) =>
                                            this.state.searchParams['cut'][subItem] ===
                                            searchItem.value
                                        ) !== -1
                                          ? 'btn-danger'
                                          : 'btn-secondary'
                                      }`}
                                    >
                                      {searchItem.label}
                                    </button>
                                  )
                                }
                              )}
                          </div>
                        </div>
                        <div className='row col-md-12 p-4'>
                          <div className='col-md-2'>
                            <label className='font-weight-bold'>{'Polish'}</label>
                          </div>
                          <div className='col-md-10'>
                            {config.inventorySearchParamsValue[7] &&
                              config.inventorySearchParamsValue[7].map(
                                (
                                  searchItem: {value: string; label: string; icon: string},
                                  subIndex: number
                                ) => {
                                  return (
                                    <button
                                      onClick={(e: any) =>
                                        this.onChangeFilterButton('polish', searchItem.value)
                                      }
                                      className={`btn font-weight-bold p-3 m-1 ${
                                        Object.keys(this.state.searchParams).length >= 1 &&
                                        this.state.searchParams['polish'] &&
                                        Object.keys(this.state.searchParams['polish']).findIndex(
                                          (subItem) =>
                                            this.state.searchParams['polish'][subItem] ===
                                            searchItem.value
                                        ) !== -1
                                          ? 'btn-danger'
                                          : 'btn-secondary'
                                      }`}
                                    >
                                      {searchItem.label}
                                    </button>
                                  )
                                }
                              )}
                          </div>
                        </div>
                        <div className='row col-md-12 p-4'>
                          <div className='col-md-2'>
                            <label className='font-weight-bold'>{'Symmetry'}</label>
                          </div>
                          <div className='col-md-10'>
                            {config.inventorySearchParamsValue[8] &&
                              config.inventorySearchParamsValue[8].map(
                                (
                                  searchItem: {value: string; label: string; icon: string},
                                  subIndex: number
                                ) => {
                                  return (
                                    <button
                                      onClick={(e: any) =>
                                        this.onChangeFilterButton('symmetry', searchItem.value)
                                      }
                                      className={`btn font-weight-bold p-3 m-1 ${
                                        Object.keys(this.state.searchParams).length >= 1 &&
                                        this.state.searchParams['symmetry'] &&
                                        Object.keys(this.state.searchParams['symmetry']).findIndex(
                                          (subItem) =>
                                            this.state.searchParams['symmetry'][subItem] ===
                                            searchItem.value
                                        ) !== -1
                                          ? 'btn-danger'
                                          : 'btn-secondary'
                                      }`}
                                    >
                                      {searchItem.label}
                                    </button>
                                  )
                                }
                              )}
                          </div>
                        </div>
                        <div className='row col-md-12 p-4'>
                          <div className='col-md-2'>
                            <label className='font-weight-bold'>{'Fluorescence Intensity'}</label>
                          </div>
                          <div className='col-md-10'>
                            {config.inventorySearchParamsValue[9] &&
                              config.inventorySearchParamsValue[9].map(
                                (
                                  searchItem: {value: string; label: string; icon: string},
                                  subIndex: number
                                ) => {
                                  return (
                                    <button
                                      onClick={(e: any) =>
                                        this.onChangeFilterButton(
                                          'fluorescence_intensity',
                                          searchItem.value
                                        )
                                      }
                                      className={`btn font-weight-bold p-3 m-1 ${
                                        Object.keys(this.state.searchParams).length >= 1 &&
                                        this.state.searchParams['fluorescence_intensity'] &&
                                        Object.keys(
                                          this.state.searchParams['fluorescence_intensity']
                                        ).findIndex(
                                          (subItem) =>
                                            this.state.searchParams['fluorescence_intensity'][
                                              subItem
                                            ] === searchItem.value
                                        ) !== -1
                                          ? 'btn-danger'
                                          : 'btn-secondary'
                                      }`}
                                    >
                                      {searchItem.label}
                                    </button>
                                  )
                                }
                              )}
                          </div>
                        </div>
                      </div>

                      <div className='custom-card-footer'>
                        <div className='float-right'>
                          <InputButtonComponent
                            onClick={handleSubmit}
                            disabled={
                              searchParams && Object.keys(searchParams).length >= 1 ? false : true
                            }
                          >
                            Search
                          </InputButtonComponent>
                        </div>
                        {searchParams && Object.keys(searchParams).length >= 1 && (
                          <div className='float-right mr-5'>
                            <InputButtonComponent onClick={() => this.clearSearch()}>
                              Clear Search
                            </InputButtonComponent>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
