import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputToggle} from '../../../modules/comman/formComponents/InputToggle'

interface IRenderFormikForm {
  values: IPackageFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IPackageFormValues {
  name: string
  area: string
  is_active: boolean
  city: string
}

interface IAddClientProps {
  onSubmit: any
  data?: any
  handleCancel?: any
}

export class CreatePackageForm extends React.Component<IAddClientProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
    }
  }

  public validateForm = (values: IPackageFormValues) => {
    const errors: any = {}
    console.log('Validating form -- ', values)

    if (!values.name) {
      errors.name = 'Name is required'
    }
    if (!values.area) {
      errors.area = 'Area is required'
    }
    if (!values.city) {
      errors.city = 'City is required'
    }
    console.log('Validating errors -- ', errors)
    return errors
  }

  public handleSubmit = (values: IPackageFormValues, action: any) => {
    console.log('Basic Value - ', values)
    this.props.onSubmit(values)

    this.setState({
      visible: false,
    })
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public render() {
    return (
      <div className='container'>
        <div className='card card-custom'>
          <div className='card-header'>
            <h3 className='card-title'>Create Package Form</h3>
          </div>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  name: '',
                  area: '',
                  is_active: false,
                  city: '',
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.name,
                              id: 'name',
                              name: 'name',
                            }}
                            placeholder='Enter Name'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('name', value)
                            }}
                            onBlur={handleBlur}
                            label='Name'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.area,
                              id: 'area',
                              name: 'area',
                            }}
                            placeholder='Enter Area'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('area', value)
                            }}
                            onBlur={handleBlur}
                            label='Area'
                            error={errors}
                            touched={touched}
                            required={false}
                          />
                        </div>
                      </div>
                      <div className='form-group row' style={{marginBottom: '20px'}}>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.city,
                              id: 'city',
                              name: 'city',
                            }}
                            placeholder='Enter City'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('city', value)
                            }}
                            onBlur={handleBlur}
                            label='City'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputToggle
                            input={{
                              value: values.is_active,
                              id: 'is_active',
                              name: 'is_active',
                            }}
                            onChange={(value: boolean) => {
                              setFieldValue('is_active', value)
                            }}
                            label='Is Active Status'
                            error={errors}
                            touched={touched}
                            selectedLabel={'True'}
                            unSelectedlabel={'False'}
                          />
                        </div>
                      </div>
                      <InputButtonComponent onClick={handleSubmit}>Create</InputButtonComponent>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
          {/* <div className='card-footer'></div> */}
        </div>
      </div>
    )
  }
}
