/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useHistory} from 'react-router'

type Props = {
  icon: string
  title: string
  value: string
  path?: string
  background?: string
  cardType?: string
}

const KpiCardComponent: React.FC<Props> = ({icon, title, value, path,cardType, background = 'white'}) => {
  const history = useHistory()
  const navigateToPath = () => {
    if (path && cardType) {
      history.push({
        pathname: path,
        state: cardType
      })
    }
    else if (path) {
      history.push(path)
    }
  }

  return (
    <div className='col-xl-3' onClick={() => navigateToPath()}>
      <a
        href='javascript:void(0)'
        className={`card hoverable card-xl-stretch mb-xl-8 ${
          background === 'blue'
            ? 'bg-info'
            : background === 'black'
            ? 'bg-dark'
            : background === 'yellow'
            ? 'bg-warning'
            : 'bg-body'
        }`}
      >
        <div className='card-body'>
          <span className='svg-icon svg-icon-primary svg-icon-3x ms-n1'>
            <svg xmlns={icon} width={24} height={24} viewBox='0 0 24 24' fill='none'>
              <rect x={8} y={9} width={3} height={10} rx='1.5' fill='black' />
              <rect opacity='0.5' x={13} y={5} width={3} height={14} rx='1.5' fill='black' />
              <rect x={18} y={11} width={3} height={8} rx='1.5' fill='black' />
              <rect x={3} y={13} width={3} height={6} rx='1.5' fill='black' />
            </svg>
          </span>
          <div
            className={`${
              background === 'white' ? 'text-gray-900' : 'text-white'
            } fw-bolder fs-2 mb-2 mt-5`}
          >
            {value}
          </div>
          <div
            className={`${
              background === 'white'
                ? 'text-white fw-bold text-gray-400'
                : 'text-white text-gray-100'
            }`}
          >
            {title}
          </div>
        </div>
      </a>
    </div>
  )
}

export {KpiCardComponent}
