/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getInventoryData, getMatrixInventoryData} from '../redux/InventoryCRUD'
import {useDispatch} from 'react-redux'
import * as inventory from '../redux/InventoryRedux'
import ListMatrixInventoryData from './ListMatrixInventoryData'
import ListMatrixSearchForm from './ListMatrixSearchForm'

const ListMatrixPage: FC = () => {
  const dispatch = useDispatch()

  const callInventoryData = async () => {
    try {
      let matrixInventoryData = await getMatrixInventoryData([])
      if (matrixInventoryData.data) {
        await dispatch(inventory.actions.setMatrixInventoryLoading(true))
        // once inventory data set then loading will automatically off using saga middleware
        await dispatch(inventory.actions.setMatrixInventoryData(matrixInventoryData.data))
        // await dispatch(inventory.actions.setMatrixInventoryDataTotal(matrixInventoryData.data.total))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callInventoryData()
  }, [])

  const searchMatrixInventoryData = async (values: any) => {
    try {
      console.log('values', values)
      await dispatch(inventory.actions.setMatrixInventoryLoading(true))
      let matrixInventoryData = await getMatrixInventoryData(values)
      if (matrixInventoryData.data) {
        await dispatch(inventory.actions.setMatrixInventoryData(matrixInventoryData.data))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await dispatch(inventory.actions.setInventoryLoading(false))
    }
  }

  return (
    <div>
      <ListMatrixSearchForm
        onSubmit={searchMatrixInventoryData}
        onClearSearch={callInventoryData}
      />
      <ListMatrixInventoryData />
    </div>
  )
}

const ListMatrixWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'Matrix',
            path: '/inventory/matrix',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'Matrix',
            path: '/inventory/matrix',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.INVENTORY'})}
      </PageTitle>
      <ListMatrixPage />
    </div>
  )
}

export {ListMatrixWrapper}
