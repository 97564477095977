/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getLocationData} from '../redux/LocationCRUD'
import {useDispatch} from 'react-redux'
import * as location from '../redux/LocationRedux'
import ListLocationData from './ListLocationData'

const ListPage: FC = () => {
  const dispatch = useDispatch()

  const callLocationData = async () => {
    try {
      let locationData = await getLocationData(0, 10)
      if (locationData.data) {
        await dispatch(location.actions.setLocationLoading(true))
        // once location data set then loading will automatically off using saga middleware
        await dispatch(location.actions.setLocationData(locationData.data.docs))
        await dispatch(location.actions.setLocationDataTotal(locationData.data.totalDocs))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callLocationData()
  }, [])

  return (
    <div>
      <ListLocationData />
    </div>
  )
}

const ListWraper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'list',
            path: '/location/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'list',
            path: '/location/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.LOCATION'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWraper}
