/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

type Props = {
  onChange: (value: boolean) => void
  label: string
  selectedLabel: string
  unSelectedlabel: string
  input: {
    value?: boolean
    name: string
    id: string
  }
  disabled?: boolean
  error: any
  touched: any
  required?: boolean
  isLabel?: boolean
}

const InputToggle: React.FC<Props> = ({
  onChange,
  label,
  selectedLabel,
  unSelectedlabel,
  input,
  disabled = false,
  error,
  touched,
  required = true,
  isLabel = true,
}) => {
  return (
    <>
      {isLabel && (
        <label style={{width: '100%'}}>
          {label} {required && <span className='text-danger'>*</span>}
        </label>
      )}

      <BootstrapSwitchButton
        checked={input.value}
        onlabel={selectedLabel}
        offlabel={unSelectedlabel}
        onChange={(checked: boolean) => {
          onChange(checked)
        }}
        width={100}
        height={40}
      />

      {touched && error && error[input.name] && !disabled && (
        <div className='fv-plugins-message-container text-danger'>
          <div className='fv-help-block'>{error[input.name]}</div>
        </div>
      )}
    </>
  )
}

export {InputToggle}

//<InputToggle
//   input={{
//     value: values.is_usa,
//     id: 'isUsa',
//     name: 'isUsa',
//   }}
//   onChange={(value: boolean) => {
//     setFieldValue('is_usa', value)
//   }}
//   label='In Usa'
//   error={errors}
//   touched={touched}
//   selectedLabel={'True'}
//   unSelectedlabel={'False'}
// />
