/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as manager from '../redux/ManagerRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {UserOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
import {
  deleteManagerData,
  editManagerData,
  getManagerData,
  getSearchManagerData,
} from '../redux/ManagerCRUD'
import {EditManagerForm} from './EditManagerForm'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  managerData: any
  managerDataTotal: number
  managerLoading: boolean
  setListManagerData: (managerData: any) => void
  setListManagerDataTotal: (managerDataTotal: number) => void
  setManagerLoading: (managerLoading: boolean) => void
  history?: History<LocationState>
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  managerSelectedData: any
}

type PathParamsType = {
  param1: string
}
class ListManagerData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      managerSelectedData: null,
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListManagerData(pagination)
    )
  }

  public getListManagerData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listManagerSearchData(start, end)
        } else {
          this.listManagerData(start, end)
        }
      }
    )
  }

  public listManagerData = async (start: number, end: any) => {
    const {setManagerLoading} = this.props
    try {
      const {setListManagerData, setListManagerDataTotal} = this.props
      await setManagerLoading(true)
      let managerData = await getManagerData(start, end)
      if (managerData.data) {
        await setListManagerData(managerData.data.docs)
        await setListManagerDataTotal(managerData.data.totalDocs)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setManagerLoading(false)
    }
  }

  public listManagerSearchData = async (start: number, end: any) => {
    const {setManagerLoading} = this.props
    try {
      const {setListManagerData, setListManagerDataTotal} = this.props
      const {searchText} = this.state

      await setManagerLoading(true)
      let managerData = await getSearchManagerData(start, end, searchText)
      if (managerData.data) {
        await setListManagerData(managerData.data.docs)
        await setListManagerDataTotal(managerData.data.totalDocs)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setManagerLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listManagerSearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public onCreateManager = () => {
    const {history} = this.props
    history.push('/manager/create')
  }

  public deleteManager = async (item: any) => {
    const {setManagerLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setManagerLoading(true)
      await deleteManagerData(item._id)

      Toaster({
        type: 'success',
        title: 'MANAGER',
        description: 'Deleted SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listManagerSearchData(startIndex, limitNumber)
        return
      }
      this.listManagerData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setManagerLoading(false)
    }
  }

  public onChangeStatus = async (item: any, checked: boolean) => {
    const {setManagerLoading} = this.props
    // try {
    //   const {startIndex, limitNumber, searchText} = this.state

    //   await setManagerLoading(true)
    //   await changeStatusLocationData(item.id, checked)

    //   Toaster({
    //     type: 'success',
    //     title: 'MANAGER',
    //     description: 'Status changed successFully',
    //   })

    //   if (searchText != null && searchText !== '') {
    //     this.listManagerSearchData(startIndex, limitNumber)
    //     return
    //   }
    //   this.listManagerData(startIndex, limitNumber)
    // } catch (e) {
    //   console.log('!!!!Error', e)
    // } finally {
    //   await setManagerLoading(false)
    // }
  }

  public onEditSubmit = async (values: any, managerId: string) => {
    const {setManagerLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setManagerLoading(true)
      await editManagerData(values, managerId)

      Toaster({
        type: 'success',
        title: 'MANAGER',
        description: 'Edited SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listManagerSearchData(startIndex, limitNumber)
        return
      }
      this.listManagerData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setManagerLoading(false)
    }
  }

  public showEditModal = async (item: any) => {
    this.setState({
      managerSelectedData: item,
    })
  }

  public closeEditModal = async () => {
    this.setState({
      managerSelectedData: null,
    })
  }

  counter = 0
  tempCounter = 0
  public columns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Name',
      key: 'name',
      fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {' '}
              {item.first_name && item.last_name
                ? `${item.first_name} ${item.last_name}`
                : item.first_name
                ? item.first_name
                : '-'}
            </strong>
          </div>
        )
      },
    },
    {
      title: 'Email',
      key: 'email',
      width: 250,
      render: (text: any, item: any, index: number) => {
        return <div>{item.email ? item.email : '-'}</div>
      },
    },
    {
      title: 'Phone',
      key: 'phone',
      render: (text: any, item: any, index: number) => {
        return <div>{item.phone ? item.phone : '-'}</div>
      },
    },
    {
      title: 'Location',
      key: 'locations',
      render: (text: any, item: any, index: number) => {
        let locationArray: any = []
        if (item && item.locations && !!item.locations.length) {
          let tempId: any
          item.locations.map((id: any) => {
            tempId = id.location_id.name ? id.location_id.name : ''
            locationArray.push(tempId)
          })
        }
        return <div>{locationArray ? locationArray.join(' , ') : '-'}</div>
      },
    },
    // {
    //   title: 'isActive',
    //   key: 'isActive',
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         {item.is_active != null ? (
    //           <Tooltip placement='bottom' title='Category Activation'>
    //             {
    //               <Switch
    //                 checked={item.is_active}
    //                 style={{
    //                   backgroundColor: item.is_active ? PRIMARY_COLOR : '#bababa',
    //                 }}
    //                 onChange={(checked: boolean) => {
    //                   //call onChange Method with item
    //                   this.onChangeStatus(item, checked)
    //                 }}
    //               />
    //             }
    //           </Tooltip>
    //         ) : (
    //           '-'
    //         )}
    //       </div>
    //     )
    //   },
    // },
    {
      title: 'Actions',
      width: 130,
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Edit'>
                <Button
                  shape='circle'
                  icon={<EditOutlined />}
                  onClick={() => {
                    this.showEditModal(item)
                  }}
                />
              </Tooltip>
            </div>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Delete'>
                <Popconfirm
                  onConfirm={() => {
                    //call delete manager api
                    this.deleteManager(item)
                  }}
                  title='Are you sure you want to delete manager?'
                >
                  <Button shape='circle' icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        )
      },
    },
  ]

  public render() {
    const {managerData, managerDataTotal, managerLoading} = this.props
    const {searchText, managerSelectedData} = this.state

    return (
      <div>
        <div className='container'>
          <div className='card card-custom'>
            <div className='card-header'>
              <h3 className='card-title table-card-title' style={{width: '25%'}}>
                <InputSearchText
                  onChange={(value: string) => {
                    this.onSearchChange(value)
                  }}
                  placeholder='Search Manager'
                  input={{
                    value: searchText,
                  }}
                />
              </h3>
              <div className='card-toolbar'>
                <InputButtonComponent
                  onClick={() => {
                    this.onCreateManager()
                  }}
                >
                  Add Manager
                </InputButtonComponent>
              </div>
            </div>
            <div className='card-body'>
              <div style={{overflowX: 'auto'}}>
                <ListTable
                  rowKey={(record: {id: any}) => record.id}
                  onChange={this.handleTableChange}
                  column={this.columns}
                  paginationConfig={{
                    total: managerDataTotal,
                    showSizeChanger: true,
                    pageSize: this.state.pagination.pageSize,
                    current: this.state.pagination.current,
                  }}
                  isLoading={managerLoading}
                  dataSource={managerData}
                  counter={this.counter}
                  tempCounter={this.tempCounter}
                />
                <div>
                  <CommanModal
                    show={managerSelectedData ? true : false}
                    handleClose={() => {
                      this.closeEditModal()
                    }}
                    title={'Edit Manager'}
                    submitText={'Save Changes'}
                  >
                    <div>
                      {managerSelectedData && (
                        <EditManagerForm
                          data={managerSelectedData}
                          onSubmit={(values: any, managerId: string) => {
                            this.onEditSubmit(values, managerId)
                          }}
                          handleCancel={() => {
                            this.closeEditModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  managerData: state.manager.managerData,
  managerDataTotal: state.manager.managerDataTotal,
  managerLoading: state.manager.managerLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListManagerData: (managerData: any) => {
      dispatch(manager.actions.setManagerData(managerData))
    },
    setListManagerDataTotal: (managerDataTotal: number) => {
      dispatch(manager.actions.setManagerDataTotal(managerDataTotal))
    },
    setManagerLoading: (managerLoading: boolean) => {
      dispatch(manager.actions.setManagerLoading(managerLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListManagerData))
