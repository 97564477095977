/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {TallyPage} from '../../component/Tally'

const TallyWrapper: FC = () => {
  return (
    <div>
      <TallyPage />
    </div>
  )
}

export {TallyWrapper}
