import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputNumberText} from '../../../modules/comman/formComponents/InputNumberText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import { InputSingleSelect } from '../../../modules/comman/formComponents/InputSingleSelect'

interface IRenderFormikForm {
  values: IStockFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IStockFormValues {
  shape: string
  weight: number
  stock_id: string
  type: string
  no_of_stones: number
  // total_price: number
  price_per_caret: number
  location: string
}

interface IAddStockProps {
  onSubmit: any
  data?: any
  handleCancel?: any
}

interface ActiveLocationType {
  value?: any
  label?: any
}
export class CreateNonCertStoneForm extends React.Component<IAddStockProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      activeLocationDropdown: [],
    }
  }

  public validateForm = (values: IStockFormValues) => {
    const errors: any = {}
    // console.log('Validating form -- ', values)

    if (!values.shape) {
      errors.shape = 'Shape is required'
    }
    if (!values.weight) {
      errors.weight = 'Weight is required'
    }
   
    if (values.weight && values.weight < 0) {
        errors.weight = 'Weight must be positive'
      }

      if (!values.no_of_stones) {
        errors.no_of_stones = 'No. Of Stones is required'
      }

      if (values.no_of_stones && values.no_of_stones < 0) {
        errors.no_of_stones = 'No. Of Stones must be positive'
      }

      // if (!values.total_price) {
      //   errors.total_price = 'Total Price is required'
      // }

      // if (values.total_price && values.total_price < 0) {
      //   errors.total_price = 'Total Price must be positive'
      // }
       if (!values.price_per_caret) {
        errors.price_per_caret = 'Price Per Caret is required'
      }

      if (values.price_per_caret && values.price_per_caret < 0) {
        errors.price_per_caret = 'Price Per Caret must be positive'
      }

    if (!values.stock_id) {
      errors.stock_id = 'Stock Id is required'
    }

    if (!values.type) {
        errors.type = 'Type is required'
      }

    console.log('Validating errors -- ', errors)
    return errors
  }

  public handleSubmit = (values: IStockFormValues, action: any) => {
    console.log('Basic Value - ', values)

    this.props.onSubmit(values)
    this.props.handleCancel()

    this.setState({
      visible: false,
    })
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public render() {
    return (
      <div className='container'>
        <div className='card card-custom'>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                    stock_id: '',
                  shape: '',
                  weight: NaN,
                  type: 'NON_CERT',
                  no_of_stones: NaN,
                  // total_price: NaN,
                  price_per_caret: NaN,
                  location: ''
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                     
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.stock_id,
                              id: 'stock_id',
                              name: 'stock_id',
                            }}
                            placeholder='Enter Stock Id.'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('stock_id', value)
                            }}
                            onBlur={handleBlur}
                            label='Stock ID'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.no_of_stones,
                              id: 'no_of_stones',
                              name: 'no_of_stones',
                            }}
                            placeholder='Enter No Of Stones'
                            onChange={(value: any) => {
                              handleChange(value)
                              setFieldValue('no_of_stones', value)
                            }}
                            onBlur={handleBlur}
                            label='No Of Stones'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.shape,
                              id: 'shape',
                              name: 'shape',
                            }}
                            placeholder='Enter Shape'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('shape', value)
                            }}
                            onBlur={handleBlur}
                            label='Shape'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.weight,
                              id: 'weight',
                              name: 'weight',
                            }}
                            placeholder='Enter Weight'
                            onChange={(value: any) => {
                              handleChange(value)
                              setFieldValue('weight', value)
                            }}
                            onBlur={handleBlur}
                            label='Weight'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                    
                      <div className='form-group row'>
                        {/* <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.total_price,
                              id: 'total_price',
                              name: 'total_price',
                            }}
                            placeholder='Enter Total Price'
                            onChange={(value: any) => {
                              handleChange(value)
                              setFieldValue('total_price', value)
                            }}
                            onBlur={handleBlur}
                            label='Total Price'
                            error={errors}
                            touched={touched}
                          />
                        </div> */}
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.price_per_caret,
                              id: 'price_per_caret',
                              name: 'price_per_caret',
                            }}
                            placeholder='Price Per Caret'
                            onChange={(value: any) => {
                              handleChange(value)
                              setFieldValue('price_per_caret', value)
                            }}
                            onBlur={handleBlur}
                            label='Price Per Caret'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.location,
                              id: 'location',
                              name: 'location',
                            }}
                            placeholder='Enter Location'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('location', value)
                            }}
                            onBlur={handleBlur}
                            label='Location'
                            error={errors}
                            touched={touched}
                            required={false}
                          />
                        </div>
                      </div>
                      
                      <InputButtonComponent onClick={handleSubmit}>Create</InputButtonComponent>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
          {/* <div className='card-footer'></div> */}
        </div>
      </div>
    )
  }
}
