import * as React from 'react'
import {Spin} from 'antd'
import {getInventoryTrailData} from '../redux/InventoryCRUD'
import {Timeline} from 'antd'
var moment = require('moment')

interface IAddClientProps {
  onSubmit: any
  data?: any
  handleCancel?: any
}

export class ViewTrailForm extends React.Component<IAddClientProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      activeLocationDropdown: [],
      tempTrailValuesArray: [],
    }
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  async componentDidMount(): Promise<void> {
    try {
      this.setState({
        isLoading: true,
      })
      if (this.props.data) {
        let trailData = await getInventoryTrailData(this.props.data._id)
        if (trailData.data) {
          this.setState({
            tempTrailValuesArray: trailData.data,
          })
        }
      }

      this.setState({
        isLoading: false,
      })
    } catch (e) {
      throw e
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  public render() {
    const formatDate = (date: any) => {
      const originalDate = date
      const formattedDate = moment(originalDate).format('DD MMM YY hh:mm A')
      return formattedDate
    }

    return (
      <div className='container'>
        {this.state.tempTrailValuesArray && !!this.state.tempTrailValuesArray.length && (
          <div className='card card-custom'>
            <Timeline>
              {this.state.tempTrailValuesArray.map(function (item: any, index: any) {
                let payloadData: any = {}
                if (item.payloadData) {
                  payloadData = JSON.parse(item.payloadData)
                }
                return (
                  <Timeline.Item>
                    {console.log(payloadData)}
                    <div>
                      {item.status
                        ? item.status == 'ADDED_PACKAGE'
                          ? 'ADDED TO MEMO'
                          : item.status == 'REMOVE_PACKAGE'
                          ? 'REMOVED FROM MEMO'
                          : item.status
                        : ''}{' '}
                      {item.staff_id?.first_name && item.staff_id?.last_name
                        ? `: (BY ${item.staff_id.first_name} ${item.staff_id.last_name})`
                        : item.staff_id?.first_name
                        ? `: (BY ${item.staff_id?.first_name})`
                        : ''}
                          {item.admin_id?.first_name && item.admin_id?.last_name
                        ? `: (BY ${item.admin_id.first_name} ${item.admin_id.last_name})`
                        : item.admin_id?.first_name
                        ? `: (BY ${item.admin_id?.first_name})`
                        : ''}
                        {" "}
                        {payloadData?.client_name ? `(TO ${payloadData?.client_name})`: ''}
                    </div>
                    <div>{item.created_at ? formatDate(item.created_at) : ''}</div>
                    <div>{item.description}</div>
                  </Timeline.Item>
                )
              })}
            </Timeline>
          </div>
        )}
      </div>
    )
  }
}
