/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {CreateWrapper} from './container/CreateStaff'
import {ListWraper} from './container/List'

export function StaffPage() {
  useEffect(() => {}, [])

  return (
    <div>
      <Switch>
        <Route path='/staff/list' exact={true} component={ListWraper} />
        <Route path='/staff/create' exact={true} component={CreateWrapper} />
        <Redirect from='/staff' exact={true} to='/staff/list' />
        <Redirect to='/staff/list' />
      </Switch>
    </div>
  )
}
